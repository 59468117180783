import React from 'react';
import { useTranslation } from 'react-i18next';

// UI Icons
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

// Custom Components
import TableHeaderButton from './TableHeaderButton';
import { ITableHeaderButtonProps } from '@/@types/ui/Table';

const TableHeaderButtonLegend = (props: ITableHeaderButtonProps) => {
  const { t } = useTranslation();

  return (
    <TableHeaderButton
      variant="contained"
      startIcon={<AssignmentIndOutlinedIcon />}
      {...props}
    >
      {t('ena:komunalni_obveznici.status_obv')}
    </TableHeaderButton>
  );
};

export default TableHeaderButtonLegend;
