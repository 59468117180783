import React, { useMemo, useEffect } from 'react';
import moment from 'moment';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

// Types
import { Checkbox, Divider, FormControl, ListItemText } from '@mui/material';
import {
  FilterFn,
  IMultiFilter,
  MultiFilterWrapperFn,
  PickerValue,
} from '@/@types/ui/Table';
import { PickerItem } from '@/@types/controls/controls';
import { DCRecord } from '@/@types/lib/dataController';
import { PickerItemValue } from '@/@types/models/model';

/**
 * Filter wrapper function that returns the DateYearsColumnFilter component
 *
 * @param {ISubModelsWithData} subModelsWithData - array of sub models filed with data
 * @param {IPickerItems} fieldItems - picker items
 * @param {TFunction} t - translation function
 *
 * @returns The DateYearsColumnFilter component
 */
const DateYearsColumnFilter: MultiFilterWrapperFn = (
  subModelsWithData,
  fieldItems,
  t
) => {
  const DateYearsFilterFn: FilterFn<IMultiFilter> = (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter, preFilteredRows } = column;

    const options = (() => {
      const optionSet = new Set<PickerItem>();

      preFilteredRows?.forEach((value: DCRecord) => {
        const dt = value[id];
        if (dt) {
          const year = (dt as moment.Moment).year();
          const existingOption = Array.from(optionSet).find(
            (opt) => opt.value === year
          );
          if (!existingOption) {
            optionSet.add({
              value: year,
              label: year.toString(),
            });
          }
        }
      });

      return Array.from(optionSet);
    })();

    if (filterValue === undefined) {
      // If filterValue is undefined, that means filter is uninitialized. Set it to include all options.
      setFilter(options.map((option) => option.value));
    }

    const handleChange = (event: SelectChangeEvent<typeof filterValue>) => {
      const {
        target: { value },
      } = event;

      // On autofill we get a stringified value.
      const valueArray = !Array.isArray(value) ? value.split(',') : value;

      if (value.includes('all')) {
        setFilter(
          filterValue.length === options.length
            ? []
            : options.map((option) => option.value)
        );
      } else {
        setFilter(valueArray);
      }
    };

    const getRenderValue = (selected: PickerItemValue[]) => {
      const tags: string[] = [];

      if (Array.isArray(selected)) {
        selected.forEach((optValue) => {
          for (const option of options) {
            if (option.value === optValue) {
              tags.push(t(option.label));
            }
          }
        });
      }

      const joinedTags = tags.join(', ');

      return joinedTags.length > 35
        ? joinedTags.substring(0, 35).trim() + '...'
        : joinedTags;
    };

    return (
      <FormControl>
        <Select
          sx={{ minWidth: '125px' }}
          MenuProps={{
            variant: 'menu',
            MenuListProps: {
              sx: { padding: 0 },
            },
          }}
          variant="outlined"
          id="multiple-checkbox"
          multiple
          value={filterValue}
          input={<OutlinedInput size="small" margin="dense" />}
          renderValue={getRenderValue}
          onChange={handleChange}
        >
          <MenuItem value="all" sx={{ padding: 0 }}>
            <Checkbox
              checked={filterValue && filterValue.length === options.length}
              indeterminate={
                filterValue &&
                filterValue.length > 0 &&
                filterValue.length < options.length
              }
            />
            <ListItemText primary={t('common.all')} />
          </MenuItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          {options.map((option, index) => (
            <MenuItem
              key={'multi-filter-' + id + '-' + index}
              value={option.value as number}
              sx={{
                padding: 0,
                '&.Mui-selected': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Checkbox
                checked={
                  filterValue ? filterValue.includes(option.value) : false
                }
              />
              <ListItemText primary={t(option.label)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  return DateYearsFilterFn;
};

export default DateYearsColumnFilter;
