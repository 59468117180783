import React, { useContext, useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord, DCResponseSuccess } from '@/@types/lib/dataController';
import { IDataController } from '@/lib/DataController';

// MUI
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

// Custom Components

import ModelTable from '@/ui/Table/ModelTable';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import DialogContext from '@/context/DialogContext/DialogContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';

import model from '@/models/ttoken';

import dataController from '@/lib/DataController';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import { LoaderContextType } from '@/context/LoaderContext/LoaderContext';

import GeneralDialog from '@/ui/Dialog/GeneralDialog';
import TableButtonDelete from '@/ui/Table/ActionButtons/TableButtonDelete';

function TenantsTable() {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [recordsFilteredNamespace, setRecordsFilteredNamespace] = useState<
    DCRecord[]
  >([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);

  const [selectedNamespace, setSelectedNamespace] = useState<string>('');

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(GeneralDialog, {
      dc: dc,
      mode: 'insert',
      form: 'insert',
      onClose: handleDialogClose,
      title: t('titles.translation'),
    });
  };

  // sel sadrži JSON objekt gdje je row id ključ, te je value DCRecord selektiranih objekata. S
  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const record = sel[Object.keys(sel)[0]];

    dialogContext.showDialog(GeneralDialog, {
      dc: dc,
      mode: 'update',
      form: 'update',
      onClose: handleDialogClose,
      initialRecord: record,
      title: t('titles.translation'),
    });
  };

  const handleDelete = async (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const record = sel[Object.keys(sel)[0]];

    const confirmResult = await dialogContext.showConfirmDialog({
      title: t('title.translation') as string,
      text: t('translation.delete_confirm') + ': ' + record.ttoken,
      confirmButtonText: t('buttons.confirm') as string,
      cancelButtonText: t('buttons.cancel') as string,
    });

    if (confirmResult && record.id) {
      await dc
        .DeleteRecord(record.id as number)
        .then((result) => {
          //console.log(result)
        })
        .finally(() => {
          refreshRecords();
        });
    }
  };

  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
  }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);

    let newNamespaces: string[] = [];

    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            for (let i = 0; i < resp.data.length; i++) {
              const namespace = resp.data[i].t_namespace as string;

              if (namespace && !newNamespaces.includes(namespace)) {
                newNamespaces.push(namespace);
              }
            }

            setNamespaces(newNamespaces);
            setRecords(buildRecordTree(resp.data));
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        if (
          selectedNamespace === '' ||
          !newNamespaces.includes(selectedNamespace)
        ) {
          setSelectedNamespace(newNamespaces[0]);
        }
        loaderContext.toggleLoading(false);
      });
  };

  const buildRecordTree = (records: DCRecord[]) => {
    const root: DCRecord = { ttoken: '', subRows: [] };

    const addRecord = (
      node: DCRecord,
      tokens: string[],
      record: DCRecord,
      path: string = ''
    ) => {
      const token = tokens.shift() || '';
      const fullPath = path ? `${path}.${token}` : token;
      let child = (node.subRows as DCRecord[]).find(
        (r) => r.ttoken === fullPath && r.tenant_id === record.tenant_id && r.t_namespace === record.t_namespace
      );      
      if (!child) {
        child = {
          ttoken: fullPath,
          subRows: [],
          t_namespace: record.t_namespace,
          tenant_id: record.tenant_id,
          trans_en: null,
          trans_hr: null,
          id: null,
        };
        if (node.subRows) {
          (node.subRows as DCRecord[]).push(child);
        }
      }
    
      if (tokens.length === 0) {
        Object.assign(child, record);
      } else {
        addRecord(child, tokens, record, fullPath);
      }
    };    

    records.forEach((record) => {
      if (record.ttoken) {
        const tokens = (record.ttoken as string).split('.');
        addRecord(root, tokens, record);
      }
    });

    return root.subRows as DCRecord[];
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedNamespace(event.target.value as string);
  };

  const filterRecordsByNamespace = () => {
    const filteredRecords = records.filter((record) => {
      if (record.t_namespace) {
        return (record.t_namespace as string) === selectedNamespace;
      }
      return false;
    });

    setRecordsFilteredNamespace(filteredRecords);
  };

  useEffect(() => {
    if (selectedNamespace !== '') {
      filterRecordsByNamespace();
    }
  }, [selectedNamespace, records]);

  const handleRowClick = (evt: React.SyntheticEvent, selection: {[key: string]: DCRecord}) => {
    //console.log("clicked:");
  };

  return (
    <>
      <FormControl sx={{ width: '300px', mt: 2, mb: 1 }}>
        <InputLabel id="namespace-dropdown-label">
          {t('translation.namespace')}
        </InputLabel>
        <Select
          labelId="namespace-dropdown-label"
          id="namespace-dropdown"
          value={selectedNamespace}
          label={t('translation.namespace')}
          onChange={handleChange}
        >
          {namespaces.map((namespace) => {
            return (
              <MenuItem
                key={namespace + '-menu-item'}
                id={namespace + '-menu-item'}
                value={namespace}
              >
                {namespace}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box height={"calc( 100% - 80px)"}>
        <ModelTable
          defaultPageSize={50}
          title={t('titles.translation')}
          records={recordsFilteredNamespace}
          dc={dc}
          allowSelection="one"
          allowFilter={true}
          allowExport={true}
          allowAdd={true}
          addLabel={t('buttons.new_m') as string}
          handleAdd={handleAdd}
          onRowAction={() => {}}
          allowRowAction={() => true}
          onRowClick={handleRowClick}
          onDoubleClick={handleEdit}
          unselectRow={false}
        >
          <TableButtonDelete variant="contained" onClick={handleDelete} />
          <TableButtonEdit variant="contained" onClick={handleEdit} />
        </ModelTable>
      </Box>
    </>
  );
}

export default TenantsTable;
