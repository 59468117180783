import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from '@/routes';
import UserProvider from '@/context/UserContext/UserProvider';
import ThemeProvider from '@/context/ThemeContext/ThemeProvider';
import SnackbarProvider from '@/context/SnackbarContext/SnackbarProvider';
import SnackbarContainer from '@/context/SnackbarContext/SnackbarContainer';
import DialogProvider from '@/context/DialogContext/DialogProvider';
import DialogContainer from '@/context/DialogContext/DialogContainer';
import LoaderProvider from '@/context/LoaderContext/LoaderProvider';
import LoaderContainer from '@/context/LoaderContext/LoaderContainer';
import MapConfigProvider from '@/context/MapConfigContext/MapConfigProvider';
import ErrorProvider from '@/context/ErrorContext/ErrorProvider';
import ErrorContainer from '@/context/ErrorContext/ErrorContainer';
import i18n from '@/i18n';

// TODO maybe connect to a default language column in db?
i18n.changeLanguage('hr');

const App = () => (
  <ThemeProvider>
    <UserProvider>
      <SnackbarProvider>
        <LoaderProvider>
          <ErrorProvider>
            <MapConfigProvider>
              <DialogProvider>
                <BrowserRouter basename={process.env.REACT_APP_DEPLOYFOLDER}>
                  <Routes>
                    {routes.map((prop) => (
                      <Route
                        path={prop.path}
                        key={prop.path}
                        element={React.createElement(prop.component)}
                      />
                    ))}
                  </Routes>
                </BrowserRouter>
                <DialogContainer />
              </DialogProvider>
            </MapConfigProvider>
            <ErrorContainer />
          </ErrorProvider>
          <LoaderContainer />
          <SnackbarContainer />
        </LoaderProvider>
      </SnackbarProvider>
    </UserProvider>
  </ThemeProvider>
);

export default App;
