import React, { useRef, useContext, useEffect, FunctionComponent } from 'react';
import clsx from 'clsx';

// MUI Components
import Box from '@mui/material/Box';
import { SxProps, styled } from '@mui/material/styles';

// Custom components
import MapContext from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';
import ScaleRatio from './scaleratio';
import makeMUIControlStyle from '../makeMUIControlStyle';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IScaleRatioRoot } from '@/@types/components/Map/Controls/ScaleRatio';

const ScaleRatioRoot: FunctionComponent<IScaleRatioRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { id, ref, className } = props;

  const scaleRatioRef = useRef(ref);

  useEffect(() => {
    if (scaleRatioRef && scaleRatioRef.current) {
      const options = {
        element: scaleRatioRef.current,
      };

      const allOptions = Object.assign(options, props);
      const definedOptions = getDefinedOptions(allOptions);

      const events = {};

      const control = new ScaleRatio(definedOptions);

      if (id) {
        control.set('id', id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ScaleRatio);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      const olEvents = getEvents(events, props);
      for (const eventName in olEvents) {
        // @ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ScaleRatio);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <Box
      id="scaleratio-control"
      className={clsx(
        className,
        'ol-control ol-scale-ratio ol-sidebar-sticky ol-scale ol-unselectable ol-control scaleratio'
      )}
      ref={scaleRatioRef}
      sx={scaleRatioStyle}
      >
      {props.children}
    </Box>
  );
};

const scaleRatioStyle: SxProps = {
  position: "absolute",
  left: "0.5em",
  bottom: "2.5em",
  padding: "2px",
  borderRadius: "4px",
  textAlign: "center",
  maxWidth: "max-content",
  zIndex: 2000,
  bgcolor: 'primary.main',
  color: '#ff0000',
  opacity: 0.75,
}

const StyledScaleRatioControl = styled(ScaleRatioRoot)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control,
    ...styles.sidebarSticky,
  };
});

export default StyledScaleRatioControl;
