import { TFunction } from 'i18next';

// OpenLayers
import OlSourceOSM from 'ol/source/OSM';
import OlSourceVector from 'ol/source/Vector';
import OlSourceStamen from 'ol/source/Stamen';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlSourceImageWMS from 'ol/source/ImageWMS';
import OlSourceWMTS from 'ol/source/WMTS';
import OlSourceXYZ from 'ol/source/XYZ';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';

import OlWMTSTileGrid from 'ol/tilegrid/WMTS';

import OlLayerTile from 'ol/layer/Tile';
import OlLayerImage from 'ol/layer/Image';
import OlLayerVector from 'ol/layer/Vector';
import OlLayerGroup from 'ol/layer/Group';

import OlCollection from 'ol/Collection';
import OlBaseLayer from 'ol/layer/Base';

import OlLineString from 'ol/geom/LineString';

import OlFeature from 'ol/Feature';

import { transform } from 'ol/proj';

import { Geometry } from 'ol/geom';

// Custom Components
import useApi from '@/lib/api/useApi';

// Types
import {
  BaseLayerDefinition,
  BaseLayersData,
  BaseLayerSourceType,
  BaseLayerLayerType,
  DefaultMapData,
  LayerDefinition,
  DataLayerSourceType,
  DataLayerLayerType,
  RoutingInfo,
  RouteInfo,
} from '@/@types/services/mapService';
import { DCRecord } from '@/@types/lib/dataController';

export interface IMapService {
  getLayers: (mapId?: number) => Promise<OlCollection<OlBaseLayer>>;
  getBaseLayers: (t: TFunction, mapId?: number, allowNoLayer?: boolean) => Promise<OlLayerGroup>;
  getDefaultData: (mapId?: number) => Promise<DefaultMapData | null>;
  getRoute?: (feat: OlFeature<OlLineString>) => Promise<RoutingInfo | null>;
}

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

const mapService: IMapService = {
  getBaseLayers,
  getLayers,
  getDefaultData,
  getRoute,
};

function getDefaultData(mapId: number = 1) {
  const apiInstance = useApi();
  return apiInstance.get(`core/maps/${mapId}/settings`).then((resp) => {
    if (resp && resp.data) {
      return resp.data as DefaultMapData;
    }
    return null;
  });
}
function getBaseLayers(t: TFunction, mapId: number = 1, allowNoLayer?: boolean): Promise<OlLayerGroup> {
  const apiInstance = useApi();
  return apiInstance.get(`core/maps/${mapId}/baselayers`).then((resp) => {
    if (resp.success) {
      const baselayerDefinitions = resp.data as BaseLayersData;
      const baselayers = prepareBaseLayers(baselayerDefinitions, t, mapId, allowNoLayer);
      return new OlLayerGroup({
        layers: baselayers,
      });
    }
    return new OlLayerGroup({
      layers: [],
    });
  });
}

function getLayers(mapId: number = 1): Promise<OlCollection<OlBaseLayer>> {
  const apiInstance = useApi();
  return apiInstance.get(`core/maps/${mapId}/layers`).then((resp) => {
    if (resp.success) {
      const layerDefinitions = resp.data as Array<LayerDefinition>;
      const layers = prepareLayers(layerDefinitions);
      const coll = new OlCollection(layers);
      return coll;
    }
    return new OlCollection();
  });
}

function getRoute(feat: OlFeature<OlLineString>): Promise<RoutingInfo | null> {
  const geom = feat.getGeometry();
  if (geom) {
    const p1 = transform(geom.getFirstCoordinate(), 'EPSG:3857', 'EPSG:3765');
    const p2 = transform(geom.getLastCoordinate(), 'EPSG:3857', 'EPSG:3765');
    // const wktFormatter = new OlFormatWKT();
    // const wkt = wktFormatter.writeFeature(feat, {
    //   dataProjection: "EPSG:3857",
    //   featureProjection: "EPSG:3857"
    // } )
    const apiInstance = useApi();
    const data = { p1: `${p1[0]},${p1[1]}`, p2: `${p2[0]},${p2[1]}` };
    return apiInstance.post('core/routing/route-staze', data).then((resp) => {
      if (resp.success && resp.data) {
        const rec: DCRecord = resp.data as DCRecord;
        const duzina = rec.total_length;
        // @ts-ignore
        const wkb = rec.segments?.seg_geom
          ? // @ts-ignore
            rec.segments.seg_geom.substring(2)
          : null;

        if (wkb === null) {
          return null;
        }

        const routeInfo: RouteInfo = {
          id: 1,
          code: 'A',
          type: 'shortest',
          total_length: duzina as number,
          total_time: rec?.total_time as string,
          min_H: rec?.min_H as number,
          max_H: rec?.max_H as number,
          total_ascent: rec?.total_ascent as number,
          total_descent: rec?.total_descent as number,
          wkb,
        };
        return [routeInfo];
      }
      return null;
    });
  }
  return Promise.reject(null);
}

const getBaseLayerSource = (def: BaseLayerDefinition) => {
  switch (def.layer_source) {
    case BaseLayerSourceType.ImageWMS:
      return new OlSourceImageWMS({
        url: def.url ? def.url : gs_url,
        params: {
          LAYERS: def.layer,
          FORMAT: def.format,
        },
      });
    case BaseLayerSourceType.TileWMS:
      return new OlSourceTileWMS({
        url: def.url ? def.url : gs_url,
        projection: def.projection,
        params: {
          LAYERS: def.layer,
          FORMAT: def.format,
          TILED: !!def.tiled,
        },
      });
    case BaseLayerSourceType.OSM:
      return new OlSourceOSM();
    case BaseLayerSourceType.STAMEN:
      return new OlSourceStamen({
        layer: def.layer,
      });
    default:
      return new OlSourceOSM();
  }
};

const getBaseLayerLayer = (def: BaseLayerDefinition, options: any) => {
  switch (def.layer_type) {
    case BaseLayerLayerType.ImageLayer:
      return new OlLayerImage(options);
    case BaseLayerLayerType.TileLayer:
      return new OlLayerTile(options);
    default:
      return null;
  }
};

function prepareBaseLayers(
  defs: Array<BaseLayerDefinition>,
  t: TFunction,
  mapId: number,
  allowNoLayer?: boolean
): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];
  let first = true;

  if (!defs) {
    defs = [];
  }

  // Retrieve and parse localStorage data with error handling
  let parsedMapBaseLayers: { [key: number]: string } = {};

  try {
    const mapBaseLayers = localStorage.getItem("mapbaselayers");
    if (mapBaseLayers) {
      parsedMapBaseLayers = JSON.parse(mapBaseLayers);
    }
  } catch (error) {
    console.error("Error parsing mapbaselayers from localStorage:", error);
    // Fallback to an empty object if parsing fails
    parsedMapBaseLayers = {};
  }

  let layerFoundInStorage = false;

  // Add solid color layer if allowNoLayer is true
  if (allowNoLayer) {
    const solidColorLayer = new OlVectorLayer({
      source: new OlVectorSource({}),
    });
    solidColorLayer.set("id", "base-solid");
    solidColorLayer.set("title", "N/A");
    solidColorLayer.set("type", "base");
    solidColorLayer.set("baseLayer", true);
    solidColorLayer.set("code", "no_layer");
    solidColorLayer.setVisible(false);

    // Check if the solid color layer is selected in localStorage
    if (parsedMapBaseLayers[mapId] === "no_layer") {
      solidColorLayer.setVisible(true);
      layerFoundInStorage = true;
    }

    layers.push(solidColorLayer);
  }

  // Iterate over all base layers from defs and handle visibility
  defs.forEach((def) => {
    const options = {
      source: getBaseLayerSource(def),
    };

    const layer = getBaseLayerLayer(def, options);
    if (layer) {
      layer.set('id', `base-${def.id.toString()}`);
      layer.set('title', t(def.ttoken) as string);
      layer.set('type', 'base');
      layer.set('baseLayer', true);
      layer.set('preview', `data:image/jpg;base64,${def.preview_base64}`);
      // layer.set("preview", "http://www.culture.gouv.fr/Wave/image/memoire/2484/sap40_z0002136_v.jpg");
      layer.set('code', def.code);

      // Check if the mapId exists in mapBaseLayers and if the def code matches
      if (parsedMapBaseLayers[mapId] && def.code === parsedMapBaseLayers[mapId]) {
        layer.setVisible(true);
        layerFoundInStorage = true;
      } else {
        layer.setVisible(false);
      }

      layers.push(layer);
    }
  });

  // If no layer from localStorage was found, select the first layer (not "no_layer")
  if (!layerFoundInStorage) {
    layers.forEach((layer, index) => {
      // Ensure that "no_layer" is not selected by default
      if (layer.get("code") !== "no_layer" && first) {
        layer.setVisible(true); 
        parsedMapBaseLayers[mapId] = layer.get("code");
        localStorage.setItem("mapbaselayers", JSON.stringify(parsedMapBaseLayers));
        first = false; // Break loop!
      } else {
        layer.setVisible(false);
      }
    });
  }

  return layers;
}

function getDataLayerSource(def: LayerDefinition) {
  switch (def.gs_layer_source) {
    case DataLayerSourceType.GROUP:
      return null;
    case DataLayerSourceType.TileWMS:
      return new OlSourceTileWMS({
        url: def.gs_url ? def.gs_url : gs_url,
        params: {
          LAYERS: def.gs_layer,
          FORMAT: def.gs_format,
          TILED: !!def.gs_tiled,
        },
      });
    case DataLayerSourceType.ImageWMS: {
      const imageWMSoptions = {
        url: def.gs_url ? def.gs_url : gs_url,
        params: {
          LAYERS: def.gs_layer ? def.gs_layer : '',
          FORMAT: def.gs_format,
        },
      };
      return new OlSourceImageWMS(imageWMSoptions);
    }
    case DataLayerSourceType.WMTS: {
      const wmtsOptions = {
        format: def.gs_format, // Odaberi hibridni format (default image/jpeg)
        layer: def.gs_layer ? def.gs_layer : '',
        matrixSet: 'EPSG:900913', // Ime gridseta
        style: '',
        tileGrid: createEPSG3857Grid(), // Gore kreirani gridset
        url: def.gs_url,
      };
      return new OlSourceWMTS(wmtsOptions);
    }
    case DataLayerSourceType.TMS:
      return new OlSourceXYZ({
        url: def.gs_url,
      });
    case DataLayerSourceType.OSM:
      return new OlSourceOSM();
    case DataLayerSourceType.STAMEN:
      return new OlSourceStamen({
        layer: def.gs_layer,
      });
    case DataLayerSourceType.VectorSource:
      return new OlSourceVector({});
    default:
      return null;
  }
}

function getDataLayerLayer(def: LayerDefinition): OlBaseLayer {
  switch (def.gs_layer_type) {
    case DataLayerLayerType.GROUP: {
      const options = {
        fold: 'open',
        visible: def.ui_visible,
        openInLayerSwitcher: true,
      };
      if (
        def.ui_switcher_options !== '' &&
        def.ui_switcher_options !== undefined
      ) {
        Object.assign(options, JSON.parse(def.ui_switcher_options));
      }
      return new OlLayerGroup(options);
    }
    case DataLayerLayerType.TileLayer:
      return new OlLayerTile({
        source: getDataLayerSource(def) as OlSourceTileWMS,
        visible: def.ui_visible,
      });
    case DataLayerLayerType.VectorLayer:
      return new OlLayerVector({
        source: getDataLayerSource(def) as OlSourceVector<Geometry>,
        visible: def.ui_visible,
      });
    case DataLayerLayerType.ImageLayer:
    default:
      return new OlLayerImage({
        source: getDataLayerSource(def) as OlSourceImageWMS,
        visible: def.ui_visible,
      });
  }
}

function flatDeep(arr: Array<OlBaseLayer>, d: number = 1): Array<OlBaseLayer> {
  return d > 0
    ? arr.reduce(
        (acc: Array<OlBaseLayer>, val) =>
          acc.concat(
            val instanceof OlLayerGroup && val.getLayers().getArray().length > 0
              ? ([val] as Array<OlBaseLayer>).concat(
                  flatDeep(val.getLayers().getArray(), d - 1)
                )
              : val
          ),
        []
      )
    : arr.slice();
}

function prepareLayers(defs: Array<LayerDefinition>): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];

  if (defs && defs.length > 0) {
    defs.forEach((def: LayerDefinition) => {
      const layer = getDataLayerLayer(def);
      if (layer) {
        layer.set('id', `${def.ui_code.toString()}`);
        layer.set('title', def.ui_ttoken);
        layer.set('type', 'app-defined');
        layer.set('layer', def.gs_layer);
        layer.set('query', def.gs_can_query);
        layer.set('call_group', def.gs_call_group);
        layer.set('format', def.gs_format);
        layer.set('tiled', def.gs_tiled);
        layer.set('use_cache', def.use_cache);
        layer.set('z_index', def.ui_z_index);
        layer.set('url', def.gs_url);
        layer.set('extent', def.ui_extent);
        layer.set('styles', def.gs_styles);
        layer.set('timeseries', def.gs_timeseries);
        layer.set('zoomable', def.ui_zoomable);
        layer.set(
          'visible_min_zoom',
          def.gs_visible_min_zoom ? def.gs_visible_min_zoom : undefined
        );
        layer.set(
          'visible_max_zoom',
          def.gs_visible_max_zoom ? def.gs_visible_max_zoom : undefined
        );

        layer.set('legend', def.ui_legend);
        layer.set('legend_ttoken', def.legend_ttoken);
        layer.set('legend_width', def.legend_width);
        layer.set('legend_height', def.legend_height);
        layer.set('legend_rule', def.legend_rule);
        layer.set('legend_style', def.legend_style);
        layer.set('legend_options', def.legend_options);
        layer.set('legend_ttoken', def.legend_ttoken);
        layer.set('legend_conf_id', def.legend_conf_id);

        const model = {
          fields: def.fields,
          title_token: def.title_token,
          title_field: def.title_field,
          title_type: def.title_type,
        };
        layer.set('model', model);
        layer.set('has_opacity_slider', def.ui_has_opacity_slider);

        layer.set('layer_functionality_id',def.layer_functionality_id);

        if (
          def.ui_parent_layer_code === null ||
          def.ui_parent_layer_code === undefined
        ) {
          layers.push(layer);
        } else {
          const parentCode = def.ui_parent_layer_code;
          const flatLayers: Array<OlBaseLayer> = layers
            ? flatDeep(layers, 3)
            : [];
          const parentLayer = flatLayers.find(
            (x) => x.get('id') === `${parentCode}`
          ) as OlLayerGroup;
          if (parentLayer) {
            parentLayer.setLayers(
              new OlCollection([...parentLayer.getLayers().getArray(), layer])
            );
          }
        }
      }
    });
  }

  return layers;
}

function createEPSG3857Grid() {
  // Kreiraj grid za EPSG:900913/EPSG:3857
  const maxZoom = 22;
  const resolutions = new Array(maxZoom);
  const matrixIds = new Array(maxZoom);
  for (let z = 0; z <= maxZoom; z++) {
    resolutions[z] = 40075016 / 256 / 2 ** z;
    matrixIds[z] = `EPSG:900913:${z}`; // Capabilities/Contents/TileMatrixSet/TileMatrix/ows:Identifier
  }
  const grid = new OlWMTSTileGrid({
    origin: [-20037508, 20037508], // Capabilities/Contents/TileMatrixSet/TileMatrix/TopLeftCorner
    resolutions,
    matrixIds,
    extent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34],
  });

  return grid;
}

export default mapService;
