import React, { MutableRefObject } from 'react';

// MUI
import Checkbox from '@mui/material/Checkbox';

// Types
import { ITableCheckboxProps } from '@/@types/ui/Table';

const TableCheckbox = React.forwardRef<HTMLInputElement, ITableCheckboxProps>(
  (props, ref) => {
    const { indeterminate, ...rest } = props;
    const resolvedRef =
      ref !== null ? ref : React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      // MutableRefObject is necessary because we are forwarded one type of ref and we create another type of ref
      // one forwarded is interpreted as a function while one created is interpreted as an element
      (
        resolvedRef as MutableRefObject<HTMLInputElement>
      ).current.indeterminate = Boolean(indeterminate);
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        inputRef={resolvedRef}
        indeterminate={indeterminate}
        color="primary"
        size="small"
        sx={{
          '.MuiCheckbox-root': {
            p: 0,
          },
        }}
        {...rest}
      />
    );
  }
);

export default TableCheckbox;
