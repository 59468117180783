import { FunctionComponent, useContext, useEffect } from "react";

//MUI
import { styled } from "@mui/material/styles";

//OpenLayers
import ZoomToExtent from "ol/control/ZoomToExtent";

//Custom components
import MapContext from "@/context/MapContext/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { IZoomToExtentControl } from "@/@types/components/Map/Controls/OpenLayers";
import { MapContextType } from "@/context/MapContext/MapContext";

const ZoomToExtentControl: FunctionComponent<IZoomToExtentControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const defaultHtmlElement = document.createElement('i');

  const { isSidebar } = props;
  
  const labelClass = props.id === "zoom-extent-selected" ? "fas fa-bullseye" : "fas fa-expand";
  
  defaultHtmlElement.setAttribute('class', labelClass);

  const options = {
    className: undefined,
    target: undefined,
    label: defaultHtmlElement,
    tipLabel: undefined,
    extent: undefined
  };

  const events = {
    change: undefined,
    propertychange: undefined
  };


  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new ZoomToExtent(definedOptions);

    if (props.id) {
      control.set("id", props.id);
    }

    if (context.map) {
      const mapControl = findControl(context.map, props.id, ZoomToExtent);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
      context.map.addControl(control);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO: Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, ZoomToExtent);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    };
  }, [
    props.className,
    props.target,
    props.label,
    props.tipLabel,
    props.extent
  ]);

  return null;
};

const StyledZoomToExtentControl = styled(ZoomToExtentControl, {
  shouldForwardProp: (prop) => prop !== 'isSidebar',
})(({ theme, isSidebar }) => {
  const styles = makeMUIControlStyle(theme);

  return {
    ...(isSidebar ? styles.sidebarItem : styles.control)
  };
});

export default StyledZoomToExtentControl;
