import * as React from 'react';
import { FunctionComponent } from 'react';

// MUI
import Grid from '@mui/material/Grid';

// Types
import { GridProps } from '@mui/material/Grid';

const GridContainer: FunctionComponent<GridProps> = (props) => (
  <Grid container spacing={props.spacing ? props.spacing : 2} {...props}>
    {props.children}
  </Grid>
);

export default GridContainer;
