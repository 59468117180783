import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Core Components
import { CircularProgress, Typography, Box, Pagination, Stack } from "@mui/material";

// Material-UI Icons
import EditIcon from "@mui/icons-material/Edit";

// Custom Components
import CommentIcon from '@mui/icons-material/SpeakerNotesOutlined';
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import dataController from "@/lib/DataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/context/DialogContext/DialogContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import modelComments from "@/models/comments"
import CommentDialog from "./CommentDialog"
import ImageSlider from "@/views/Comments/ImageSlider"
import {  } from "@mui/material";


// Types
import { MiscCardProps } from "@/@types/views/map";
import { DCRecord } from "@/@types/lib/dataController";
import Image360 from "@/ui/Image360/Image360";


const MiscCard = (props: MiscCardProps) => {
  const { t } = useTranslation();

  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [comment, setComment] = useState<any>(null);

  const [page360,setPage360] = useState<number>(props.featureData.type==="photos360"?1:-1)
  const [paginationDisabled, setPaginationDisabled] = useState(false);

  const dialogContext = useContext(DialogContext);

  const dc = new dataController(modelComments);

  const { featureData, onClose, onCommentDelete, refreshLayers, refreshPhotos } = props;

  const {type} = featureData;

  const refreshComment = () => {
    // @ts-ignore 
    setLoadStatus({ isLoading: true, isLoaded: false });
    dc.GetDataSingle(Number(featureData.features[0].getId())).then((resp) => {
      if(resp && resp.data) {
        setComment(resp.data)
      } else {
        // setComments(null)
      }
    })
    .finally(() => {
      setLoadStatus({ isLoading: false, isLoaded: true });
    })
  }

  useEffect(() => {
    if(type === 'commentinfo') {
      refreshComment();
    }
  }, [featureData]);


  const handleCommentDialogClose = (result: any) => {
    if (result.dataChanged && result.action !== "delete") {
      if(refreshPhotos && featureData.record && featureData.record.length !== 0) {
        refreshPhotos(featureData.features[0], undefined, featureData.position);
      } else {
        refreshComment();
      }
    } else if (result.action == "delete") {
      if (onCommentDelete) {
        onCommentDelete()
      }
      onClose()
    } else if(refreshPhotos && featureData.record && featureData.record.length !== 0) {
        refreshPhotos(featureData.features[0], undefined, featureData.position);
      }
  };

  const handleEditComment = () => {
    dialogContext.showDialog(CommentDialog, {
      dc,
      mode: "update",
      form: "insert",
      dataId: comment.id,
      onClose: handleCommentDialogClose,
      feature: featureData.features[0],
      refreshLayers: () => {
        if(refreshPhotos) {
          refreshPhotos(featureData.features[0], undefined, featureData.position);
        } else {
          refreshComment();
        }
      },
      // @ts-ignore
      initialRecord: {
        note: comment.note,
        photos: featureData.record && Array.isArray(featureData.record) && featureData.record?.length !== 0 ?
        featureData?.record : {} 
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage360(value);
    setPaginationDisabled(true)
  };
  const finishLoad = () => {
    setPaginationDisabled(false);
  }
  const page360Size = featureData.type==="photos360" && Array.isArray(featureData.features) ? featureData.features.length : 0;

  const form = "default";

  return (
      <MapCard
        sx={{
          backgroundColor: "#ececec",
          width: type === "photos360" ? "615px" : "300px",
        }}
      > 
        
            <>
              <MapCardHeader
                title={comment?.created_by}
                subheader={`${t("common.id")  }: ${  comment?.id}`}
                subheaderTypographyProps={{ color: "initial" }}
                avatar={<CommentIcon sx={{ color: "#FFF" }} fontSize="large"/>}
                action={<MapCardHeaderButtonClose onClick={onClose} />}
              />
              <MapCardContent >
              { 
                type==='commentinfo'?
                  (loadStatus.isLoaded ?
                    <Box display="flex" flexDirection="column"  m={1} sx={{
                      borderRadius:"5px",
                      boxShadow: "0px 3px 7px -4px rgba(0,0,0,0.60)",
                      WebkitBoxShadow: "0px 3px 7px -4px rgba(0,0,0,0.60)",
                      MozBoxShadow: "0px 3px 7px -4px rgba(0,0,0,0.60)",
                      backgroundColor: "#ffffff"
                    }}>
                      <Box width="100%">
                          {featureData.record && featureData.record !== null && Array.isArray(featureData.record) ? (
                            <ImageSlider images={featureData.record.map(rec => rec.image)}/>
                            ) : (
                            <ImageSlider images={[]} />
                          )}
                      </Box>
                      <Box width="100%" py={0.75} px={2} minHeight="75px">
                        {comment && comment.note!==null ? (
                              <Box  sx={{ textAlign: 'left', wordWrap: 'break-word' }}
                              >{comment.note}</Box> // todo fix , use form controller maybe?
                            ) : (
                              <></>
                          )}
                      </Box>
                    </Box>
                  : 
                    <CircularProgress />)
                  : type === 'photos360' ?
                    <Stack spacing={1} alignContent={"center"}>

                      <Image360 finishLoad={finishLoad} imageId={featureData.features[page360-1].properties.id} northOffset={featureData.features[page360-1].properties.orijentacija}/> 
                      <Pagination disabled={paginationDisabled}  sx={{display:"flex", justifyContent: "center"}} color="primary" count={page360Size} page={page360} onChange={handleChange}></Pagination>

                    </Stack>
                  : <></>
              }
              </MapCardContent>
              <MapCardActions 
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "0px",
                    marginBottom: "5px",
                    overflow: "hidden"
                  }} 
              >
                {
                // <MapCardActionButton startIcon={<EditIcon />} onClick={handleEditComment}>
                //  {t("buttons.edit_comment")}
                // </MapCardActionButton>
                }
              </MapCardActions>
            </> 
         
      </MapCard>
  );
}

export default MiscCard;
