import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';

// Custom Components
import TableButton from './TableButton';

// Types
import { ITableActionButtonDetail } from '@/@types/ui/Table';

function TableButtonDelete(props: ITableActionButtonDetail) {
  const { t } = useTranslation();

  return (
    <TableButton
      variant="outlined"
      allow="many"
      startIcon={<DeleteIcon />}
      selection={{}}
      {...props}
    >
      {t('buttons.delete')}
    </TableButton>
  );
}

export default TableButtonDelete;
