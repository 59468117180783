import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import buttonFields from './helpers/buttonFields';

const User: IModel = {
  title: 'Users',
  apiPath: 'system/users',
  forms: {
    login: {
      fields: [{ source: 'username', readonly: false }, 'password'],
    },
    register: {
      fields: [
        { source: 'username', readonly: false },
        'password',
        'password_confirm',
        'email',
        'first_name',
        'last_name',
      ],
    },
    insert: {
      fields: [
        { source: 'username', readonly: false },
        'password',
        'password_confirm',
        { source: 'email', readonly: false },
        'first_name',
        'last_name',
        // { source: 'app_permissions', readonly: false },
        // 'status',
        'user_type_id',
        { source: 'tenant_id', readonly: false },
        'remarks',
      ],
    },
    tenantuser_insert: {
      fields: [
        'first_name',
        'last_name',
        { source: 'username', readonly: false },
        { source: 'email', readonly: false },
        'password',
        'password_confirm',
        // 'status',
        'user_type_id',
        'tenant_id',
        'remarks',
        // { source: 'app_permissions', readonly: false },
      ],
    },
    update: {
      fields: [
        'id',
        'username',
        'first_name',
        'last_name',
        'username',
        'email',
        // { source: 'app_permissions', readonly: false },
        'status',
        'user_type_id',
        'remarks',
        'is_anoniman',
      ],
    },
    password_reset_request: {
      fields: ['email']
    },
    password_reset: {
      fields: [
        'email',
        'passwordreset_token',
        'password_new',
        'password_new_confirm',
      ],
      validator: 'validatePasswordReset',
      hidden: ['passwordreset_token',]
    },
    password_change: {
      fields: [
        { source: 'password', ttoken: 'users.password_current' },
        'password_new',
        'password_new_confirm',
      ],
      validator: 'validatePasswordChange',
    },
    password_set: {
      fields: ['password', 'password_confirm'],
      validator: 'validatePasswordSet',
    },
    activate: {
      fields: [
        'email',
        'activation_token',
        'password',
        'password_new',
        'password_new_confirm',
      ],
      validator: 'validatePasswordChange',
    },
    search: {
      fields: [
        'full_name',
        { source: 'email', readonly: false, validation: null },
        { source: 'oib', validation: null },
        {
          source: 'status',
          type: 'radio',
          readonly: false,
          items: {
            labels: [
              'user_states.registriran',
              'user_states.aktivan',
              'user_states.neaktivan',
            ],
            values: [10, 20, 30],
          },
        },
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'username',
        'email',
        'first_name',
        'last_name',
        "tenant_id",
        'created_on',
        'status',
        'user_type_id'
      ],
    },
    tenant_admin: {
      fields: [
        'id',
        'username',
        'email',
        'full_name',
        'status',
        'user_type_id',
        'created_on',
        'created_by',
        'modified_on',
        'modified_by',
        'remarks',
        'edit',
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'Username',
      source: 'username',
      ttoken: 'users.username',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 50,
      },
      readonly: true,
    },
    {
      title: 'Ime',
      source: 'first_name',
      ttoken: 'users.first_name',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Prezime',
      source: 'last_name',
      ttoken: 'users.last_name',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Puno Ime',
      source: 'full_name',
      ttoken: 'users.full_name',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Ime i prezime',
      source: 'full_name',
      ttoken: 'users.full_name',
      type: 'text',
    },
    {
      title: 'E-mail',
      source: 'email',
      ttoken: 'users.email',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 250,
        regex: 'email',
      },
    },
    {
      title: 'OIB',
      source: 'oib',
      ttoken: 'users.oib',
      type: 'text',
      validation: {
        required: true,
        minLength: 11,
        maxLength: 11,
        regex: 'oib',
      },
    },
    {
      title: 'Broj telefona',
      source: 'telephone',
      ttoken: 'users.telephone',
      type: 'text',
      placeholder: '+385xxxxxxxxx',
      validation: {
        required: true,
        maxLength: 15,
        regex: 'tel',
      },
    },
    {
      title: 'Status',
      source: 'status',
      ttoken: 'common.status',
      type: 'picker',
      readonly: true,
      filter: true,
      items: {
        labels: [
          'user_states.registriran',
          'user_states.aktivan',
          'user_states.neaktivan',
          'user_states.anoniman',
        ],
        values: [10, 20, 30, 40],
        default: 20,
      },
      translate: true,
    },
    {
      title: 'Uloga',
      source: 'user_role_id',
      ttoken: 'users.uloga',
      type: 'picker',
      items: {
        labels: ['user_roles.korisnik', 'user_roles.administrator'],
        values: [10, 90],
        default: 10,
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'Tip',
      source: 'user_type_id',
      ttoken: 'users.type',
      type: 'picker',
      items: {
        labels: [
          'admin:user_types.regular',
          //'admin:user_types.public', // we don't want to add new public users. the only public user is set on tenant creation.
          'admin:user_types.service',
          'admin:user_types.internal',
        ],
        values: [
          1, 
          //2, 
          3, 
          4],
        default: 1,
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'Dozvole',
      source: 'app_permissions',
      ttoken: 'users.dozvole',
      type: 'checkbox',
      items: {
        labels: ['app.permissions.basic', 'app.permissions.administration'],
        values: [100, 900],
      },
      readonly: true,
    },
    {
      title: 'Napomena',
      source: 'remarks',
      ttoken: 'users.napomena',
      type: 'multiline',
      rows: 2,
      maxRows: 5,
      validation: {
        maxLength: 250,
      },
      width: 'full',
    },
    {
      title: 'Anonimni korisnik',
      source: 'is_anoniman',
      ttoken: 'users.anoniman',
      readonly: true,
      type: 'text',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [0, 1],
        default: 0,
      },
    },
    {
      title: 'Datum i vrijeme registracije',
      source: 'created_on',
      ttoken: 'common.created_on',
      type: 'datetime',
      filter: true,
      sort: true,
      readonly: true,
    },
    {
      title: 'Aktivacijski Ključ',
      source: 'activation_token',
      ttoken: 'users.activation_token',
      type: 'text',
      readonly: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Ključ za izmjenu lozinke',
      source: 'passwordreset_token',
      ttoken: 'users.passwordreset_token',
      type: 'text',
      readonly: true,
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'Lozinka',
      source: 'password',
      ttoken: 'users.password',
      type: 'password',
      validation: {
        required: true,
      },
    },
    {
      title: 'Potvrda lozinke',
      source: 'password_confirm',
      ttoken: 'users.password_confirm',
      type: 'password',
      validation: {
        required: true,
      },
    },
    {
      title: 'Nova lozinka',
      source: 'password_new',
      ttoken: 'users.password_new',
      type: 'password',
      validation: {
        required: true,
      },
    },
    {
      title: 'Potvrda nove lozinke',
      source: 'password_new_confirm',
      ttoken: 'users.password_new_confirm',
      type: 'password',
      validation: {
        required: true,
      },
    },
    {
      title: 'anonymize',
      source: 'anonymize',
      ttoken: '',
      type: 'boolean',
    },
    {
      title: 'active',
      source: 'active',
      ttoken: '',
      type: 'boolean',
    },
    coreFields.created_by,
    coreFields.modified_by,
    coreFields.modified_on,
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      validation: {
        required: true,
      },
      filter: true
    },
    {
      title: 'user_type_id',
      source: 'user_type_id',
      ttoken: 'user_type_id',
      type: 'numeric',
    },
    buttonFields.edit,
  ],
};

export default User;
