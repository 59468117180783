import React, { useContext, useEffect, useRef } from 'react';

// Custom Components

// OL
import OlBaseLayer from 'ol/layer/Base';
import OlTileLayer from 'ol/layer/Tile';
import OlImageLayer from 'ol/layer/Image';
import OlTile from 'ol/Tile';
import OlImageTile from 'ol/ImageTile';

import OlTileWMSSource from 'ol/source/TileWMS';
import OlWMTSTileGrid from 'ol/tilegrid/WMTS';
import OlWMTSSource from 'ol/source/WMTS';
import OlTMSSource from 'ol/source/XYZ';

// Types
import TileState from 'ol/TileState';
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import { findLayer } from '@/lib/olHelpers';
import ImageLayer from '@/components/Map/Layers/ImageLayer';
import TileLayer from '@/components/Map/Layers/TileLayer';

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

type GeoLayerProps = {
  id: string;
  key: number | string;
  layer: OlBaseLayer;
  visible?: boolean;
};

type StorageType = {
  [key: string]: any;
};

const GeoLayer = (props: GeoLayerProps) => {
  const { id, layer, visible } = props;
  const mapContext = useContext<MapContextType | null>(MapContext);

  const storageRef = useRef<StorageType>({});

  const checkIfLayerVisible = () => {
    const visibility = mapContext?.getLayerVisibility();
    const hiddenLayers = visibility
      ? Object.keys(visibility).filter((key) => visibility[key] === false)
      : [];
    // console.log(hiddenLayers);

    const isVisible = !(hiddenLayers.indexOf(id) >= 0);
    return isVisible;
  };

  const visibilityFromMap = checkIfLayerVisible();
  const visibilityFromProp = visible;

  const visibility = visible === undefined ? visibilityFromMap : visibilityFromProp;
  // console.log("render GS Layer", layer, visible);

  // useEffect(() => {
  //   if (mapContext?.map) {
  //     const visible = checkIfLayerVisible();
  //     layer.setVisible(visible);
  //     mapContext.map.addLayer(layer);
  //   }

  //   return () => {
  //     if (mapContext?.map) {
  //       const mapLayer = findLayer(mapContext.map, props.id);
  //       if (mapLayer) {
  //         mapContext.map.removeLayer(mapLayer);
  //       }
  //     }
  //   };
  // }, [mapContext?.map]);

  // useEffect(() => {
  //   if (mapContext?.map) {
  //     const mapLayer = findLayer(mapContext.map, props.id);
  //     if (mapLayer) {
  //       const isVisible = visible ? true : false;
  //       mapLayer.setVisible(isVisible);
  //     }
  //   }
  // }, [visible]);

  const customLoader = (tile: OlTile, src: string) => {
    //console.log(src);
    if (storageRef.current[src]) {
      // @ts-ignore
      tile.getImage().src = storageRef.current[src];
    } else {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.addEventListener('loadend', function (evt) {
        const data = this.response;
        if (data !== undefined) {
          // @ts-ignore
          tile.getImage().src = URL.createObjectURL(data);
        } else {
          tile.setState(TileState.ERROR);
        }
      });
      xhr.addEventListener('error', () => {
        tile.setState(TileState.ERROR);
      });
      xhr.addEventListener('load', function () {
        const data = this.response;
        if (data !== undefined) {
          storageRef.current[src] = data;
        }
      });
      xhr.open('GET', src);
      xhr.send();
    }
  };

  const useCache = layer.get('use_cache') === true;

  const isTileLayer = layer instanceof OlTileLayer;
  const isImageLayer = layer instanceof OlImageLayer;

  const isTileWMSSource = isTileLayer
    ? layer.getSource() instanceof OlTileWMSSource
    : false;
  const isTileWMTSSource = isTileLayer
    ? layer.getSource() instanceof OlWMTSSource
    : false;
  const isTileTMSSource = isTileLayer
    ? layer.getSource() instanceof OlTMSSource
    : false;

  const layersTime = mapContext?.getLayerTime();

  const timeseries = layer.get('timeseries');
  const hasSlider = !!timeseries;

  const time =
    layersTime && layersTime[id] && hasSlider ? layersTime[id] : undefined;

  let minZoom = layer.get('visible_min_zoom');
  if (minZoom !== undefined && minZoom > 0) {
    minZoom -= 1; // exclusive > inclusive limit
  }
  const maxZoom = layer.get('visible_max_zoom');

  if (isTileLayer)
    return (
      <TileLayer
        id={id}
        visible={visibility}
        zIndex={layer.get('z_index')}
        extent={layer.get('extent')}
        minZoom={minZoom}
        maxZoom={maxZoom}
        wms={
          isTileWMSSource
            ? {
                url: gs_url,
                serverType: 'geoserver',
                // tileLoadFunction: customLoader,
                // tileLoadFunction: useCache ? customLoader : undefined,
                params: {
                  LAYERS: layer.get('layer'),
                  FORMAT: layer.get('format'),
                  TILED: layer.get('tiled'),
                  STYLES: layer.get('styles'),
                  VIEWPARAMS:  "token:" + localStorage.getItem('auth_token'),
                  TIME: time,
                },
              }
            : undefined
        }
        wmts={
          isTileWMTSSource
            ? {
                url: layer.get('url'),
                layer: layer.get('layer'),
                // tileLoadFunction: customLoader,
                style: layer.get('styles'),
                format: layer.get('format'),
                matrixSet: (layer.getSource() as OlWMTSSource).getMatrixSet(),
                tileGrid: (
                  layer.getSource() as OlWMTSSource
                ).getTileGrid() as OlWMTSTileGrid,
                tileLoadFunction: (imageTile: OlTile, src: string) => {
                  //@ts-ignore
                  imageTile.getImage().src = src + "&authkey=" + localStorage.getItem("auth_token");
                },
                dimensions: time ? { TIME: time } : undefined,
              }
            : undefined
        }
        tms={
          isTileTMSSource
            ? {
                url: layer.get('url'),
                // tileLoadFunction: customLoader,
              }
            : undefined
        }
      />
    );
  if (isImageLayer)
    return (
      <ImageLayer
        id={id}
        visible={visibility}
        zIndex={layer.get('z_index')}
        extent={layer.get('extent')}
        minZoom={minZoom}
        maxZoom={maxZoom}
        wms={{
          url: gs_url,
          serverType: 'geoserver',
          ratio: 1.2,
          params: {
            LAYERS: layer.get('layer'),
            FORMAT: layer.get('format'),
            STYLES: layer.get("styles"),
            authkey: localStorage.getItem('auth_token') || undefined
          },
        }}
      />
    );
  return null;
};

export default GeoLayer;
