import React, { FunctionComponent, useContext, useEffect } from 'react';

// OpenLayers
import OlLayerImage from 'ol/layer/Image';
import OlSourceImageWMS, { Options as WMSOptions } from 'ol/source/ImageWMS';

// Custom components
import MapContext from '@/context/MapContext/MapContext';
import GroupLayerContext from './GroupLayer/GroupLayerContext';
import { findLayer, getDefinedOptions, getEvents } from '@/lib/olHelpers';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { GroupLayerContextType } from '@/@types/components/Map/Layers/GroupLayer';
import {
  ImageLayerProps,
  OlLayerImageOptions,
} from '@/@types/components/Map/Layers/layerImage';
import { OlSourceImageWMSOptions } from '@/@types/components/Map/Layers/sourceImageWMS';

const idKey = 'id';
const titleKey = 'title';
const typeKey = 'type';

const ImageLayer: FunctionComponent<ImageLayerProps> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const parentLayerContext = useContext(
    GroupLayerContext
  ) as GroupLayerContextType;

  let layer;

  const wmsLayers = props.wms?.params?.LAYERS;

  const options: OlLayerImageOptions = {
    className: undefined,
    opacity: undefined,
    visible: undefined,
    extent: undefined,
    zIndex: undefined,
    minResolution: undefined,
    maxResolution: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    map: undefined,
    source: undefined,
    properties: undefined,
  };

  const wmsOptions: OlSourceImageWMSOptions = {
    attributions: undefined,
    crossOrigin: undefined,
    hidpi: undefined,
    serverType: undefined,
    imageLoadFunction: undefined,
    interpolate: undefined,
    params: undefined,
    projection: undefined,
    ratio: undefined,
    resolutions: undefined,
    url: undefined,
  };

  const events = {
    change: undefined,
    'change:extent': undefined,
    'change:maxResolution': undefined,
    'change:maxZoom': undefined,
    'change:minResolution': undefined,
    'change:minZoom': undefined,
    'change:opacity': undefined,
    'change:source': undefined,
    'change:visible': undefined,
    'change:zIndex': undefined,
    error: undefined,
    postrender: undefined,
    prerender: undefined,
    propertychange: undefined,
  };

  const getSource = () => {
    if (props.source) {
      return props.source;
    }
    if (props.wms) {
      const allWMSOptions = Object.assign(wmsOptions, props.wms);
      // @ts-ignore TODO:  Property 'params' is missing in type '{}' but required in type 'Options'
      const definedWMSOptions: WMSOptions = getDefinedOptions(allWMSOptions);

      return new OlSourceImageWMS(definedWMSOptions);
    }
    return null;
  };

  useEffect(() => {
    // console.log('tile layer effect called');
    const allOptions = Object.assign(options, props);
    // @ts-ignore
    const definedOptions = getDefinedOptions(allOptions);
    // @ts-ignore TODO:  Property 'source' does not exist on type 'object'
    definedOptions.source = definedOptions.source || getSource();

    layer = new OlLayerImage(definedOptions);

    if (props.id) {
      layer.set(idKey, props.id);
    }
    if (props.title) {
      layer.set(titleKey, props.title);
    }
    if (props.type) {
      layer.set(typeKey, props.type);
    }

    if (
      parentLayerContext &&
      parentLayerContext.exists &&
      parentLayerContext.childLayers
    ) {
      parentLayerContext.childLayers.push(layer);
    } else if (mapContext.map) {
      // const mapLayers = mapContext.map.getLayers();
      // mapLayers.getArray().find(x => x instanceof OlLayerTile && x.get(idKey) === props.id);
      const mapLayer = findLayer(mapContext.map, props.id);

      if (mapLayer) {
        // context.updateMap({type: "removeLayer", layer: mapLayer});
        // console.log('remove layer', mapLayer);
        mapContext.map.removeLayer(mapLayer);
      }
      // context.updateMap({type: "addLayer", layer: layer});
      // console.log('add layer', layer);
      mapContext.map.addLayer(layer);
    } else {
      // @ts-ignore TODO: Cannot find what type openlayers wants for layers
      mapContext.initOptions.layers.push(layer);
    }

    const olEvents = getEvents(events, props);
    for (const eventName in olEvents) {
      // @ts-ignore TODO: Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange" | "change:extent" | "change:maxResolution" | "change:maxZoom" | "change:minResolution" | "change:minZoom" | "change:opa
      // city" | "change:visible" | ... 5 more ... | "postrender")[]'
      layer.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.id);
        if (mapLayer) {
          // console.log('unmounting TileLayer, removing mapLayer', props.id, mapLayer)
          mapContext.map.removeLayer(mapLayer);
        }
      }
    };
  },[]);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setExtent(props.extent);
    }
  }, [props.extent]);

  useEffect(() => {
    if (mapContext.map && props.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerImage) {
        mapLayer.setMap(props.map);
      }
    }
  }, [props.map]);

  useEffect(() => {
    if (mapContext.map && props.maxResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMaxResolution(props.maxResolution);
    }
  }, [props.maxResolution]);

  useEffect(() => {
    if (mapContext.map && props.maxZoom !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMaxZoom(props.maxZoom);
    }
  }, [props.maxZoom]);

  useEffect(() => {
    if (mapContext.map && props.minResolution !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMinResolution(props.minResolution);
    }
  }, [props.minResolution]);

  useEffect(() => {
    if (mapContext.map && props.minZoom !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setMinZoom(props.minZoom);
    }
  }, [props.minZoom]);

  useEffect(() => {
    if (mapContext.map && props.opacity !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setOpacity(props.opacity);
    }
  }, [props.opacity]);

  useEffect(() => {
    if (mapContext.map && props.properties !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setProperties(props.properties, true);
    }
  }, [props.properties]);

  useEffect(() => {
    if (mapContext.map && props.source !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerImage) {
        mapLayer.setSource(props.source);
      }
    }
  }, [props.source]);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setVisible(!!props.visible);
    }
  }, [props.visible]);

  useEffect(() => {
    if (mapContext.map && props.visible !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setVisible(props.visible);
    }
  }, [props.visible]);

  useEffect(() => {
    if (mapContext.map && props.zIndex !== undefined) {
      const mapLayer = findLayer(mapContext.map, props.id);
      mapLayer?.setZIndex(props.zIndex);
    }
  }, [props.zIndex]);

  useEffect(() => {
    if (mapContext.map) {
      const mapLayer = findLayer(mapContext.map, props.id);
      if (mapLayer && mapLayer instanceof OlLayerImage) {
        const src = (mapLayer as OlLayerImage<OlSourceImageWMS>).getSource();
        if (src) {
          src.updateParams({
            ...src.getParams(),
            LAYERS: wmsLayers,
          });
        }
      }
    }
  }, [wmsLayers]);

  return null;
};

export default ImageLayer;
