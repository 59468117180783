import React, {useCallback, useState, useEffect, useMemo} from 'react'
import { useTranslation } from 'react-i18next';

// Dropzone
import Dropzone, { FileRejection } from 'react-dropzone'

// MUI
import { Box, Typography,Badge, useTheme, FormControl, FormHelperText, FormLabel, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Lightbox
import { Lightbox, SlideImage} from "yet-another-react-lightbox";
import { Zoom, Download } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";

// FA
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faImage, faFileWord, faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';

// Custom 
import { verifyAndArrayFileValue, generateRejectedFileValidation, transformFilesToOurFormat, rejectAcceptedFiles, downloadFile, FileControlTypeMap } from '@/lib/fileControlUtility';
import useApi from '@/lib/api/useApi';
import { IFileControlProps } from '@/@types/controls/controls';
import { DCFile } from '@/@types/lib/dataController';



function FileControl(props: IFileControlProps) {

  const { t } = useTranslation();
  const theme = useTheme();
  const { download } = useApi();

  const [fileValidation, setFileValidation] = useState<string | null>(null);
  const [activeImage, setActiveImage] = useState<DCFile | null>(null);
  const [wrapperHover, setWrapperHover] = useState(false);

  const { accept, field, controlMode, value, validation, onChange, multiple
  } = props;

  const getLabel = (): string => {
    if (field) {
      return field.ttoken ? t(field.ttoken) : field.title;
    } else {
      return label;
    }
  };

  const valueArray = useMemo(() => verifyAndArrayFileValue(value), [value]);

  const isReadOnly = useMemo(() => controlMode === "view" || (field && field.readonly), [controlMode, field]);
  const isRequired = useMemo(() => !!(field.validation && field.validation.required), [field]);

  const hasError = useMemo(() => !!(validation && validation.valid === false), [validation]);
  const hasValue = useMemo(() => valueArray.length > 0, [valueArray]);
  const hasMaxFiles = useMemo(() => field.hasOwnProperty('maxFiles'), [field]);
  const maxFiles = useMemo(() => (hasMaxFiles ? field.maxFiles ?? 3 : 3), [hasMaxFiles, field]);
  const maxFilesCalculated = useMemo(() => maxFiles - valueArray.length, [valueArray.length, maxFiles]);
  const isDisabled = useMemo(()=> valueArray.length === maxFiles, [valueArray,maxFiles])  
  const label = useMemo(() => getLabel(), [field]);
  const slides = useMemo(() => [activeImage ? {src: `data:image/jpeg;base64,${activeImage.content}`, downloadFilename: activeImage.name} : undefined] as SlideImage[], [activeImage]);

  const onDrop = useCallback(<T extends File>(acceptedFiles:T[], fileRejections:FileRejection[]) => {

    if (acceptedFiles.length > maxFilesCalculated) { // We need to check this manually because dropzone maxFiles prop doesnt take into account current file state
      setFileValidation(rejectAcceptedFiles(acceptedFiles,t));
      return;
    }

    if (fileRejections.length > 0) {
      setFileValidation(generateRejectedFileValidation(fileRejections, t));
    }

    transformFilesToOurFormat(acceptedFiles).then((formatedFiles)=>{
      if (onChange) {
        onChange(valueArray.concat(formatedFiles), field.source)
      }
    })
  }, [valueArray,field,onchange])

  const handleRemoveFile = (file: DCFile) => {

    if (onChange){
      const newValue = valueArray.filter((val: DCFile)=>{
        return !((val.uuid && file.uuid && (file.uuid === val.uuid)) || (file.id && val.id && (file.id === val.id) ))
      })
      onChange(newValue, field.source)
    }
  }

  const removeAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (onChange) {
      onChange([],field.source)
    }
  }

  const viewOrDownloadFile = (file: DCFile) => {
    if (file.type.includes('image')) {
      setActiveImage(file);
    } else {
      downloadFile(file);
    }
  }

  const handleOpenFile = (file: DCFile) => {
    if (file.uuid) {
      if (!field.subModel || !field.subModel.apiPath) {
        console.warn("ApiPath is missing in model");
      } else {
        download(field.subModel.apiPath, file.uuid).then(resp => {
          resp.json().then((file: DCFile) => {
            viewOrDownloadFile(file);
          });
        });
      }
    } else if (file.content) {
      viewOrDownloadFile(file);
    }
  }
  

  const generateFileIcon = (val: DCFile, i: number) => {
    const type = val && val.type ? val.type : '';
    const name = val && val.name ? (val.name.length > 13 ? val.name.substring(0, 14) + '...' : val.name) : '';
    const icon =
      type.indexOf('pdf') >= 0 ? faFilePdf :
        type.indexOf('image') >= 0 ? faImage :
          type.indexOf('docx') >= 0 ? faFileWord : faFile;
    return (
      <Tooltip key={'file-tooltip-' + i} title={val.name}>
        <Box
        key={'div' + i} 
        sx={styles.filePreviewBox}
        onClick={(event) => {event.stopPropagation(); handleOpenFile(val)}}
        >
          <FontAwesomeIcon
            icon={icon}
            color="grey"
            size="2x"
            key={'icon' + i}
            style={{ margin: "auto"}}
          />
          {!isReadOnly ?
            <Badge
              key={'badge' + i}
              badgeContent={<CloseIcon fontSize="inherit" />}
              color="secondary"
              onClick={(event) => {event.stopPropagation(); handleRemoveFile(val)}}
              sx={styles.removeButton}
              anchorOrigin={{
                horizontal: 'left', 
                vertical: 'top', 
              }}
            /> : null}
          <Typography key={'label' + i} style={{ display: "block", fontSize: "15px" }}>{name}</Typography>
        </Box>
      </Tooltip>
    );
  }

  const styles = {
    infoBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    filePreviewBox: { 
      margin: 1, 
      textAlign: "center", 
      width: "fit-content", 
      cursor: "pointer" 
    },
    filePreviewWrapper: {
      display:"flex"
    },
    browseText: {
      textDecoration: "underline " + theme.palette.secondary.main
    },
    removeButton: { 
      cursor: "pointer" 
    },
  }
  return (
    <FormControl margin="none" fullWidth required={isRequired} error={hasError}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <FormLabel>
          {`${label} (Max ${maxFiles} ${maxFiles === 1 ?
            t('titles.files_sg')
            : maxFiles % 10 >= 2 && maxFiles % 10 < 5 ?
              t('titles.files_pl2') : t('titles.files_pl1')})`}
        </FormLabel>
        <Typography variant='body2' textAlign={"right"}>Max 5MB</Typography>
      </Box>
      <Dropzone
      onDrop={onDrop}
      maxFiles={maxFiles}
      maxSize={5*1024*1024} // 5MB
      disabled={isReadOnly || isDisabled}
      multiple={multiple}
      accept={accept ? FileControlTypeMap[accept] :  {}}
      >
        {({getRootProps, getInputProps, isDragActive}) => (
          <Box 
          {...getRootProps()}
          style={{ 
            position: "relative",
            borderWidth: "2px",
            borderColor: ((isDragActive || wrapperHover) && !isDisabled) ? theme.palette.secondary.main : "#d9d9d9",
            borderStyle: "solid",
            borderRadius: "4px",
            minHeight: "85px",
            padding: "10px",
            transition: "all 0.3s",
            cursor: "pointer"
          }} 
          onMouseOver={() => setWrapperHover(true)}
          onMouseOut={() => setWrapperHover(false)}
          >
            
            <input {...getInputProps()} />
            <p style={{ position: "absolute", top: 4, right: 4, margin: 0}}>
              {`${valueArray.length}/${maxFiles}`}
            </p>
            {Array.isArray(value) ?
              value.length > 0 ? 
            <Tooltip title={t("buttons.delete_all")}>
              <IconButton size='small' onClick={removeAll} style={{position: "absolute", bottom: 0, right: 0}}>
                <DeleteForeverIcon/>
              </IconButton>
            </Tooltip> : null 
            : null
            }
            {Array.isArray(value) ?
              value.length === 0 ? 
                <Box sx={styles.infoBox}>
                  <FontAwesomeIcon
                    icon={faFileUpload}
                    size='3x'
                    color={theme.palette.secondary.main}
                  />
                  <Typography>{t("messages.dropzone.drag")}</Typography>
                  <Box display={"flex"} flexDirection={"row"} style={{gap: 4}}>
                    <Typography>{t("messages.dropzone.or")+" "}</Typography> <Typography style={styles.browseText}>{t("messages.dropzone.browse")}</Typography>
                  </Box>
                </Box> : 
                <Box style={styles.filePreviewWrapper}>
                  {valueArray.map((val, i) => generateFileIcon(val, i))}
                </Box>
            : null}

          </Box>
        )}
      </Dropzone>
      <FormHelperText id={field.source + "-helper"} error={hasError || fileValidation ? true : false}>
        {hasError ?  validation && validation.msg : ''}
        {fileValidation ? fileValidation : ''}
      </FormHelperText>
      <Lightbox
        open={activeImage !== null}
        close={() => setActiveImage(null)}
        slides={slides}
        plugins={[Zoom, Download]}
      />
    </FormControl>
  )
}

export default FileControl;