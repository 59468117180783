import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import MapLegendForm from './MapLegendForm';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';

import { useParams } from 'react-router-dom';
import dataController from '@/lib/DataController';
import MapLegendModel from '@/models/map_legend';

function MapLegendPage() {
  const { id } = useParams();
  const dc = new dataController(MapLegendModel);

  const recordId = parseInt(id ? id : '', 10);

  return (
    <AppPage
      titlett="admin:page.map_legend"
      needPermission={[Permissions.Administration]}
    >
      <Box m={2}>
        <MapLegendForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AppPage>
  );
}

export default MapLegendPage;
