import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Typography, Box, Grid } from '@mui/material';

// Types
import { ModuleCardType } from '@/@types/common';

// Custom
import ModuleCard from '@/ui/ModuleCard/ModuleCard';
import UserContext from '@/context/UserContext/UserContext';

type THomePageModulesBox = {
  modules: ModuleCardType[];
};

const HomePageModulesBox = (props: THomePageModulesBox) => {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const { modules } = props;

  return (
    <Box mt={5}>
      <Typography mb={1} variant="h5">
        {t('common.modules')}
      </Typography>
      <Grid container columnSpacing={5} rowSpacing={3}>
        {modules
          .filter((r) => userContext?.hasAnyPermission(r.needPermission))
          .map((m, i) => (
            <Grid key={'module-card-' + i} item xs={12} md={6} xl={4}>
              <ModuleCard module={m} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default HomePageModulesBox;
