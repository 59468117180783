import * as React from 'react';
import { useParams } from 'react-router-dom';

// MUI Components
import Box from '@mui/material/Box';

// Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import MapLayerForm from './MapLayerForm';
import Permissions from '@/lib/permissions';
import dataController from '@/lib/DataController';

// Models
import MapLayerModel from '@/models/map_layer';

function MapLayerPage() {
  const { id } = useParams();
  const dc = new dataController(MapLayerModel);

  const recordId = parseInt(id ? id : '', 10);

  return (
    <AppPage
      titlett="admin:page.map_layers"
      needPermission={[Permissions.Administration]}
    >
      <Box m={2}>
        <MapLayerForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AppPage>
  );
}

export default MapLayerPage;
