import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';

// Custom Components
import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import useFormController from '@/components/useFormController';
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DataController from '@/lib/DataController';
import modelComments from '@/models/comments';

const CommentDialog = (props: IDialogProps) => {
  const { mode, dataId, onClose, form, initialRecord } = props;

  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const dc = new DataController(modelComments);

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  const {
    record,
    validation,
    fields,
    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose: close,
    initialRecord,
  });

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = () => {
    close({ success: false });
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: 'insert' });
          } else if (result.error) {
            const msg = t('error.addRecord') + result.error;
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = t('error.editRecord') + result.error;
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleDelete = () => {
    doDelete().then((result) => {
      if (result.success) {
        snackbarContext.showNotification('delete', 'success');
        close({ dataChanged: true, action: 'delete' });
      } else if (result.error) {
        const msg = t('error.delete') + result.error;
        snackbarContext.showNotification(msg, 'error');
      }
    })
    .catch((resp) => {
      if(!resp.success && !resp.canceled) {
        snackbarContext.showNotification(resp.error, 'error');
      }
    });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          {mode === 'update' ? (
            <EditIcon sx={{ transform: 'scale(1.3)' }} />
          ) : mode === 'insert' ? (
            <AddCommentIcon sx={{ transform: 'scale(1.3)' }} />
          ) : null}
          <DialogToolbarHeading>
            {mode === 'insert'
              ? t('titles.add_comment')
              : mode === 'update'
              ? t('titles.edit_comment')
              : null}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              // @ts-ignore
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions
        style={{
          display: 'inline-block',
        }}
      >
        <Box mx={4} mb={1} display="flex" justifyContent="space-between">
          <Box>
            <Button
              variant="contained"
              onClick={
                mode === 'insert'
                  ? handleInsert
                  : mode === 'update'
                  ? handleUpdate
                  : undefined
              }
              startIcon={<Check />}
              size="medium"
              color="primary"
            >
              {t('buttons.confirm')}
            </Button>
            {mode === 'update' ? (
              <Button
                sx={{ marginX: 2 }}
                variant="contained"
                onClick={handleDelete}
                startIcon={<DeleteForever />}
                size="medium"
                color="primary"
              >
                {t('buttons.delete')}
              </Button>
            ) : null}
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={handleClose}
              startIcon={<Cancel />}
              size="medium"
              color="primary"
            >
              {t('buttons.cancel')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </DraggableDialog>
  );
};

export default CommentDialog;
