import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import EnaSettingForm from './EnaSettingForm';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';

import { useParams } from 'react-router-dom';
import dataController from '@/lib/DataController';
import EnaSettingsModel from '@/models/ena_settings';

function EnaSettingPage() {
  const { id } = useParams();
  const dc = new dataController(EnaSettingsModel);

  const recordId = parseInt(id ? id : '', 10);

  return (
    <AppPage
      titlett="admin:page.ena_settings"
      needPermission={[Permissions.Administration]}
    >
      <Box m={2}>
        <EnaSettingForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AppPage>
  );
}

export default EnaSettingPage;
