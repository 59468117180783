import * as React from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import CancelIcon from "@mui/icons-material/Cancel";

//Custom components
import CardToolbarMenuItem from "@/ui/Card/CardToolbarMenuItem";

//Types
import { ICardToolbarMenuItemDetail } from "@/@types/ui/Card";

const CardToolbarMenuItemDeactivate = React.forwardRef<HTMLLIElement, ICardToolbarMenuItemDetail>((props, ref) => {
  const { t } = useTranslation();
  const { onClick } = props;

  return <CardToolbarMenuItem label={t("buttons.deactivate")} icon={<CancelIcon />} onClick={onClick} ref={ref} />;
});

export default CardToolbarMenuItemDeactivate;
