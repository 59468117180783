import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

// OpenLayers
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';
import { get as OlGetProjection, Projection } from 'ol/proj';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import OlCollection from 'ol/Collection';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFormatWKT from 'ol/format/WKT';
import OlFeature from 'ol/Feature';

// MUI
import { Box } from '@mui/material';

// Custom
import {
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
  ZoomToExtentControl,
} from '@/components/Map/Controls';
import {
  Layers,
  VectorLayer,
} from '@/components/Map/Layers';
import Map from '@/components/Map/Map';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import { commentsStyle, selectedStyle } from '@/components/Map/mapStyles';
import MapButton from '@/components/Map/Controls/Custom/MapButton';

// Context
import LoaderContext, {
  LoaderContextType,
} from '@/context/LoaderContext/LoaderContext';
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';

// Lib
import { padExtent, flattenLayers } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';


// Types
import { IModel } from '@/@types/models/model';
import { DCRecord } from '@/@types/lib/dataController';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';

type PhotoCentricMiniMapProps = {
  selectedRecordId: number;
  onRecordSelect: Function;
  model: IModel;

  // if true geom is fetched from endpoint on MW
  // if false geom is fetched from records passed in props
  fetchGeomFromMW?: boolean;

  // fetching geom from MW props
  baseRecordPath?: string; // used if we want to override model apiUrl
  geomRelativePath?: string;

  // fetching geom from records
  records?: DCRecord[];

  mapId: number;
};

const PhotoCentricMiniMap = (props: PhotoCentricMiniMapProps) => {
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const { t } = useTranslation();

  const [mapInitialized, setMapInitialized] = useState(false);

  const {
    selectedRecordId,
    onRecordSelect,
    model,
    fetchGeomFromMW,
    baseRecordPath,
    geomRelativePath,
    records,
    mapId,
  } = props;

  const [recordExtent, setRecordExtent] = useState<number[]>(tenantContext?.mapSettings?.default_extent);
  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [showDOFLayer, setShowDOFLayer] = useState(true);

  const [hiddenLayerIDs, setHiddenLayerIDs] = useState<string[]>([]);

  const defaultExtent = useMemo(() => padExtent(tenantContext?.mapSettings?.default_extent),[tenantContext])
  const viewOptions = {
    center: tenantContext?.mapSettings
      ? tenantContext?.mapSettings?.initial_view_center
      : ([1724286, 5418981] as [number, number]),
    extent: tenantContext?.mapSettings
      ? tenantContext.mapSettings.max_extent
      : [1437016, 5271097, 2206278, 5860579],
    zoom: tenantContext?.mapSettings
      ? tenantContext.mapSettings.initial_view_zoom
      : 8,
    minZoom: 8,
    maxZoom: 21,
  };

  let highlightedFeature: OlFeature<Geometry> | null = null;

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection('EPSG:3765');
  const wgs84 = OlGetProjection('EPSG:4326');
  const wgs84PM = OlGetProjection('EPSG:3857');

  const dc = new DataController(model);
  const wkt = new OlFormatWKT();

  useEffect(() => {
    getLayers();
  }, []);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll: any) => {
      setLayersCollection(coll);
      setMapInitialized(true);
      //ako ne fetcha ni jedan layer
      if (!coll[0]) setShowDOFLayer(false);
      //togglaj kartu s obzirom na novi layer functionality
      else {
        setShowDOFLayer(false);
        coll.forEach((layer: any) => {
            if (coll.layer_functionality_id == EnumLayerFunctionality["toggle_dof"]) setShowDOFLayer(true);
        })
      }
    });
  };

  useEffect(() => {
    if (selectedRecordId === 0) {
      setSelectedSource(new OlSourceVector({}));
    }
    // if we want to fetch from records directly
    if (!fetchGeomFromMW && records && records.length > 0 && recordsSource) {
      const features = recordsSource
        ?.getFeatures()
        .filter((x) => x.getId() === selectedRecordId);
      const srcVector = new OlSourceVector({ features });
      setSelectedSource(srcVector);
      const rec = records.find((x) => x.id === selectedRecordId);

      if (features && features?.length > 0) {
        setRecordExtent(srcVector.getExtent() as number[]);
      } else {
        setRecordExtent(defaultExtent);
      }
    } else if (fetchGeomFromMW && selectedRecordId > 0) {
      // if we want to fetch from MW
      const url = `${
        baseRecordPath || model.apiPath
      }/${selectedRecordId}/${geomRelativePath}` as string;
      dc.GetData(url).then((resp) => {
        if (resp.success) {
          const dcRec = resp.data as DCRecord;

          let feature;
          if (dcRec?.wkt) {
            const wktFormatter = new OlFormatWKT();
            feature = wktFormatter.readFeature(wkt, {
              dataProjection: (dcRec.proj as string) || 'EPSG:3765',
              featureProjection: 'EPSG:3857',
            });
          } else if (dcRec.geom) {
            feature = dcRec.geom as OlFeature;
          }

          if (feature) {
            setSelectedSource(new OlSourceVector({ features: [feature] }));
            const geom = feature.getGeometry();
            if (geom) {
              setRecordExtent(geom.getExtent());
            }
          }
        } else {
          setRecordExtent(defaultExtent);
        }
      });
    }
  }, [selectedRecordId]);

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      (feature) => feature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('primary-records')
          );
        },
      }
    );

    if (feature) {
      const id = feature.getId();
      onRecordSelect(id);
    } else {
      const allLayers = layersCollection
        ? flattenLayers(layersCollection.getArray()).filter(
            (x) => !(x instanceof OlLayerGroup)
          )
        : [];

      const GSLayerNames = allLayers
        .filter((x) => x.get('query') === true)
        .map((x) => x.get('layer'));

      if (GSLayerNames.length > 0) {
        gsService
          .getFeatureInfo(evt.map, evt.pixel, GSLayerNames)
          .then((resp) => {
            if (resp && Object.keys(resp).length != 0) {
              const layerKeys = Object.keys(resp);
              const key = layerKeys.length > 0 ? layerKeys[0] : null;
              const features = key ? resp[key] : [];

              if (Array.isArray(features) && features.length > 0) {
                const feat = features[0];
                const { id } = feat.properties;
                onRecordSelect(id);
              } else {
                onRecordSelect(0);
              }
            } else {
              onRecordSelect(0);
            }
          });
      } else {
        onRecordSelect(0);
      }
    }
  };

  const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    const pixel = evt.map.getEventPixel(evt.originalEvent);
    const feature = evt.map.forEachFeatureAtPixel(
      pixel,
      (feature: any) => feature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('objekti')
          );
        },
      }
    ) as unknown as OlFeature<Geometry>;
    highlightFeature(feature);
  };

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => !prevState);
  };

  useEffect(() => {
    var hiddenLayerIDsNew: string[] = [];
    if (showDOFLayer) hiddenLayerIDsNew.push('dof');

    setHiddenLayerIDs(hiddenLayerIDsNew);
  }, [showDOFLayer]);

  const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  };

  const recordsSource = useMemo(() => {
    if (!records) return;

    const features = records
      .map((x) => {
        if (x.wkt) {
          const wktFormatter = new OlFormatWKT();
          const feat = wktFormatter.readFeature(x.wkt, {
            dataProjection: 'EPSG:3765',
            featureProjection: 'EPSG:3857',
          });
          feat.setId(x.id as number);
          return feat;
        } else if (x.geom) {
          return x.geom;
        }
        return undefined;
      })
      .filter((x) => x !== undefined);

    const newSourceVector = new OlSourceVector({
      features: features as OlFeature<Geometry>[],
    });
    return newSourceVector;
  }, [records]);

  return layersCollection ? (
    <Map
      height="500px"
      view={viewOptions}
      onClick={handleClick}
      className="map"
      id="mini-map"
      zoomToExtent={recordExtent.length ? recordExtent : undefined}
      zoomToExtentPadding={[20, 20, -20, -20]}
      initialized={mapInitialized}
    >
      <Controls>
        <ZoomControl
          zoomInTipLabel={`${t('map:controls.zoom_in')}`}
          zoomOutTipLabel={`${t('map:controls.zoom_out')}`}
        />
        <RotateControl autoHide={false} />
        <ScaleLineControl type="minimap" />
        <GeoBaseLayerSwitcher type="minimap"/>
        <ZoomToExtentControl
          id="zoom-extent-default"
          extent={defaultExtent}
          tipLabel={`${t('map:controls.zoom_to_extent')}`}
          className="ol-control ol-zoom-extent"
        />
        <ZoomToExtentControl
          id="zoom-extent-selected"
          extent={recordExtent}
          tipLabel={`${t('map:controls.zoom_to_selected')}`}
          className="ol-control ol-zoom-selected"
        />
        <Box sx={{
          position: "relative"
        }}>
          {showDOFLayer && <MapButton
            id="layerswitcher-dof"
            className={'ol-layerswitcher-dof'}
            handleClick={handleLayerSwitcherDOFClick}
            title={t('map:layerswitcher.toggle_dof') as string}
            active={showDOFLayer}
          >
            <i className="fas fa-camera"></i>
          </MapButton>}
        </Box>
      </Controls>
      <Layers>
        <GeoAPILayers
          layersCollection={layersCollection}
          hiddenIDs={hiddenLayerIDs}
        />
        <VectorLayer
          id="primary-records"
          source={recordsSource}
          style={commentsStyle}
          zIndex={900}
        />
        <VectorLayer
          id="_selected"
          source={selectedSource}
          style={selectedStyle}
          zIndex={950}
        />
        {/* <VectorLayer id="highlighted" source={highlightedSource} style={hoveringRecordStyle} zIndex={960} /> */}
      </Layers>
    </Map>
  ) : null;
};

export default PhotoCentricMiniMap;
