import React, { useEffect, useState, useContext, useRef } from 'react';

// MUI
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Skeleton,
} from '@mui/material';

// MUI Icons
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

// Custom Components

import modelImage from '@/models/images';
import DataController from '@/lib/DataController';
import UserContext from '@/context/UserContext/UserContext';
import LoaderContext, {
  LoaderContextType,
} from '@/context/LoaderContext/LoaderContext';
import DialogContext, {
  DialogContextType,
} from '@/context/DialogContext/DialogContext';
import ImageDialog from './PhotoCentricImageDialog';
import imgService from '@/services/imgService';
import PhotoCentricThumbsFilter from '@/ui/PhotoCentric/PhotoCentricThumbsFilter';

// Types
import { DCRecord } from '@/@types/lib/dataController';

const REDDOT_RADIUS = 5;

export type ThumbFilters = {
  textFilter: string;
  selectedFilter: boolean;
};

type PhotoCentricThumbsProps = {
  baseRecordPath: string;
  recordId: number | null;
};

type IThumbsDictionary = {
  [key: number]: string;
};

const PERCENTAGE = 5;

const initialFilterObject: ThumbFilters = {
  textFilter: '',
  selectedFilter: false,
};

const PhotoCentricThumbs = (props: PhotoCentricThumbsProps) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const [filteredRecords, setFilteredRecords] = useState<DCRecord[]>([]);
  const [filterObject, setFilterObject] =
    useState<ThumbFilters>(initialFilterObject);
  const [records, setRecords] = useState<DCRecord[]>([]);
  const [thumbs, setThumbs] = useState<IThumbsDictionary>({});
  
  const imageListRef = useRef<HTMLUListElement>(null);

  const { baseRecordPath, recordId } = props;

  const dc = new DataController(modelImage);

  useEffect(() => {
    if (recordId) {
      loadThumbnails();
    }
  }, [recordId]);

  const loadThumbnails = () => {
    loaderContext.toggleLoading(true);
    const endsWithSlash =
      baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1;
    dc.GetData(`${baseRecordPath}${endsWithSlash ? '' : '/'}${recordId}/images`)
      .then((resp) => {
        if (resp.success) {
          const records = resp.data as DCRecord[];

          if (!Array.isArray(records)) return;

          const filteredData = records.filter((r) => {
            const { width, height, objpx, objpy, objpoly } = r as {
              width: number;
              height: number;
              objpx: number;
              objpy: number;
              objpoly: number;
            };
            if (!objpx || !objpy || !width || !height) {
              return false;
            }

            if (r.selected) {
              return true;
            }

            const lower = PERCENTAGE / 100;
            const upper = (100 - PERCENTAGE) / 100;
            if (objpx < width * lower || objpx > width * upper) {
              return false;
            }
            if (objpy < height * lower || objpy > height * upper) {
              return false;
            }

            return true;
          });

          const hasAtLeastOneRedPoint =
            records.find((x) => x.objpx !== null && x.objpy !== null) !==
            undefined;
          if (hasAtLeastOneRedPoint) {
            setRecords(filteredData as DCRecord[]);
          } else {
            setRecords(records);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  useEffect(() => {
    if (Array.isArray(records)) {
      records.forEach((x) => {
        if (recordId && x.id && typeof x.id === 'number') {
          imgService
            .getRecordImageThumb(baseRecordPath, recordId, x.id)
            .then((base64) => {
              setThumbs((prevState) => ({
                ...prevState,
                [x.id as number]: base64,
              }));
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    }
  }, [records]);

  useEffect(() => {
    if (records.length) {
      filterThumbnails();
    } else {
      setFilteredRecords((prev) => (prev.length ? [] : prev));
    }
  }, [filterObject, records]);

  function filterThumbnails() {
    const newFilteredRecords = records.filter((record: any) => {
      let textOk = true;
      let selectedOk = true;

      if (filterObject.textFilter.length > 0) {
        if (
          !record.id.toString().toLowerCase().includes(filterObject.textFilter)
        ) {
          textOk = false;
        }
      }

      if (filterObject.selectedFilter === true && record.selected === false) {
        selectedOk = false;
      }

      return textOk && selectedOk;
    });
    setFilteredRecords(newFilteredRecords);
  }

  const thumbHeight = imageListRef.current?.clientHeight ? imageListRef.current?.clientHeight : 0;
  const thumbWidth = thumbHeight * (12 / 9);

  const handleThumbnailClick = (rec: DCRecord) => {
    dialogContext.showDialog(ImageDialog, {
      record: rec,
      baseRecordPath,
      baseRecordId: recordId,
      onClose: () => {}
    });
  };

  const handleMarkFavorite = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
  };

  const retrieveFile = (imageId: number) => {
    if (recordId && imageId) {
      return imgService.getRecordImageThumb(baseRecordPath, recordId, imageId);
    }
    return Promise.reject(null);
  };

  const buildDataUrl = (imageId: number) => {
    if (thumbs[imageId] !== undefined) {
      return `data:image/jpg;base64,${thumbs[imageId]}`;
    }
    return '';
  };

  return (
    <Paper elevation={0} sx={{ ml: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        m={0}
        p={0}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <PhotoCentricThumbsFilter
          filterObject={filterObject}
          onFilterChange={setFilterObject}
          recordsLength={records.length}
          filteredRecrodsLength={filteredRecords.length}
        />
      </Box>
      <ImageList
        ref={imageListRef}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          display: 'block',
          whiteSpace: 'nowrap',
          width: '100%',
          height: '100%',
          marginBlock: 0,
        }}
      >
        {filteredRecords.map((x) => {
          if (x.thumb_path !== undefined) {

            const px =
              x.objpx && x.width
                ? Math.round(
                    (x.objpx as number) * (thumbWidth / (x.width as number)) -
                      REDDOT_RADIUS / 2
                  )
                : null;
            const py =
              x.objpy && x.height
                ? Math.round(
                    (x.objpy as number) *
                      (thumbHeight / (x.height as number)) -
                      REDDOT_RADIUS / 2
                  )
                : null;

            return (
              <ImageListItem
                key={x.id as string}
                onClick={() => handleThumbnailClick(x)}
                sx={{
                  width: thumbWidth,
                  height: thumbHeight,
                  display: 'inline-block',
                  mr: 1,
                }}
              >
                {thumbs[x.id as number] === undefined ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={thumbWidth}
                    height={thumbHeight}
                  />
                ) : (
                  <img
                    id={x.id as string}
                    src={buildDataUrl(x.id as number)}
                    alt={x.serial as string}
                    width={thumbWidth}
                    height={thumbHeight}
                    style={{ objectFit: 'cover' }}
                  />
                )}
                {thumbs[x.id as number] !== undefined && px && py ? (
                  <Box
                    m={0}
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      width: 0,
                      height: 0,
                      border: `${REDDOT_RADIUS}px solid red`,
                      borderRadius: `${REDDOT_RADIUS}px`,
                      left: `${px}px`,
                      top: `${py}px`,
                    }}
                  />
                ) : null}

                <ImageListItemBar
                  title={
                    x.path &&
                    String(x.path) &&
                    String(x.path).substring(
                      String(x.path).lastIndexOf('/') + 1
                    )
                      ? String(x.path).substring(
                          String(x.path).lastIndexOf('/') + 1
                        )
                      : null
                  }
                  sx={{
                    '.MuiImageListItemBar-title': {
                      fontSize: '9px',
                    },
                  }}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`star ${x.path}`}
                      onClick={handleMarkFavorite}
                    >
                      {x.selected ? (
                        <StarIcon id={x.id as string} />
                      ) : (
                        <StarBorderIcon id={x.id as string} />
                      )}
                    </IconButton>
                  }
                  actionPosition="right"
                />
              </ImageListItem>
            );
          }
          return null;
        })}
      </ImageList>
    </Paper>
  );
};

export default PhotoCentricThumbs;
