import React, { useContext, useEffect, useState, useCallback } from 'react';

// MUI
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';

// Custom
import MapContext from '@/context/MapContext/MapContext';
import ButtonControl from '@/components/Map/Controls/Custom/Button/ButtonControl';
import ButtonGroupControl from '@/components/Map/Controls/Custom/ButtonGroup/ButtonGroupControl';
import UserContext from "@/context/UserContext/UserContext";

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IMapView } from '@/@types/common';
import MapEvent from 'ol/MapEvent';
import { EventsKey } from 'ol/events';
import { useTranslation } from 'react-i18next';
import { ViewHistoryState } from '@/@types/components/Map/Controls/Custom';

const MAX_VIEWS = 50;

function ViewHistoryControl() {
  const userContext = useContext(UserContext);
  const [ready, setReady] = useState(false);
  const [eventKey, setEventKey] = useState<EventsKey | null>(null);

  const [viewsState, setViewsState] = useState<ViewHistoryState>({
    views: [],
    currentViewIndex: 0,
    viewSwitchClicked: false,
    navigateTo: undefined,
  });

  const mapContext = useContext<MapContextType | null>(MapContext);
  const { t } = useTranslation();

  const handlePrevious = useCallback(() => {
    setViewsState((prevState) => {
      if (prevState.currentViewIndex === 0) {
        return prevState;
      } else {
        return {
          ...prevState,
          currentViewIndex: prevState.currentViewIndex - 1,
          viewSwitchClicked: true,
          navigateTo: prevState.views[prevState.currentViewIndex - 1],
        };
      }
    });
  }, []);

  const handleNext = useCallback(() => {
    setViewsState((prevState) => {
      if (prevState.currentViewIndex >= prevState.views.length - 1) {
        return prevState;
      } else {
        return {
          ...prevState,
          currentViewIndex: prevState.currentViewIndex + 1,
          viewSwitchClicked: true,
          navigateTo: prevState.views[prevState.currentViewIndex + 1],
        };
      }
    });
  }, []);

  const navigateToView = (view: IMapView) => {
    if (mapContext?.map) {
      const v = mapContext.map.getView();
      v.animate({
        center: view.center,
        zoom: view.zoom,
        duration: 500,
      });
    }
  };

  const handleMoveEnd = useCallback(
    (evt: MapEvent) => {
      if (evt?.map) {
        const view = evt.map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
        if (center && zoom) {
          const newView: IMapView = {
            center: center as [number, number],
            zoom: zoom,
          };
          setViewsState((prevState) => {
            // Handle view switch click case
            if (prevState.viewSwitchClicked) {
              return {
                ...prevState,
                viewSwitchClicked: false,
              };
            }

            // If we are adding a new view after a few steps back, we need to truncate the views array
            const updatedViews = prevState.views.slice(0, prevState.currentViewIndex + 1).concat(newView);
            let updatedCurrentViewIndex = prevState.currentViewIndex + 1;

            // Ensure views array length is max 50 by removing the first element if necessary
            if (updatedViews.length > MAX_VIEWS) {
              updatedViews.shift(); // Remove the first (oldest) view
              updatedCurrentViewIndex = MAX_VIEWS - 1; // Adjust index to reflect the new bounds
            }

            return {
              views: updatedViews,
              currentViewIndex: updatedCurrentViewIndex,
              viewSwitchClicked: false,
              navigateTo: undefined,
            };
          });
        }
      }
    },
    []
  );

    // Trim views if exceeding MAX_VIEWS on initial load from localStorage
    useEffect(() => {
      const viewsData = localStorage.getItem('viewsState_' + userContext?.username);
      if (viewsData !== null && viewsData !== undefined) {
        const parsedViewsState: ViewHistoryState = JSON.parse(viewsData);
  
        // Check if the stored views exceed the MAX_VIEWS limit
        if (parsedViewsState.views.length > MAX_VIEWS) {
          const trimmedViews = parsedViewsState.views.slice(-MAX_VIEWS); // Keep only the last 50 views
          const adjustedIndex = Math.min(parsedViewsState.currentViewIndex, MAX_VIEWS - 1);
  
          setViewsState({
            views: trimmedViews,
            currentViewIndex: adjustedIndex,
            viewSwitchClicked: false,
            navigateTo: undefined,
          });
        } else {
          setViewsState(parsedViewsState);
        }
      }
    }, [userContext?.username]);

  useEffect(() => {
    if (userContext?.username) {
      localStorage.setItem('viewsState_' + userContext?.username, JSON.stringify(viewsState));
    }
  }, [viewsState, userContext?.username]);

  useEffect(() => {
    if (!ready) {
      if (mapContext?.map !== null) {
        setReady(true);
      }
    }
  }, [mapContext?.map]);

  useEffect(() => {
    if (mapContext?.map) {
      const evKey = mapContext.map.on('moveend', handleMoveEnd);
      setEventKey(evKey);
    }

    return () => {
      if (mapContext?.map && eventKey) {
        mapContext.map.un('moveend', eventKey.listener);
      }
    };
  }, [mapContext?.map, eventKey, handleMoveEnd]);

  useEffect(() => {
    if (viewsState.navigateTo) {
      navigateToView(viewsState.navigateTo);
    }
  }, [viewsState.navigateTo]);

  const isFirstView = viewsState.currentViewIndex === 0;
  const isLastView = viewsState.currentViewIndex === viewsState.views.length - 1;

  return ready ? (
    <ButtonGroupControl id="bar-history">
      <ButtonControl id="history-prev" disabled={isFirstView} onClick={handlePrevious}>
        <HistoryIcon fontSize="small" />
      </ButtonControl>
      <ButtonControl id="history-next" disabled={isLastView} onClick={handleNext}>
        <UpdateIcon fontSize="small" />
      </ButtonControl>
    </ButtonGroupControl>
  ) : null;
}

export default ViewHistoryControl;
