import React, { useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";

//MUI
import { Typography, IconButton, Box, Tooltip } from '@mui/material';

//MUI Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';

//MUI LAB
import { TreeItemContentProps, useTreeItem } from '@mui/lab/TreeItem';

//Components
import MapContext from '@/context/MapContext/MapContext';
import TimeSeriesSlider from '@/ui/Slider/TimeSeriesSlider';
import OpacitySlider from '@/ui/Slider/OpacitySlider';
import { findLayer } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';

const LayerTreeItemCustomContent = React.forwardRef(
  function LayerTreeItemCustomContent(props: TreeItemContentProps, ref) {
    const mapContext = useContext(MapContext) as MapContextType;
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      is,
      id,
      placeholder,
    } = props;
    const checked = props['aria-checked'];
    // @ts-ignore
    const { layerExtent, zoomable, dataVisible, hasOpacitySlider } = props;

    const timeseries = placeholder ? placeholder.split(';') : undefined;
    const ticks = timeseries ? timeseries.length - 1 : 0;
    const hasTimeSeriesSlider = timeseries && timeseries.length > 0;
    const { t } = useTranslation();

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      preventSelection(event);
    };

    const handleExpansionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleSelection(event);
    };

    const handleChangeCommited = (
      event: React.SyntheticEvent | Event,
      value: number | Array<number>
    ) => {
      const time = handleValueLabel(value as number);
      if (id && time) {
        mapContext.setLayerTime(id, time);
      }
    };

    const handleZoomToLayer = () => {
      if (mapContext && mapContext.map && layerExtent) {
        const view = mapContext.map.getView();
        view.fit(layerExtent, { padding: [20, 20, 20, 220], duration: 1000 });
      }
    };

    const handleChangeOpacity = (
      event: Event,
      value: number | Array<number>,
      activeThumb: number
    ) => {
      if (mapContext && mapContext.map && typeof value === 'number') {
        const mapLayer = findLayer(mapContext.map, props.id);
        mapLayer?.setOpacity(value / 100);
      }
    };

    const handleValueLabel = (x: number) => {
      const timeseries = placeholder ? placeholder.split(';') : undefined;

      if (timeseries && timeseries.length > x) {
        return timeseries[x];
      } else {
        return '?';
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
        {hasTimeSeriesSlider ? (
          <TimeSeriesSlider
            ticks={ticks}
            checked={checked}
            handleValueLabel={handleValueLabel}
            handleChangeCommited={handleChangeCommited}
          />
        ) : null}
        <Box display="flex" flexDirection="row" maxWidth="7rem">
          {hasOpacitySlider ? (
            <OpacitySlider checked={checked} handleChange={handleChangeOpacity} />
          ) : null}
          {dataVisible ? (
            zoomable ?
              <IconButton
                onClick={handleZoomToLayer}
                color="primary"
                disabled={!layerExtent}
                edge="start"
              >
                <LocationOnIcon />
              </IconButton> 
            : 
              <></>
          ) : (
            <Tooltip title={t("tooltips.zoom_level_helper_text") as string}>
              <IconButton style={{paddingLeft: 0, marginLeft: 0, marginRight: 12}} size="small">
                <VisibilityOffTwoToneIcon sx={{color: "grey", paddingLeft: 0, marginLeft: 0}} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        {icon ? 
          <div onClick={handleExpansionClick} className={classes.iconContainer}>
            {icon}
          </div> 
        : 
          <></>}
      </div>
    );
  }
);

export default LayerTreeItemCustomContent;
