import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// d.ts
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataResponse, DCRecord } from '@/@types/lib/dataController';

// Material UI Core

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import DialogContext from '@/context/DialogContext/DialogContext';
import model from '@/models/test';
import DataController from '@/lib/DataController';
import TestDialog from './TestDialog';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import TableButtonDelete from '@/ui/Table/ActionButtons/TableButtonDelete';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import { ClosingDetails } from '@/@types/components/formController';
import { sampleData } from '../../../dummyData.js';

// Types
import { TestViewRedirectState } from '@/@types/views/TestPage/test';

const TestTable = () => {
  const dialogContext = useContext(DialogContext);
  const loaderContext = useContext(LoaderContext);
  const snackbarContext = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [records, setRecords] = useState<Array<DCRecord>>([]);

  const { t } = useTranslation();

  const dc = new DataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(TestDialog, {
      dc,
      mode: 'insert',
      form: 'default',
      onClose: handleDialogClose,
    });
  };

  const handleEdit = () => {
    snackbarContext.showNotification('Test success alert!', 'success');
  };

  const handleDelete = () => {
    snackbarContext.showNotification('Test error alert!', 'error');
  };

  const handleDialogClose = (result: ClosingDetails) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const refreshRecords = () => {
    loaderContext?.toggleLoading(true);
    // dc.GetData()
    //   .then((resp: DataResponse) => {
    //     if (resp.success && resp.data && Array.isArray(resp.data)) {
    //       setRecords(resp.data);
    //     }
    //   })
    //   .finally(() => {
    //     loaderContext?.toggleLoading(false);
    //   });
    setRecords(sampleData as DCRecord[]);
    loaderContext?.toggleLoading(false);
  };

  const handleRowAction = function (action: string, id: number) {
    if (action === 'edit') {
      navigate(`/test/${id}`, { state: { id } as TestViewRedirectState });
    } else if (action === 'related_animal') {
      navigate(`/test/${id}`, { state: { id } as TestViewRedirectState });
    }
  };

  const allowRowAction = function (action: string, id: number) {
    return true;
  };

  return (
    <Card>
      <CardContent>
        <ModelTable
          title={t('titles.test_table')}
          records={records}
          dc={dc}
          allowSelection="many"
          allowFilter
          allowExport
          allowAdd
          allowColumnPicker
          addLabel={`${t('buttons.new_m')}`}
          handleAdd={handleAdd}
          onRowAction={handleRowAction}
          allowRowAction={allowRowAction}
        >
          <TableButtonDelete variant="outlined" onClick={handleDelete} />
          <TableButtonEdit variant="contained" onClick={handleEdit} />
        </ModelTable>
      </CardContent>
    </Card>
  );
};

export default TestTable;
