import React from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelGrobnaMjesta from '@/models/grobna_mjesta';
import modelUkopani from '@/models/grobno_mjesto_ukopani';

// Types
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';

type GrobnaMjestaDataCentricPageParams = {};

const GrobnaMjestaDataCentricPage = (
  props: GrobnaMjestaDataCentricPageParams
) => (
    <AppPage
      titlett="groblja:page.grobna_mjesta"
      needPermission={[Permissions.Groblja]}
    >
      <DataCentricPage
        primaryModel={modelGrobnaMjesta}
        primaryTitleToken="titles.grobna_mjesta"
        primaryView='default'
        secondaryModel={modelUkopani}
        secondaryTitleToken="titles.ukopani"
        secondaryView='default'
        baseRecordPath={modelGrobnaMjesta.apiPath}
        childRecordRelativePath="ukopani"
        geomRelativePath=""
        mapId={41}
        mapModel={modelGrobnaMjesta}
      />
    </AppPage>
  );

export default GrobnaMjestaDataCentricPage;
