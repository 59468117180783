import * as React from 'react';

// MUI Components
import Box from '@mui/material/Box';

// Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import MapLayersTable from './MapLayersTable';
import Permissions from '@/lib/permissions';

function MapLayersPage() {
  return (
    <AppPage
      titlett="admin:page.map_layers"
      needPermission={[Permissions.Administration]}
    >
      <Box m={0} style={{ height: '100%' }}>
        <MapLayersTable />
      </Box>
    </AppPage>
  );
}

export default MapLayersPage;
