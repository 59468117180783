import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';

// Ui
import TableButtonView from '@/ui/Table/ActionButtons/TableButtonView';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import { DialogFormGenerateFunction } from '@/ui/DataCentric/DataCentricPage';
import TableButtonShowPhotos from '@/ui/Table/ActionButtons/TableButtonShowPhotos';

// Lib
import dataController, { IDataController } from '@/lib/DataController';

// Context 
import DialogContext, {
  DialogContextType,
  IDialogProps,
} from '@/context/DialogContext/DialogContext';

// Types
import { IModel } from '@/@types/models/model';

interface IDataCentricSecondaryTable {
  selectedPrimaryRecordId: number;
  onRecordSelect: (id: number) => void;
  onLoad?: (records: Array<DCRecord>) => void;
  view: string | undefined;
  onRowAction: (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => void;
  setAllowImages: () => void;
  imagesEnabled: boolean;

  model: IModel;
  titleToken: string;

  baseRecordPath: string;
  childRecordRelativePath: string;

  allowView?: boolean;
  allowEdit?: boolean;
  dialogComponent?: React.FunctionComponent<IDialogProps>;
  dialogForm?: string | DialogFormGenerateFunction;

  triggerRefresh: boolean;
  setTriggerRefresh: (state: boolean) => void;
}

const DataCentricSecondaryTable = (props: IDataCentricSecondaryTable) => {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  
  const { t } = useTranslation();
  const { onRecordSelect, onLoad, onRowAction, setAllowImages } = props;
  const {
    model,
    titleToken,
    baseRecordPath,
    childRecordRelativePath,
    imagesEnabled,
    view,
    selectedPrimaryRecordId,
    triggerRefresh,
    setTriggerRefresh,
  } = props;
  const { allowView, allowEdit, dialogComponent, dialogForm } = props;

  const dc: IDataController = new dataController(model);

  const [records, setRecords] = useState<DCRecord[]>([]);

  useEffect(() => {
    if (selectedPrimaryRecordId !== 0) {
      refreshRecords();
    }
  }, [selectedPrimaryRecordId]);

  useEffect(() => {
    if (triggerRefresh) {
      setTriggerRefresh(false);
      if (selectedPrimaryRecordId !== 0) {
        refreshRecords();
      }
    }
  }, [triggerRefresh]);

  const refreshRecords = () => {
    const path = `${baseRecordPath}/${selectedPrimaryRecordId}/${childRecordRelativePath}`;
    dc.GetData(path)
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
          if (onLoad) {
            onLoad(resp.data);
          }
        }
      })
      .catch((resp) => {
        setRecords([]);
      });
  };

  const handleView = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'view',
        form,
        recordId: record.id,
        onClose: () => {
          handleDialogClose({
            dataChanged: true,
            action: 'view',
            selId,
          });
        },
      });
    }
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'update',
        form,
        recordId: record.id,
        onClose: () => {
          handleDialogClose({
            dataChanged: true,
            action: 'update',
            selId,
          });
        },
      });
    }
  };

  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
    selId?: number;
  }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  return (
    <ModelTable
      title={t(titleToken)}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={false}
      allowExport={false}
      allowAdd={false}
      onRowAction={onRowAction}
      allowRowAction={() => true}
      onRowClick={(
        evt: React.SyntheticEvent,
        sel: { [key: string]: DCRecord }
      ) => {
        const clickedRecord = sel[Object.keys(sel)[0]];
        return onRecordSelect(clickedRecord.id as number);
      }}
      viewName={view}
      rowSelectedCustom={selectedPrimaryRecordId}
    >
      <TableButtonShowPhotos
        disabled={!imagesEnabled}
        variant="contained"
        onClick={setAllowImages}
      />
      {allowEdit ? (
        <TableButtonEdit variant="outlined" onClick={handleEdit} />
      ) : (
        <></>
      )}
      {allowView ? (
        <TableButtonView variant="contained" onClick={handleView} />
      ) : (
        <></>
      )}
    </ModelTable>
  );
};

export default DataCentricSecondaryTable;
