import React from 'react'

import { Box, ButtonBase, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material'

export type MapButtonProps = {
    id: string
    className: string
    handleClick: () => void;
    title?: string
    children?:JSX.Element;
    active?: boolean;
}

const MapButton = (props: MapButtonProps) => {

    const {id,className,handleClick,title,children,active} = props;

    const theme = useTheme();

    const handleButtonClick = (evt: React.SyntheticEvent) =>{
        handleClick();
    }
    
    var classes = "ol-custom-control ol-control " + className;
    if (active) {
        classes += " ol-active"
    }

    return (
        <div className={classes} title={title}> 
            <ButtonBase 
                id={id}
                onClick={handleButtonClick}
                sx={{
                    bgcolor: theme.palette.primary.main+"!important",
                }}
            >
                {children}
            </ButtonBase>
        </div>
    )

}

export default MapButton;