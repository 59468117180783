import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// MUI
import Button from '@mui/material/Button';

// Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Types
import { ICardActionButton } from '@/@types/ui/Card';
import { useMediaQuery, useTheme } from '@mui/material';
export interface IDialogActionButton extends ICardActionButton {
  // TODO: Discuss whether we should just make an IActionButton, maybe we might pass other props to DialogActionButton
}

const DialogActionButton: FunctionComponent<IDialogActionButton> = (
  props: React.PropsWithChildren<IDialogActionButton>
) => {
  const userContext = useContext(UserContext) as UserContextType;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md')); 

  const {
    needPermission,
    startIcon,
    onClick,
    variant,
    disabled,
    ariaLabel,
    ariaControls,
    children,
  } = props;

  if (needPermission===undefined || (needPermission!==undefined && userContext.hasAnyPermission(needPermission))) {
    return (
      <Button
        color="primary"
        size={mdUp ? "medium" : "small"}
        startIcon={startIcon}
        onClick={onClick}
        variant={variant}
        aria-label={ariaLabel}
        aria-controls={ariaControls}
        disabled={disabled}
        sx={{
          ml: {sm: 2, md: 10},
          mr: 0,
        }}
      >
        {children}
      </Button>
    );
  }
  return null;
};

export default DialogActionButton;
