import React from 'react'

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton'
import { useTranslation } from 'react-i18next';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map';

// OL
import { transform as OlTransform } from "ol/proj";

// MUI
import { Directions as DirectionsIcon } from '@mui/icons-material';

const GoogleMapsButton = (props: TCustomGFIActionProps) => {

    const { t } = useTranslation();

    const { featureData } = props;

    const handleGoogleNavigate = (position: [number, number]) => {
        const positionLonLat = OlTransform(position, 'EPSG:3857', 'EPSG:4326');
        const googleMapsNavigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${positionLonLat[1]},${positionLonLat[0]}`;
        window.open(googleMapsNavigationUrl, '_blank');
    }
    
    return (
        <MapCardActionButton onClick={() => handleGoogleNavigate(featureData.position)} startIcon={<DirectionsIcon/>}>
            {t("buttons.google_maps")}
        </MapCardActionButton>
    )

}

export default GoogleMapsButton;