import React, { useContext, SyntheticEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

//Custom Components
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogActionButtonClose from '@/ui/Dialog/ActionButtons/DialogActionButtonClose';
import DialogContext from '@/context/DialogContext/DialogContext';
import { IDialogProps } from '@/context/DialogContext/DialogContext';
import ModelTable from '@/ui/Table/ModelTable';

//Types
import {
  ClosingDetails,
} from '@/@types/components/formController';

const PPVKupoprodajniUgovoriDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { recordsUgovori, dcUgovori } = props;

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: "cancel" });
  };

  const close = (result: ClosingDetails) => {
    dialogContext.hideDialog();
  };

  return (
    <DynamicDialog open={true} maxWidth={'xl'} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t('cjenovni_blokovi.ugovori')}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box height={"650px"}>
          <ModelTable
            title=" "
            tableName="ugovori"
            viewName="default"
            records={recordsUgovori || []}
            dc={dcUgovori}
            allowSelection="none"
            allowAdd={false}
            allowRowAction={() => false}
            onRowAction={() => { }}
            disableControls={false}
            allowColumnPicker
            size="small"
            allowFilter
            allowExport
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
      </DialogActions>
    </DynamicDialog>
  );
};

export default PPVKupoprodajniUgovoriDialog;
