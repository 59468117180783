import * as React from 'react';
import {
  FunctionComponent,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useContext,
} from 'react';

// MUI
import Menu from '@mui/material/Menu';

// MUI Icons
import MenuIcon from '@mui/icons-material/Menu';

// Custom Components
import { PopoverProps } from '@mui/material/Popover';
import CardToolbarButton from '@/ui/Card/CardToolbarButton';
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Types
import { ICardToolbarMenuButton } from '@/@types/ui/Card';

const CardToolbarMenuButton: FunctionComponent<
  React.PropsWithChildren<ICardToolbarMenuButton>
> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [anchorEl, setAnchorEl] =
    React.useState<PopoverProps['anchorEl']>(null);

  const { needPermission } = props;

  const handleClick = (evt: SyntheticEvent): void =>
    setAnchorEl(evt.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  const hookAction = (action: () => void): void => {
    setAnchorEl(null);
    if (action) {
      action();
    }
  };

  const childrenWithProps: ReactNode | undefined | null = React.Children.map(
    props.children,
    (child) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child as ReactElement, {
          onClick: () => hookAction(child.props.onClick),
        });
      }
      return child as ReactNode;
    }
  );

  if (
    needPermission === undefined ||
    (needPermission && userContext.hasAnyPermission(needPermission))
  ) {
    return (
      <>
        <CardToolbarButton onClick={handleClick}>
          <MenuIcon />
        </CardToolbarButton>
        <Menu
          id="card-toolbar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '.MuiMenu-paper': {
              backgroundColor: 'primary.main',
            },
            '.MuiMenu-list': {
              color: 'common.black',
            },
          }}
        >
          {childrenWithProps}
        </Menu>
      </>
    );
  } else return null;
};
export default CardToolbarMenuButton;
