import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Switch from '@mui/material/Switch';

// MUI Icons
import FilterListIcon from '@mui/icons-material/FilterList';

// Custom Components
import TableHeaderButton from './TableHeaderButton';

import { ITableHeaderButtonFilterProps } from '@/@types/ui/Table';

const TableHeaderButtonFilter = (props: ITableHeaderButtonFilterProps) => {
  const { t } = useTranslation();
  const { filters, ...rest } = props;

  return (
    <TableHeaderButton
      startIcon={<FilterListIcon />}
      endIcon={<Switch checked={filters} size="small" color="primary" />}
      variant="outlined"
      {...rest}
      sx={{ display: { xs: 'none', sm: 'flex' } }}
    >
      {filters ? t('buttons.filters-off') : t('buttons.filters-on')}
    </TableHeaderButton>
  );
};

export default TableHeaderButtonFilter;
