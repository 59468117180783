import React, { useContext, useEffect, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userService } from '@/services/userService';

//Material UI
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

//Material-UI Icons
import ContactsIcon from '@mui/icons-material/Contacts';
import PeopleIcon from '@mui/icons-material/People';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MapIcon from '@mui/icons-material/Map';

//Custom Icons
import UserIcon from '@/icons/UserIcon';

//Custom components
import FormContent from '@/components/FormContent';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemActivate,
  CardToolbarMenuItemDeactivate,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import {
  FormAvatar,
  FormHeading,
  FormHeadingID,
  FormFillContent,
  FormTab,
  FormTabPanel,
  FormTabs,
} from '@/ui/Form';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import dataController from '@/lib/DataController';
// import imageModel from "Models/poslovniSubjektiImages";
// import ImageThumbnail from "UI/Image/ImageThumbnail";
// import ImageUploadDialog from "UI/Dialog/ImageUploadDialog";
// import SwapButton from "UI/Buttons/SwapButton";
import useFormController from '@/components/useFormController';
import { FormContentMode } from '@/components/FormContent';
import { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';
import { IApiResponse } from '@/@types/lib/api';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import LicenceModel from '@/models/licence';
import UserModel from '@/models/user';
import TenantFormConfigTab from '@/views/Tenants/TenantConfig/TenantMapConfigTab';
import { ClosingDetails } from '@/@types/components/authFormController';
import ModelTable from '@/ui/Table/ModelTable';
import UserLicenceModel from '@/models/licence';
import { DCRecord } from '@/@types/lib/dataController';
import TenantAdminLicencesDialog from '@/ui/AdminDialogs/TenantAdminLicencesDialog';

interface IUserFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

function UserForm(props: IUserFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext) as DialogContextType;

  const [currentTab, setCurrentTab] = useState(0);

  // const close = (result: ClosingDetails) => {
  //   if (result.dataChanged || dataChanged) {
  //     onClose({ dataChanged: true, action: result.action });
  //   }
  // };

  const { dc, recordId, mode, form } = props;
  const {
    record,
    validation,
    fields,
    onFieldChange,
    doUpdate,
    doDelete,
    doRefresh,
    doClose,
    pickerDataControllers,
    dataChanged,
  } = useFormController({
    mode,
    recordId: recordId,
    form,
    dc,
    onClose: close,
  });

  const [userLicenceRecords, setUserLicenceRecords] = useState<Array<DCRecord>>(
    []
  );

  const dcUserLicences = new dataController(
    UserLicenceModel,
    'system/tenants/' +
      String(record.tenant_id) +
      '/users/' +
      String(recordId) +
      '/licences'
  );

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/admin/users');
          } else if (resp.shouldSave) {
            if (doUpdate) {
              doUpdate()
                .then((result) => {
                  if (result.success) {
                    const msg = 'messages.update_user_success';
                    snackbarContext.showNotification(msg, 'success');
                    navigate('/admin/users');
                  }
                })
                .catch((result) => {
                  if (
                    result.success === false &&
                    result.validationPass === false
                  ) {
                    //do nothing
                  } else {
                    const msg = 'error.update_user';
                    snackbarContext.showNotification(msg, 'error');
                  }
                });
            }
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_user_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            const msg = 'error.update_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
    }
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_user_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/admin/users');
          }
        })
        .catch((result) => {
          const msg = 'error.delete_user';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.activate(Number(record.id)).then((result) => {
          //@ts-ignore
          if (result.success) {
            const msg = 'messages.activate_user_success';
            snackbarContext.showNotification(msg, 'success');
            doRefresh();
          } else {
            const msg = 'error.activate_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.activate_user';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleDeactivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.deactivate(Number(record.id)).then((result) => {
          //@ts-ignore
          if (result.success) {
            const msg = 'messages.deactivate_user_success';
            snackbarContext.showNotification(msg, 'success');
            //close({ dataChanged: true, action: 'update' });
            navigate(-1);
          } else {
            const msg = 'error.deactiavate_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.deactivate_user';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleRowAction = (
    action: string,
    id: number,
    initial_record?: { [key: string]: any }
  ) => {
    if (action === 'edit') {
      dialogContext.showDialog(TenantAdminLicencesDialog, {
        mode: 'update',
        form: 'update_user_licence',
        dataId: id,
        onClose: () => {},
        refreshRecords: getLicences,
        initialRecord: initial_record ? initial_record : undefined,
        tenantId: record.tenant_id,
        userId: recordId,
      });
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    pickerDataControllers: pickerDataControllers,
  };

  const title = record.first_name ? (record.first_name as string) : undefined;

  const showSaveButton = currentTab === 0 || currentTab === 1;
  const disableSaveButton = !dataChanged;

  useEffect(() => {
    if (
      record &&
      record !== null &&
      record.tenant_id !== undefined &&
      record.tenant_id !== null &&
      userLicenceRecords.length === 0
    ) {
      getLicences();
    }
  }, [record]);

  const getLicences = () => {
    const dcUserLicences = new dataController(
      UserLicenceModel,
      'system/tenants/' +
        String(record.tenant_id) +
        '/users/' +
        String(recordId) +
        '/licences'
    );

    dcUserLicences
      .GetData()
      .then((resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setUserLicenceRecords(resp.data);
        }
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {});
  };

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="user" icon={<UserIcon />} />
          <FormHeading
            header={title}
            subheaderContent={<FormHeadingID id={recordId} />}
            dataChanged={dataChanged}
          />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
          <CardToolbarMenuButton>
            {record.active ? (
              <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
            ) : (
              <CardToolbarMenuItemActivate onClick={handleActivate} />
            )}
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs
          value={currentTab}
          onChange={handleTabChange}
          identifier={dc.getSource()}
        >
          <FormTab
            id="basic"
            label={t('core:common.tabs.basic')}
            icon={<BusinessCenterIcon />}
            aria-label="basic"
            value={0}
          />
          <FormTab
            id="licences"
            label={t('core:user.tabs.licences')}
            icon={<ContactsIcon />}
            aria-label="licences"
            value={1}
          />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.basic') as string}
                  fieldNames={[
                    'first_name',
                    'last_name',
                    'username',
                    'email',
                    'app_permissions',
                    'status',
                    'user_type_id',
                    'remarks',
                    'tenant_id',
                  ]}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          {record &&
          record !== null &&
          record.tenant_id !== undefined &&
          record.tenant_id !== null ? (
            <ModelTable
              title={t('admin:tenant.sections.licences') as string}
              key={`tblid-tenant-admin-user-licences`}
              dc={dcUserLicences}
              records={userLicenceRecords}
              allowSelection="one"
              allowRowAction={() => true}
              onRowAction={handleRowAction}
              viewName="tenant_admin_user"
              allowColumnPicker
              allowFilter={true}
              allowAdd={true}
              addLabel={t('buttons.new_f') as string}
              handleAdd={() => {
                dialogContext.showDialog(TenantAdminLicencesDialog, {
                  mode: 'insert',
                  form: 'insert_user_licence',
                  dataId: undefined,
                  onClose: () => {},
                  refreshRecords: getLicences,
                  initialRecord: undefined,
                  tenantId: record.tenant_id,
                  userId: recordId,
                });
              }}
            />
          ) : null}
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? (
          <CardActionButtonSave
            onClick={handleUpdate}
            variant="contained"
            disabled={disableSaveButton}
          />
        ) : null}
      </CardActions>
    </Card>
  );
}

export default UserForm;
