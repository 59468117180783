import { FunctionComponent, useContext, useEffect } from 'react';

//OpenLayers
import { Draw } from 'ol/interaction';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlInteraction } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IDrawInteraction } from '@/@types/components/Map/Interactions/OpenLayers';

const DrawInteraction: FunctionComponent<IDrawInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const { type } = props;

  const options = {
    type: undefined,
    clickTolerance: undefined,
    features: undefined,
    source: undefined,
    dragVertexDelay: undefined,
    snapTolerance: undefined,
    stopClick: undefined,
    maxPoints: undefined,
    minPoints: undefined,
    finishCondition: undefined,
    style: undefined,
    geometryFunction: undefined,
    geometryName: undefined,
    condition: undefined,
    freehand: undefined,
    freehandCondition: undefined,
    wrapX: undefined,
  };

  const events = {
    change: undefined,
    'change:active': undefined,
    drawend: undefined,
    drawstart: undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(
    () => registerOlInteraction(context, Draw, props, options, events),
    [type]
  );

  useEffect(
    () => registerOlInteraction(context, Draw, props, options, events),
    []
  );

  return null;
};

export default DrawInteraction;
