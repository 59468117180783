import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';
import ThreeDAdminTable from './ThreeDAdminTable';

function ThreeDAdminPage() {
  return (
    <AppPage
      titlett="admin:page.3d_settings"
      needPermission={[Permissions.Administration]}
    >
      <Box m={0} style={{ height: '100%' }}>
        <ThreeDAdminTable />
      </Box>
    </AppPage>
  );
}

export default ThreeDAdminPage;
