import React from "react"
import { useTranslation } from "react-i18next";

// MUI
import { Box, Dialog, Toolbar, useMediaQuery, useTheme } from "@mui/material";

// Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";

interface ImageDialogProps {
  image: any,
  onClose: () => void
}

const imageStyles: React.CSSProperties = {
 height: "100%",
 width: "100%",
 objectFit: "contain"
};

const ImageDialog = (props: ImageDialogProps) => {
  const { image, onClose } = props
  const { t } = useTranslation();

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleClose = () => {
    onClose();
  }

  return (
    <Dialog 
      open
      fullWidth
      maxWidth="xl"
      fullScreen={smUp ? false : true}
      onClose={handleClose}
      PaperProps={{ 
        style: { 
          zIndex: 1300,
          minHeight: '70vh'
        },
    }}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <DialogToolbarHeading>{t("titles.photo")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box pt={2} display="flex" justifyContent="center" alignItems="center" width={"100%"} height={"100%"}>
          {image ? <img src={`data:image/jpeg;base64, ${image}`} style={imageStyles} /> : null}
        </Box>
      </DialogBody>
    </Dialog>
  )


}

export default ImageDialog