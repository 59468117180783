import React from 'react';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

//Types
import { SvgIconProps } from '@mui/material';

function ThreeDSettingIcon(props: SvgIconProps) {
  return <ViewInArIcon {...props} />;
}

export default ThreeDSettingIcon;