import * as React from "react";
import { HexColorPicker } from "react-colorful";
import { useState,useEffect } from "react";

//MUI
import InputAdornment from "@mui/material/InputAdornment";
import WaterDropIcon from '@mui/icons-material/WaterDrop';

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { IColorControlProps } from "@/@types/controls/controls";
import { IconButton, Popover, SvgIcon } from "@mui/material";

/*

  To use this input control,
  add something like this to a model: 

  {
    title: "tenant_color",
    source: "tenant_color",
    ttoken: "common.tenant_color",
    type: "color",
    validation: {
      required: true,
      maxLength: 7,
      minLength: 4,
      regex: "color"
    }
  },

*/

function ColorControl(props: IColorControlProps) {

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { field, value, onChange } = props;

  const hexColorRegexTyping = /^#[0-9a-fA-F]{0,6}$/;

  const WaterDropWithStroke = () => {
    return (
      <SvgIcon>
        <path 
          d="M12 2c-5.33 4.55-8 8.48-8 11.8 0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8zM7.83 14c.37 0 .67.26.74.62.41 2.22 2.28 2.98 3.64 2.87.43-.02.79.32.79.75 0 .4-.32.73-.72.75-2.13.13-4.62-1.09-5.19-4.12-.08-.45.28-.87.74-.87z"
          // d is taken from WaterDrop MUI Icon 
          stroke="black"
          strokeWidth="1"
          color={value}
        />
      </SvgIcon>
    )
  }

  const handleInputChange = (newValue: string, source: string) => {
      if (newValue.length > 0 ) {
        if (hexColorRegexTyping.test(newValue)) {
          onChange(newValue, source)
        }
      } 
      else {
        onChange("", source);
      }
  }

  const handlePickerChange = (newValue: string) => {
    onChange(newValue,field.source);
  }


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect( () => {
    if (!value) {
      onChange("#",field.source);
    }
  },[])

  const open = Boolean(anchorEl);

  return (
    <>
      <BaseInputControl
        {...props}
        onChange={handleInputChange}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton 
                disableFocusRipple
                disableRipple
                disableTouchRipple
                size="small"
                onClick={handleClick}  
              >
                <WaterDropWithStroke />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper:{
            sx: {
              overflow: "hidden",
              borderRadius: "8px",
            }
          }
        }}
      >
        <HexColorPicker color={value===""?"#":value} onChange={handlePickerChange} />
      </Popover>
    </>
  );
}

export default ColorControl;
