// These are trhee fields that describe alarms in CBC and are used in multiple UI views
// They can be added to UI models...

import { IFieldText, IFieldDateTime, IFieldPickerItems } from "@/@types/models/model";

const alarm_ts: IFieldDateTime = {
  title: "Alarm timestamp",
  source: "alarm_ts",
  ttoken: "common.alarm_ts",
  type: "datetime",
  validation: {
    required: false
  }
};

const alarm_active: IFieldPickerItems = {
  title: "Alarm active",
  source: "alarm_active",
  ttoken: "common.alarm_active",
  type: "boolean",
  items: {
    labels: ["common.ne", "common.da"],
    values: [false, true],
    default: true
  },
  translate: true,
  validation: {
    required: true
  }
};

const alarm_remark: IFieldText = {
  title: "Alarm Opis",
  source: "alarm_remark",
  ttoken: "common.alarm_remark",
  type: "text",
  validation: {
    maxLength: 100
  },
  width: "full"
};

export const alarmFields = {
  alarm_ts: alarm_ts,
  alarm_active: alarm_active,
  alarm_remark: alarm_remark
};
