import { IModel } from '@/@types/models/model';

import commonFields from './helpers/commonFields';
import buttonFields from './helpers/buttonFields';
import coreFields from './helpers/coreFields';

const KomunalniObveznici: IModel = {
  title: 'Komunalni obveznici',
  source: 'komunalni_obveznici',
  apiPath: 'ena/komunalni-obveznici',
  forms: {
    default: {
      fields: [
        'obv',
        'adrobv',
        // 'sifobv',
        'nasobv',
        // 'adrobj',
        // 'nasobj',
        // 'ulobj',
        // 'kbobj',
        'lokacija_id',
        'lokacija_id_short',
        'zap',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'obv',
        'oib',
        // 'sifobv',
        // 'vrsta_prostora',
        // 'p_a',
        // 'p_b',
        // 'adrobv',
        // 'adrobj',
        // 'namjena',
        'pov_a',
        'pov_b',
        // 'zona',
        'lokacija_id_short',
        'zapisnik_id',
        'status_obv',
        'active',
        // 'zapisnik_dl',
        'btn_show_photos',
        'download',
      ],
      hidden: ['id', 'oib', 'lokacija_id_short'],
    },
  },
  fields: [
    {
      title: 'id',
      source: 'id',
      ttoken: 'common.id',
      type: 'numeric',
      idattr: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'Obveznik',
      source: 'obv',
      ttoken: 'ena:komunalni_obveznici.obv',
      type: 'text',
      filter: true,
    },
    {
      title: 'OIB',
      source: 'oib',
      ttoken: 'common.oib',
      type: 'text',
      filter: true,
    },
    {
      title: 'Status',
      source: 'status_obv',
      ttoken: 'ena:komunalni_obveznici.status',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'adrobv',
      source: 'adrobv',
      ttoken: 'ena:komunalni_obveznici.adrobv',
      type: 'text',
      filter: true,
    },
    // {
    //   title: 'sifobv',
    //   source: 'sifobv',
    //   ttoken: 'ena:komunalni_obveznici.sifobv',
    //   type: 'text',
    // },
    {
      title: 'nasobv',
      source: 'nasobv',
      ttoken: 'ena:komunalni_obveznici.nasobv',
      type: 'text',
      filter: true,
    },
    // {
    //   title: "sifobj",
    //   source: "sifobj",
    //   ttoken: "komunalni_obveznici.sifobj",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "adrobj",
    //   source: "adrobj",
    //   ttoken: "komunalni_obveznici.adrobj",
    //   type: "text",
    //   search: true
    // },
    // {
    //   title: "nasobj",
    //   source: "nasobj",
    //   ttoken: "komunalni_obveznici.nasobj",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "ulobj",
    //   source: "ulobj",
    //   ttoken: "komunalni_obveznici.ulobj",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "kbobj",
    //   source: "kbobj",
    //   ttoken: "komunalni_obveznici.kbobj",
    //   type: "text",
    //   search: false
    // },
    {
      title: 'Lokacija ID',
      source: 'lokacija_id',
      ttoken: 'ena:komunalni_obveznici.lok',
      type: 'text',
      filter: true,
    },
    {
      title: 'Lokacija ID short',
      source: 'lokacija_id_short',
      ttoken: 'ena:komunalni_obveznici.lok',
      type: 'text',
      filter: true,
    },
    {
      title: 'Glavni Objekt ID',
      source: 'glavniobj_id',
      ttoken: 'komunalni_obveznici.lok',
      type: 'text',
    },
    {
      title: 'zap',
      source: 'zap',
      ttoken: 'ena:komunalni_obveznici.zap',
      type: 'text',
      filter: true,
    },
    {
      title: 'Broj zapisnika',
      source: 'zapisnik_id',
      ttoken: 'ena:komunalni_obveznici.zapisnik_id',
      type: 'text',
      filter: true,
    },
    // {
    //   title: "zap",
    //   source: "zapisnik_dl",
    //   ttoken: "komunalni_obveznici.zapisnik_dl",
    //   type: "dokumenti",
    //   search: false
    // },
    {
      title: '',
      source: 'btn_show_photos',
      tooltip: 'buttons.show_photos',
      ttoken: '',
      type: 'button',
      icon: 'photo_camera',
      action: 'show_photos',
      padding: 'none',
      sort: false,
    },
    // {
    //   title: "namjena",
    //   source: "namjena",
    //   ttoken: "komunalni_obveznici.namjena_poslije",
    //   type: "text",
    //   search: false
    // },
    {
      title: 'Povrsina A',
      source: 'pov_a',
      ttoken: 'ena:komunalni_obveznici.pov_a',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina B',
      source: 'pov_b',
      ttoken: 'ena:komunalni_obveznici.pov_b',
      type: 'numeric',
      filter: true,
    },
    // {
    //   title: "Zona",
    //   source: "zona",
    //   ttoken: "komunalni_obveznici.zona",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "Katastarska opcina",
    //   source: "ko",
    //   ttoken: "komunalni_obveznici.ko",
    //   type: "text",
    //   search: true
    // },
    // {
    //   title: "Katastarska cestica",
    //   source: "kc",
    //   ttoken: "komunalni_obveznici.kc",
    //   type: "text",
    //   search: true
    // },
    // {
    //   title: "Vrsta prostora",
    //   source: "vrsta_prostora",
    //   ttoken: "komunalni_obveznici.namjena_prije",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "Povrsina za naplatu",
    //   source: "p_a",
    //   ttoken: "komunalni_obveznici.p_a",
    //   type: "numeric",
    //   search: false
    // },
    // {
    //   title: "Povrsina za naplatu",
    //   source: "p_b",
    //   ttoken: "komunalni_obveznici.p_b",
    //   type: "numeric",
    //   search: false
    // },
    {
      ...buttonFields.download,
      action: 'download_zapisnik',
      idref: 'zapisnik_id',
    },
    commonFields.wkt,
    { ...coreFields.active, filter: true },
  ],
};

export default KomunalniObveznici;
