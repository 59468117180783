import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

// MUI
import { Grid } from '@mui/material';

// Custom Components

// Ui
import MapCentricTable from '@/ui/MapCentric/MapCentricTable';
import MapCentricMiniMap from '@/ui/MapCentric/MapCentricMiniMap';
// import MapCentricImagesOverlay from '@/ui/MapCentric/MapCentricImagesOverlay';

// Lib
import DataController from '@/lib/DataController';

// Context
import { IDialogProps } from '@/context/DialogContext/DialogContext';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IModel } from '@/@types/models/model';

export type DialogFormGenerateFunction = (record: DCRecord) => string;

export type MapCentricPageParams = {
  model: IModel;
  titleToken: string;
  view: string | undefined;
  allowAdd?: boolean;
  allowView?: boolean;
  allowEdit?: boolean;
  dialogComponent?: React.FunctionComponent<IDialogProps>;
  dialogForm?: string | DialogFormGenerateFunction;
  initialSelectedId?: number;

  baseRecordPath: string;
  geomRelativePath: string;

  mapId: number;
  mapModel: IModel;

  allowShowImages?: boolean;
  imageRelativePath?: string;
  modelPhotos?: IModel;

  // Open page as dialog
  dialogAsPagePath?: string | undefined;
};

const MapCentricPage = (props: MapCentricPageParams) => {
  const [selectedRecordId, setSelectedRecordId] = useState(
    props.initialSelectedId || 0
  );
  const [showImages, setShowImages] = useState(false);
  const [images, setImages] = useState<DCRecord[]>([]);
  const [imagesButtonEnabled, setImagesButtonEnabled] = useState(false);

  const {
    model,
    view,
    titleToken,
    allowAdd,
    allowView,
    allowEdit,
    dialogComponent,
    dialogForm,
    initialSelectedId,
    mapId,
    mapModel,
    baseRecordPath,
    geomRelativePath,
    allowShowImages,
    imageRelativePath,
    modelPhotos,
    dialogAsPagePath,
  } = props;

  const handleRecordSelect = (id: number) => {
    if (id === selectedRecordId) {
      setSelectedRecordId(0);
    } else if (id !== 0 && id !== null) {
      setSelectedRecordId(id);
    }
  };

  const fetchImages = () => {
    if (selectedRecordId !== 0 && modelPhotos && imageRelativePath) {
      const dc = new DataController(modelPhotos);
      const customPath = `${baseRecordPath}/${selectedRecordId}/${imageRelativePath}`;
      dc.GetData(customPath)
        .then((resp) => {
          if (resp.success && resp.data) {
            if (Array.isArray(resp.data)) {
              setImages(resp.data);
              setImagesButtonEnabled(true);
            } else {
              setImages([]);
              setImagesButtonEnabled(false);
            }
          }
        })
        .catch((resp) => {
          setImages([]);
          setImagesButtonEnabled(false);
        });
    }
  };

  useEffect(() => {
    if (initialSelectedId) {
      setSelectedRecordId(initialSelectedId);
    }
  }, [initialSelectedId]);

  useEffect(() => {
    fetchImages();
  }, [selectedRecordId]);

  const setAllowImages = () => {
    setShowImages(!showImages);
  };

  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={35}>
        <MapCentricMiniMap
          onRecordSelect={handleRecordSelect}
          selectedRecordId={selectedRecordId}
          model={mapModel}
          mapId={mapId}
          baseRecordPath={baseRecordPath}
          geomRelativePath={geomRelativePath}
        />
      </Panel>
      <PanelResizeHandle />
      <Panel defaultSize={65} minSize={20}>
        <MapCentricTable
          model={model}
          titleToken={titleToken}
          view={view}
          onRowAction={() => {}}
          onRecordSelect={handleRecordSelect}
          selectedRecordId={selectedRecordId}
          setAllowImages={setAllowImages}
          imagesEnabled={imagesButtonEnabled}
          allowAdd={allowAdd}
          allowView={allowView}
          allowEdit={allowEdit}
          dialogComponent={dialogComponent}
          dialogForm={dialogForm}
          dialogAsPagePath={dialogAsPagePath}
        />
        {/* {allowShowImages && showImages ? (
          <MapCentricImagesOverlay
            closeOverlay={() => setShowImages(false)}
            images={images}
            selectedObjektId={selectedRecordId}
          />
        ) : null} */}
      </Panel>
    </PanelGroup>
  );
};

export default MapCentricPage;
