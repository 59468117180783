import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// OpenLayers
import OlBaseLayer from 'ol/layer/Base';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';
import OlLayerGroup from 'ol/layer/Group';

// Custom components
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import mapService from '@/services/mapService';
import { BaseLayerSwitcherControl } from '@/components/Map/Controls';

// Types
type GeoBaseLayerSwitcherProps = {
  allowNoLayer?: boolean;
  mapId?: number;
  invisible?:boolean;
  type?: "minimap" | "map"
};

const GeoBaseLayerSwitcher = (props: GeoBaseLayerSwitcherProps) => {
  const [baselayersGroup, setBaselayersGroup] = useState<
    OlLayerGroup | undefined
  >(undefined);
  const context: MapContextType | null = useContext(MapContext);
  const { t } = useTranslation();
  const { allowNoLayer, mapId, invisible, type } = props;

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (context?.map) {
      mapService.getBaseLayers(t, mapId, allowNoLayer).then((olGroup: OlLayerGroup) => {
        if (olGroup) {
          const mapLayers = context?.map?.getLayers();
          if (mapLayers) {
            mapLayers.insertAt(0, olGroup);
          }
        }

        if (mounted.current) {
          setBaselayersGroup(olGroup);
        }
      });
    }
  }, [context?.map]);

  const showBaseLayerSwitcher = !!(
    context?.map &&
    baselayersGroup &&
    baselayersGroup.getLayers &&
    baselayersGroup.getLayers().getArray().length > 0 &&
    !invisible
  );

  const layersArray = baselayersGroup
    ? baselayersGroup.getLayers().getArray()
    : [];

  return showBaseLayerSwitcher ? (
    <BaseLayerSwitcherControl type={type} layers={layersArray} mapId={mapId} />
  ) : null;
};

export default GeoBaseLayerSwitcher;
