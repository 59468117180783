import { MenuType } from '@/@types/common';
import Permissions from '@/lib/permissions';

const menuRoutes: MenuType[] = [
  {
    path: '/',
    name: 'home',
    ttoken: 'menu.home',
    icon: 'home',
    needPermission: [Permissions.Core],
  },
  {
    path: '/map',
    name: 'map',
    ttoken: 'menu.map',
    icon: 'map',
    needPermission: [Permissions.Core],
  },
  {
    path: '/3d',
    name: 'threed',
    ttoken: 'menu.threed',
    icon: 'public',
    needPermission: [Permissions.ThreeDView],
  },
  {
    path: '',
    name: 'ena',
    ttoken: 'menu.ena',
    icon: 'apartment',
    needPermission: [Permissions.Ena],
    nested: [
      {
        path: '/ena/photodoc',
        name: 'photodocumentation',
        ttoken: 'menu.photodoc',
        icon: 'photo_camera',
        needPermission: [Permissions.Ena],
      },
      {
        path: '/ena/utility',
        name: 'utility obligations',
        ttoken: 'menu.utility_obligations',
        icon: 'request_page',
        needPermission: [Permissions.Ena],
      },
    ],
  },
  {
    path: '',
    name: 'rasvjeta',
    ttoken: 'menu.rasvjeta',
    icon: 'light',
    needPermission: [Permissions.Rasvjeta],
    nested: [
      {
        path: '/rasvjeta/mjesta',
        name: 'rasvjeta mjesta',
        ttoken: 'menu.rasvjeta_mjesta',
        icon: 'article',
        needPermission: [Permissions.Rasvjeta],
      },
      {
        path: '/rasvjeta/photodoc',
        name: 'rasvjeta photodocumentation',
        ttoken: 'menu.photodoc',
        icon: 'photo_camera',
        needPermission: [Permissions.Rasvjeta],
      },
    ],
  },
  {
    path: '',
    name: 'groblja',
    ttoken: 'menu.groblja',
    icon: 'church',
    needPermission: [Permissions.Groblja],
    nested: [
      {
        path: '/groblja/grobna-mjesta',
        name: 'grobna mjesta',
        ttoken: 'menu.grobna_mjesta',
        icon: 'article',
        needPermission: [Permissions.Groblja],
      },
      {
        path: '/groblja/photodoc',
        name: 'groblja photodocumentation',
        ttoken: 'menu.photodoc',
        icon: 'photo_camera',
        needPermission: [Permissions.Groblja],
      },
    ],
  },
  {
    path: '/nerazvrstane-ceste',
    name: 'nerazvrstane-ceste',
    ttoken: 'menu.nerazvrstane_ceste',
    icon: 'remove_road',
    needPermission: [Permissions.NerazvrstaneCeste],
  },
  {
    path: '/parking',
    name: 'parking',
    ttoken: 'menu.parking',
    icon: 'local_parking',
    needPermission: [Permissions.Parking],
  },
  {
    path: '/comments',
    name: 'comments',
    ttoken: 'menu.comments',
    icon: 'comment',
    needPermission: [Permissions.Private],
  },
  {
    path: '/admin',
    name: 'administration',
    ttoken: 'menu.admin',
    icon: 'build',
    needPermission: [Permissions.Administration],
    nested: [
      {
        path: '/admin/tenants',
        name: 'tenants',
        ttoken: 'menu.tenants',
        icon: 'business',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/users',
        name: 'users',
        ttoken: 'menu.users',
        icon: 'people',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/translations',
        name: 'translations',
        ttoken: 'menu.translations',
        icon: 'translate',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/models',
        name: 'models',
        ttoken: 'menu.models',
        icon: 'auto_awesome_motion_icon',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/map_layers',
        name: 'map_layers',
        ttoken: 'admin:menu.map_layers',
        icon: 'layers',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/map_legends',
        name: 'Map Legends',
        ttoken: 'admin:menu.map_legends',
        icon: 'signpost',
        needPermission: [Permissions.Administration],
      },  
      {
        path: '/admin/3d_settings',
        name: '3D Settings',
        ttoken: 'admin:menu.3d_settings',
        icon: 'view_in_ar',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/ena_settings',
        name: 'Ena Settings',
        ttoken: 'admin:menu.ena_settings',
        icon: 'room_preferences',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/app_logs',
        name: 'App Logs',
        ttoken: 'admin:menu.app_logs',
        icon: 'storage',
        needPermission: [Permissions.Administration],
      },
      {
        path: '/admin/login_logs',
        name: 'Login Logs',
        ttoken: 'admin:menu.login_logs',
        icon: 'key',
        needPermission: [Permissions.Administration],
      },
    ],
  },
  {
    path: '/test',
    name: 'test',
    ttoken: 'menu.test',
    icon: 'bug_report',
    needPermission: [Permissions.Test],
  },
];

export default menuRoutes;
