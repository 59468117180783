import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import proj4 from 'proj4';

// OpenLayers
import { register as OlRegister } from 'ol/proj/proj4';
import { get as OlGetProjection } from 'ol/proj';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import OlCollection from 'ol/Collection';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFeature from 'ol/Feature';
import OlFormatWKT from 'ol/format/WKT';

// Custom components
import {
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
} from '@/components/Map/Controls';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import { selectedStyle, commentsStyle } from '@/components/Map/mapStyles';
import Map from '@/components/Map/Map';

// Context
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

// Lib
import { flattenLayers } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';

// Types
import { IModel } from '@/@types/models/model';
import { ViewOptionsType } from '@/@types/common';
import { DCRecord } from '@/@types/lib/dataController';

interface IDataCentricMiniMapProps {
  selectedPrimaryRecordId: number;
  onPrimaryRecordSelect: Function;

  model: IModel;
  mapId: number;
  baseRecordPath: string;
  geomRelativePath: string;
  fetchGeomFromMW?: boolean;
}

const DataCentricMiniMap = (props: IDataCentricMiniMapProps) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();

  const [mapInitialized, setMapInitialized] = useState(false);

  const { selectedPrimaryRecordId, onPrimaryRecordSelect } = props;
  const { model, mapId, baseRecordPath, geomRelativePath, fetchGeomFromMW } =
    props;

  const [defaultExtent, setDefaultExtent] = useState(
    userContext?.mapSettings?.default_extent
      ? userContext.mapSettings.default_extent
      : [1437016, 5271097, 2206278, 5860579]
  );

  const [recordExtent, setRecordExtent] = useState<number[]>([]);
  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: userContext?.mapSettings
      ? userContext.mapSettings.initial_view_center
      : [1731757, 5581737],
    zoom: userContext?.mapSettings
      ? userContext.mapSettings.initial_view_zoom
      : 7,
    // extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    extent: [1447153, 5104226, 2162937, 5867310],
    // constrainOnlyCenter: true,
    // projection: wgs84PM,
    minZoom: 7,
    maxZoom: 18,
    constrainResolution: true,
  });
  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [primaryRecords, setPrimaryRecords] = useState<DCRecord[]>([]);

  let highlightedFeature: OlFeature<Geometry> | null = null;

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection('EPSG:3765');
  const wgs84 = OlGetProjection('EPSG:4326');
  const wgs84PM = OlGetProjection('EPSG:3857');

  const dc = new DataController(model);

  useEffect(() => {
    getLayers();
    refreshPrimaryRecords();
  }, []);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const refreshPrimaryRecords = () => {
    dc.GetData(`${baseRecordPath}`).then((resp) => {
      if (resp.success) {
        const records = resp.data as DCRecord[];
        if (Array.isArray(records) && records.length > 0) {
          // filter when .geom is missing
          const filteredRecords = records.filter(
            (record) =>
              record.hasOwnProperty('geom') &&
              record.geom &&
              record.geom !== null
          );
          setPrimaryRecords(filteredRecords);
        } else {
          setPrimaryRecords([]);
          setRecordExtent([]);
        }
      }
    });
  };

  useEffect(() => {
    if (!fetchGeomFromMW && primaryRecords.length > 0) {
      const features = primaryRecordsSource
        ?.getFeatures()
        .filter((x) => x.getId() === selectedPrimaryRecordId);
      const srcVector = new OlSourceVector({ features });
      setSelectedSource(srcVector);
      const rec = primaryRecords.find((x) => x.id === selectedPrimaryRecordId);

      if (rec) {
        setRecordExtent(srcVector.getExtent() as number[]);
      } else {
        setRecordExtent([]);
      }
    } else if (selectedPrimaryRecordId > 0) {
      const url =
        `${baseRecordPath}/${selectedPrimaryRecordId}/${geomRelativePath}` as string;
      dc.GetData(url).then((resp) => {
        if (resp.success) {
          const dcRec = resp.data as DCRecord;
          const wkt = dcRec?.wkt as string;
          const proj = dcRec?.proj as string;
          if (wkt && proj) {
            const wktFormatter = new OlFormatWKT();
            const feat = wktFormatter.readFeature(wkt, {
              dataProjection: proj,
              featureProjection: 'EPSG:3857',
            });
            setSelectedSource(new OlSourceVector({ features: [feat] }));
            const geom = feat.getGeometry();
            if (geom) {
              setRecordExtent(geom.getExtent());
            }
          }
        } else {
          setRecordExtent([]);
        }
      });
    }
  }, [selectedPrimaryRecordId]);

  useEffect(() => {
    if (selectedSource) {
      const features = selectedSource.getFeatures();
      if (features.length > 0) {
        setRecordExtent(selectedSource.getExtent());
      }
    }
  }, [selectedSource]);

  const primaryRecordsSource = useMemo(() => {
    if (primaryRecords === null) return;
    const features = primaryRecords
      .map((x) => x.geom)
      .filter((x) => x !== undefined);
      
    return new OlSourceVector({ features: features as OlFeature<Geometry>[] });
  }, [primaryRecords]);

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      (feature) => feature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('primary-records')
          );
        },
      }
    );

    if (feature) {
      const id = feature.getId();
      onPrimaryRecordSelect(id);
    } else {
      const allLayers = layersCollection
        ? flattenLayers(layersCollection.getArray()).filter(
            (x) => !(x instanceof OlLayerGroup)
          )
        : [];

      const GSLayerNames = allLayers
        .filter((x) => x.get('query') === true)
        .map((x) => x.get('layer'));

      if (GSLayerNames.length > 0) {
        gsService
          .getFeatureInfo(evt.map, evt.pixel, GSLayerNames)
          .then((resp) => {
            if (resp && Object.keys(resp).length != 0) {
              const layerKeys = Object.keys(resp);
              const key = layerKeys.length > 0 ? layerKeys[0] : null;
              const features = key ? resp[key] : [];

              if (Array.isArray(features) && features.length > 0) {
                const feat = features[0];
                const { id } = feat.properties;
                onPrimaryRecordSelect(id, feat.properties as DCRecord);
              } else {
                onPrimaryRecordSelect(null);
              }
            } else {
              onPrimaryRecordSelect(null);
            }
          });
      } else {
        onPrimaryRecordSelect(null);
      }
    }
  };

  const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    const pixel = evt.map.getEventPixel(evt.originalEvent);
    const feature = evt.map.forEachFeatureAtPixel(
      pixel,
      (feature: any) => feature,
      {
        layerFilter: (layer) =>
          // const layerId = layer.get("id");
          // return layerId !== null && layerId !== undefined && layerId.startsWith("primary-records");
          true,
      }
    ) as unknown as OlFeature<Geometry>;
    highlightFeature(feature);
  };

  const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  };

  if (layersCollection)
    return (
      <Map
        height="500px"
        view={viewOptions}
        onClick={handleClick}
        onPointermove={handlePointerMove}
        className="sidebar-map"
        id="mini-map"
        zoomToExtent={recordExtent.length ? recordExtent : undefined}
        zoomToExtentPadding={[20, 20, -20, -20]}
        initialized={mapInitialized}
      >
        <Controls>
          <ZoomControl
            zoomInTipLabel={`${t('map:controls.zoom_in')}`}
            zoomOutTipLabel={`${t('map:controls.zoom_out')}`}
          />
          <RotateControl autoHide={false} />
          <ScaleLineControl />
          <GeoBaseLayerSwitcher type='minimap' mapId={mapId} allowNoLayer={false} />
        </Controls>
        <Layers>
          <GeoAPILayers layersCollection={layersCollection} />
          <VectorLayer
            id="primary-records"
            source={primaryRecordsSource}
            style={commentsStyle}
            zIndex={900}
          />
          <VectorLayer
            id="selected"
            source={selectedSource}
            style={selectedStyle}
            zIndex={950}
          />
        </Layers>
      </Map>
    );

  return null;
};

export default DataCentricMiniMap;
