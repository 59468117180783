import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import EditIcon from '@mui/icons-material/Edit';

// Custom Components
import TableButton from './TableButton';

// Types
import { ITableActionButtonDetail } from '@/@types/ui/Table';

function TableButtonEdit(props: ITableActionButtonDetail) {
  const { t } = useTranslation();

  return (
    <TableButton allow="one" startIcon={<EditIcon />} selection={{}} {...props}>
      {t('buttons.edit')}
    </TableButton>
  );
}

export default TableButtonEdit;
