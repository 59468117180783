import * as React from 'react';
import { ReactNode, useContext } from 'react';

// MUI
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

// Custom components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { UserContextType } from '@/context/UserContext/UserContext';
import { Permission } from '@/@types/common';

export interface IHeaderMenuItem {
  onClick: () => void;
  label: string;
  icon?: ReactNode;
  needPermission?: Permission[];
}

const HeaderMenuItem = React.forwardRef<HTMLLIElement, IHeaderMenuItem>(
  (props, ref) => {
    const userContext = useContext(UserContext) as UserContextType;
    const { onClick, label, icon, needPermission } = props;

    if (needPermission && !userContext.hasAnyPermission(needPermission)) {
      return null;
    }
    return (
      <MenuItem onClick={onClick} ref={ref}>
        {icon ? (
          <ListItemIcon sx={{ minWidth: 32, color: 'white' }}>
            <Icon>{icon}</Icon>
          </ListItemIcon>
        ) : null}
        {label ? (
          <Typography variant="button" sx={{ color: 'white' }}>
            {label}
          </Typography>
        ) : null}
      </MenuItem>
    );
  }
);

export default HeaderMenuItem;
