import React, { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import Button, { ButtonProps } from '@mui/material/Button';

//Custom Components
import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext';

//Types
import { Permission } from '@/@types/common';
export interface IMapCardButtonProps extends ButtonProps {
  needPermission?: Permission[];
  tooltip?: string;
  disabled?: boolean;
  stickyRight?: boolean;
}
function MapCardActionButton(props: IMapCardButtonProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as UserContextType;

  const { needPermission, tooltip, disabled, stickyRight, ...rest } = props;

  const divStyle: CSSProperties = {};
  if (stickyRight) {
    divStyle['marginLeft'] = 'auto';
  }

  return needPermission &&
    !userContext.hasAnyPermission(needPermission) ? null : (
    <div style={divStyle}>
      <Button
        size="small"
        color="primary"
        variant="contained"
        {...rest}
        disabled={disabled ? disabled : false}
        sx={{ mr: 1 }}
      >
        {props.children}
      </Button>
    </div>
  );
}

export default MapCardActionButton;
