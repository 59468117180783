// Crop rectangular class with detailed logging
const Crop = function (originX, originY, width, height) {
  this._clickX = originX;
  this._clickY = originY;
  this._width = width;
  this._height = height;

  const absWidth = Math.abs(width);
  const absHeight = Math.abs(height);

  this.x = width < 0 ? originX - absWidth : originX;
  this.y = height < 0 ? originY - absHeight : originY;
  this.w = absWidth;
  this.h = absHeight;

  console.log(`Initial Crop Values: x=${this.x}, y=${this.y}, w=${this.w}, h=${this.h}`);

  // Enlarge selection so it is square (based on bigger side)
  this.makeSquare = function () {
    const size = Math.max(this.w, this.h);
    this.w = size;
    this.h = size;

    if (this.x < this._clickX) {
      this.x = this._clickX - size;
    }
    if (this.y < this._clickY) {
      this.y = this._clickY - size;
    }

    console.log(`After makeSquare: x=${this.x}, y=${this.y}, w=${this.w}, h=${this.h}`);
  };

  // Enlarge selection so it fits the ratio (based on the bigger side)
  this.keepRatio = function (ratio) {
    const size = Math.max(this.w, this.h);
    if (this.w === size) {
      this.h = Math.round(size / ratio);
    } else {
      this.w = Math.round(size * ratio);
    }

    if (this.x < this._clickX) {
      this.x = this._clickX - this.w;
    }
    if (this.y < this._clickY) {
      this.y = this._clickY - this.h;
    }

    console.log(`After keepRatio: x=${this.x}, y=${this.y}, w=${this.w}, h=${this.h}`);
  };

  // Ensure the crop is within canvas boundaries
  this.shrinkToFit = function (canvasX, canvasY) {
    console.log(`Before shrinkToFit: x=${this.x}, y=${this.y}, w=${this.w}, h=${this.h}`);
    
    const dirWidth = this._width < 0 ? -1 : 1; // width direction (-1 or 1)
    const dirHeight = this._height < 0 ? -1 : 1; // height direction (-1 or 1)

    const endX = this._clickX + dirWidth * this.w;
    const endY = this._clickY + dirHeight * this.h;

    if (endX < 0 || endX > canvasX) {
      this.w = dirWidth > 0 ? canvasX - this.x : this._clickX;
    }
    if (endY < 0 || endY > canvasY) {
      this.h = dirHeight > 0 ? canvasY - this.y : this._clickY;
    }

    // Enforce the crop to remain square
    if (this.w > this.h) {
      this.w = this.h;
    } else {
      this.h = this.w;
    }

    // Adjust the crop to stay within boundaries after resizing
    if (this.x < 0) {
      this.x = 0;
    } else if (this.x + this.w > canvasX) {
      this.x = canvasX - this.w;
    }
    if (this.y < 0) {
      this.y = 0;
    } else if (this.y + this.h > canvasY) {
      this.y = canvasY - this.h;
    }

    console.log(`After shrinkToFit: x=${this.x}, y=${this.y}, w=${this.w}, h=${this.h}`);
  };

  this.getCursorStyle = function () {
    return this._width * this._height > 0 ? 'nwse-resize' : 'nesw-resize';
  };

  // Print data in a comma-separated string
  this.csv = function () {
    const arr = [this.x, this.y, this.w, this.h];
    return arr.join(',');
  };
};

export default Crop;
