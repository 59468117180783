import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';
import { IDataController } from '@/lib/DataController';

// Material UI Core

// Material UI Icons

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import AppLogsModel from '@/models/app_logs';
import dataController from '@/lib/DataController';
import { LoaderContextType } from '@/context/LoaderContext/LoaderContext';

function AppLogsTable() {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(AppLogsModel);

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  return (
    <ModelTable
      title={t('admin:titles.app_logs')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      allowAdd={true}
      allowColumnPicker={true}
      addLabel={t('buttons.new_m') as string}
      onRowAction={() => {}}
      allowRowAction={() => true}
      unselectRow={false}
    >
    </ModelTable>
  );
}

export default AppLogsTable;
