import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';

//MUI
import MUIButtonGroup from '@mui/material/ButtonGroup';

//OpenLayers
import OlControl from 'ol/control/Control';

//Custom components
import ButtonGroupContext from './ButtonGroupContext';
import ButtonGroupClass from './buttongroup';
import MapContext from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { ButtonGroupProps, useTheme } from '@mui/material';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

export interface IButtonGroupControlRoot extends ButtonGroupProps {
  id: string;
  order?: number;
}

const ButtonGroupControlRoot: FunctionComponent<IButtonGroupControlRoot> = (
  props
) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { id } = props;

  const theme = useTheme();

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      const options = {
        element: elementRef.current,
      };

      let allOptions = {};
      Object.assign(allOptions, options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new ButtonGroupClass(id, definedOptions);

      if (id) {
        control.set('id', id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ButtonGroupClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as OlControl);
      } else {
        mapContext.initOptions.controls.push(control as OlControl);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, ButtonGroupClass);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <MUIButtonGroup
      className="ol-button ol-custom-buttongroup"
      variant="contained"
      size="small"
      {...props}
      sx={{ position: 'absolute', top: "8px", left: '50%', ...props.sx }}
      ref={elementRef}
    >
      <ButtonGroupContext.Provider
        value={{ isInGroup: true, groupId: id, element: elementRef }}
      >
        {props.children}
      </ButtonGroupContext.Provider>
    </MUIButtonGroup>
  );
};

export default ButtonGroupControlRoot;
