import * as React from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import SaveIcon from "@mui/icons-material/Save";

//Custom components
import CardToolbarMenuItem from "@/ui/Card/CardToolbarMenuItem";

//Types
import { ICardToolbarMenuItemSave } from "@/@types/ui/Card";

const CardToolbarMenuItemSave = React.forwardRef<HTMLLIElement, ICardToolbarMenuItemSave>((props, ref) => {
  const { t } = useTranslation();
  const { label, onClick } = props;

  return <CardToolbarMenuItem label={t(label)} icon={<SaveIcon />} onClick={onClick} ref={ref} />;
});

export default CardToolbarMenuItemSave;
