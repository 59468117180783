import React, { useContext, SyntheticEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Compoenents
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';

// Custom Components
import useFormController from '@/components/useFormController';
import FormContent from '@/components/FormContent';
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogActionButtonAdd from '@/ui/Dialog/ActionButtons/DialogActionButtonAdd';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';
import { GridContainer} from '@/ui/Grid';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';

// Contexts
import DialogContext, { IDialogProps } from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';

// Types
import { ClosingDetails } from '@/@types/components/formController';

const MapLayersDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const { dc, mode, form, dataId, onClose, initialRecord } = props;

  const {
    record,
    validation,
    dataChanged,
    fields,
    pickerDataControllers,
    onFieldChange,
    doInsert,
    doUpdate,
    doClose,
  } = useFormController({
    mode,
    recordId: dataId,
    form,
    dc,
    onClose,
    initialRecord,
  });

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleInsert = (evt: SyntheticEvent) => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.insert_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert', id: result.id });
          } else if (result.error) {
            const msg = 'error.insert_map_layer';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = (evt: SyntheticEvent) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_map_layer_success';
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = 'error.update_map_layer';
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    if (doClose) {
      doClose()
        .then((result) => {
          if (result.success) {
            close({ dataChanged: false, action: 'cancel' });
          } else if (result.shouldSave) {
              handleUpdate(evt);
            } else if (result.canceled) {
              // do nothing
            }
        })
        .catch((e) => console.log(e));
    }
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      onClose({ dataChanged: true, action: result.action, id: result.id });
    }
    dialogContext.hideDialog();
  };

  let cmode = mode;
  if (record.status === 30) {
    cmode = 'view';
  }

  return (
    <DynamicDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <DialogToolbarHeading>
            {t('admin:titles.map_layers')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              title={t('admin:titles.map_layer_information') as string}
              fieldNames={[
                'ui_code',
                'ui_title',
                'ui_ttoken',
                'map_id',
                'tenant_id',
                'gfi_model_id',
                'gs_layer_source',
                'gs_layer_type',
                'gs_layer',
                'gs_format',
                'ui_legend',
                'legend_conf_id',
                'gs_url',
                'gs_projection',
                'layer_functionality_id',
              ]}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={cmode}
              columns={2}
              pickerDataControllers={pickerDataControllers}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert' ? (
          <DialogActionButtonAdd variant="contained" onClick={handleInsert} />
        ) : mode === 'update' ? (
          <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
        ) : null}
      </DialogActions>
    </DynamicDialog>
  );
};

export default MapLayersDialog;
