import React, { FunctionComponent, useState } from 'react';

//Custom
import MapContext from './MapContext';

//Types
import { BoolSettings, StringSettings } from '@/@types/common';
import Map from 'ol/Map';
import { InitOptionsType } from './MapContext';

export type MapContextProviderProps = {
  map: Map | null;
  initOptions: InitOptionsType;
  children: JSX.Element | JSX.Element[];
};

const MapContextProvider: FunctionComponent<MapContextProviderProps> = (
  props
) => {
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { map, initOptions } = props;

  const setLayerVisibility = (id: string, isVisible: boolean) => {
    const key: string = 'maplayers';

    const item = localStorage.getItem(key);
    const settings = item ? JSON.parse(item) : null;
    if (settings === null || Object.keys(settings).length === 0) {
      localStorage.setItem(key, JSON.stringify({ [id]: isVisible }));
    } else {
      settings[id] = isVisible;
      localStorage.setItem(key, JSON.stringify(settings));
    }

    setForceRefreshCounter((prev) => prev + 1);
  };

  const getLayerVisibility = () => {
    const key: string = 'maplayers';

    const item = localStorage.getItem(key);
    const settings = item ? JSON.parse(item) : null;

    return settings ? (settings as BoolSettings) : ({} as BoolSettings);
  };

  const setLayerTime = (id: string, time: string) => {
    const key = 'layerstime';

    const item = localStorage.getItem(key);
    const settings = item ? JSON.parse(item) : null;
    if (settings === null || Object.keys(settings).length === 0) {
      localStorage.setItem(key, JSON.stringify({ [id]: time }));
    } else {
      settings[id] = time;
      localStorage.setItem(key, JSON.stringify(settings));
    }

    setForceRefreshCounter((prev) => prev + 1);
  };

  const getLayerTime = () => {
    const key = 'layerstime';
    const item = localStorage.getItem(key);
    const settings = item ? JSON.parse(item) : null;

    return settings ? (settings as StringSettings) : ({} as StringSettings);
  };

  const providerValue = {
    map,
    initOptions,
    setLayerVisibility,
    getLayerVisibility,
    setLayerTime,
    getLayerTime,
    forceRefreshCounter,
    sidebarCollapsed,
    setSidebarCollapsed,
  };

  return (
    <MapContext.Provider value={providerValue}>
      {props.children}
    </MapContext.Provider>
  );
};

export default MapContextProvider;
