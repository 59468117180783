import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';

// MUI
import { styled } from '@mui/material/styles';

// OpenLayers
import OlControl from 'ol/control/Control';
import OlBaseLayer from 'ol/layer/Base';

// Custom components
import BaseLayerSwitcherClass from './baseLayerSwitcherClass';
import BaseLayerSwitcherUI from './BaseLayerSwitcherUI';
import MapContext from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';
import makeMUIControlStyle from '@/components/Map/Controls/makeMUIControlStyle';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { BaseLayerDefinition } from '@/@types/services/mapService';

export type BaseLayerSwitcherProps = {
  className?: string;
  layers: Array<OlBaseLayer>;
  type?: "map" | "minimap";
  mapId?: number;
};

const BaseLayerSwitcherControl: FunctionComponent<BaseLayerSwitcherProps> = (
  props
) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { className, layers, type, mapId } = props;

  const id = 'base-layer-switcher';

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      const options = {
        element: elementRef.current,
        target: undefined,
      };

      const allOptions = {};
      Object.assign(allOptions, options, props);
      const definedOptions = getDefinedOptions(allOptions);

      const events = {};

      const control = new BaseLayerSwitcherClass(definedOptions);
      control.set('id', id);

      if (mapContext.map) {
        const mapControl = findControl(
          mapContext.map,
          id,
          BaseLayerSwitcherClass
        );
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control as OlControl);
      } else {
        mapContext.initOptions.controls.push(control as OlControl);
      }

      const olEvents = getEvents(events, props);
      for (const eventName in olEvents) {
        // @ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(
          mapContext.map,
          id,
          BaseLayerSwitcherClass
        );
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <BaseLayerSwitcherUI
      layers={layers}
      ref={elementRef}
      className={className}
      type={type}
      mapId={mapId}
    />
  );
};

const StyledBaseLayerSwitcherControl = styled(BaseLayerSwitcherControl)(
  ({ theme }) => {
    const styles = makeMUIControlStyle(theme);
    return {
      ...styles.control,
    };
  }
);

export default StyledBaseLayerSwitcherControl;
