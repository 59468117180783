import * as React from 'react';
import { FunctionComponent, useContext } from 'react';

// Custom components
import SidebarContext, {
  SidebarContextType,
} from '@/context/SidebarContext/SidebarContext';
import { Permission } from '@/@types/common';
import UserContext from '@/context/UserContext/UserContext';

// Types
export interface ISidebarPane {
  id: string;
  children: JSX.Element | JSX.Element[];
  needPermission?: Permission[]
}

const SidebarPane: FunctionComponent<ISidebarPane> = (
  props: React.PropsWithChildren<ISidebarPane>
) => {
  const sidebarContext = useContext(SidebarContext) as SidebarContextType;

  const { id, children, needPermission } = props;

  const userContext = useContext(UserContext);

  const isActive = sidebarContext.activePane === id;
  return isActive && ((!needPermission || !needPermission.length) || ((needPermission && needPermission.length && (userContext?.hasAnyPermission(needPermission))))) ? (
    <div className="sidebar-pane active" id={id}>
      {children}
    </div>
  ) : null;
};

export default SidebarPane;
