import * as React from 'react';
import { useContext, useRef, useEffect, FunctionComponent } from 'react';

// Custom components
import MapContext, { MapContextType } from '@/context/MapContext/MapContext';
import Sidebar from './ol5-sidebar';
import './ol3-sidebar.css';
import SidebarContext from '../../../../context/SidebarContext/SidebarContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';

// Types
import { SidebarContextType, positionType } from '@/context/SidebarContext/SidebarContext';

export interface ISidebarRoot {
  initialOpenId?: string;
  id?: any;
  position?: positionType;
  children: JSX.Element | JSX.Element[];
}

const SidebarRoot: FunctionComponent<ISidebarRoot> = (
  props: React.PropsWithChildren<ISidebarRoot>
) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const sideBarContext = useContext(SidebarContext) as SidebarContextType;
  const sidebarRef = useRef(null);

  const { id, initialOpenId, position, children } = props;

  useEffect(() => {
    //console.log('useEffect Sidebar', props);
    //console.log(sidebarRef);
    if (sidebarRef.current) {
      const options = {
        element: sidebarRef.current,
        position: 'left',
      };

      const allOptions = Object.assign(options, props);
      const definedOptions = getDefinedOptions(allOptions);

      const events = {};

      const control = new Sidebar(definedOptions);

      if (id) {
        control.set('id', id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      const olEvents = getEvents(events, props);
      for (const eventName in olEvents) {
        // @ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  const classNames = ['sidebar'];
  classNames.push(`sidebar-${position || 'left'}`);
  if (sideBarContext.collapsed) {
    classNames.push('collapsed');
  }
  const joinedClassNames = classNames.join(' ');

  return (
    <div id="sidebar" className={joinedClassNames} ref={sidebarRef}>
      {children}
    </div>
  );
};

export default SidebarRoot;
