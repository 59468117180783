import { FunctionComponent, useContext, useEffect } from 'react';
import clsx from 'clsx';

//MUI
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

//OpenLayers
import Rotate from 'ol/control/Rotate';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlControl } from '@/lib/olHelpers';
import makeMUIControlStyle from '../makeMUIControlStyle';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IRotateControl } from '@/@types/components/Map/Controls/OpenLayers';

const RotateControl: FunctionComponent<IRotateControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    label: undefined,
    tipLabel: undefined,
    duration: undefined,
    autoHide: undefined,
    render: undefined,
    resetNorth: undefined,
    target: undefined,
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(() => {
    const overridenProps = {
      ...props,
      className: clsx(props.className, 'ol-rotate'),
    };
    registerOlControl(context, Rotate, overridenProps, options, events);
  }, [
    props.className,
    props.label,
    props.tipLabel,
    props.duration,
    props.autoHide,
    props.render,
    props.resetNorth,
    props.target,
  ]);

  return null;
};

const StyledRotateControl = styled(RotateControl)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.control,
  };
});

export default StyledRotateControl;
