import React, { useCallback, useMemo, useState } from 'react';
import { Badge, Box, Card, Grow, IconButton, TextField, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import TableHeaderButton from './HeaderButtons/TableHeaderButton';

type TableSearchProps = {
  globalFilter: string;
  setGlobalFilter: (filterValue: string) => void;
  dataCount: number;
};

const SearchTextField: React.FC<TableSearchProps> = ({ setGlobalFilter, globalFilter, dataCount }) => {
  const { t } = useTranslation();

  const handleSearchClear = useCallback(() => {
    setGlobalFilter('');
  }, [setGlobalFilter]);

  const handleSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        handleSearchClear();
      }
    },
    [handleSearchClear]
  );

  const globalFilterPlaceholder = useMemo(
    () => t('common.search_count_placeholder', { count: dataCount }),
    [dataCount, t]
  );

  return (
    <TextField
      id="global-search"
      InputProps={{
        startAdornment: <SearchIcon color="primary" />,
        endAdornment: (
          <IconButton disabled={!globalFilter} onClick={handleSearchClear}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      onKeyDown={handleSearchKeyDown}
      variant="standard"
      color="primary"
      margin="dense"
      placeholder={globalFilterPlaceholder}
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value)}
    />
  );
};

const TableSearch: React.FC<TableSearchProps> = ({ setGlobalFilter, globalFilter, dataCount }) => {
  const theme = useTheme();
  const isSmallScreenUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [searchOpen, setSearchOpen] = useState(false);

  const handleMobileSearchToggle = useCallback(() => {
    setSearchOpen((prev) => !prev);
  }, []);

  return isSmallScreenUp ? (
    <SearchTextField globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} dataCount={dataCount} />
  ) : (
    <Box position="relative">
         <Badge
        color="error"
        invisible={globalFilter === '' || searchOpen}
        badgeContent={
          <IconButton
            sx={{ m: 0, p: 0, width: '20px', height: '20px' }}
            onClick={() => setGlobalFilter('')}
            size="small"
          >
            <ClearIcon sx={{ fontSize: '15px', fontWeight: "bold" }} />
          </IconButton>
        }
        sx={{
          '.MuiBadge-badge': {
            padding: 0, // Remove padding around the badge
            minWidth: 'unset', // Remove minimum width constraint
            height: '20px', // Adjust height to fit the icon
            width: '20px', // Adjust width to fit the icon
          },
          transform: "translate(-10px -10px)"
        }}
      >
        <TableHeaderButton
            smallButtons
            startIcon={<SearchIcon />}
            onClick={handleMobileSearchToggle}
        />
    </Badge>
        <Grow in={searchOpen}>
            <Box width={225} zIndex={100} top={45} position="absolute">
                <Card sx={{ pb: 1, px: 1 }}>
                    <SearchTextField globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} dataCount={dataCount} />
                </Card>
            </Box>
        </Grow>
    </Box>
  );
};

export default TableSearch;
