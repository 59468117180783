import React, { useState } from "react"
import { useTranslation } from "react-i18next";

import { ResetPassword } from "@/@types/views/Auth/passwordreset";
import { IDataController } from "@/lib/DataController";

//Material-UI Core Components
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

//Custom Components
import AppPage from '@/ui/AppPage/AppPage';
import GridContainer from "@/ui/Grid/GridContainer";
import GridItem from "@/ui/Grid/GridItem";
import PasswordResetRequestForm from "@/views/core/Auth/PasswordResetRequestForm";

import model from "@/models/user";
import DataController from "@/lib/DataController";

function PasswordResetRequestPage() {

  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();

  const dc: IDataController = new DataController(model);

  const handleCompleted = (result: { success: boolean}) => {
    if (result.success) {
      setCompleted(true)
    }
  }

  return (
    <AppPage hideNavDrawer showBg titlett="page.password_reset_request">
      <Box m={2}>
        <Container maxWidth={"sm"}>
          { !completed ?
            <PasswordResetRequestForm dc={dc} form="password_reset_request" mode="update" onCompleted={handleCompleted} onClose={() => {}}/>
            : <Card>
              <CardContent>
              <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5" component="h3">
                      { t("titles.password_reset_request_success") }
                    </Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <Typography variant="body1" component="span">
                      { t("messages.password_reset_request_success") }
                    </Typography>
                  </GridItem>
                </GridContainer>

              </CardContent>
            </Card>
          }
        </Container>
      </Box>
    </AppPage>
  )

}

export default PasswordResetRequestPage;
