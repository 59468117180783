import React from 'react'
import { useTranslation } from 'react-i18next';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map'

// OL
import { transform as OlTransform } from "ol/proj";

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// MUI
import PublicIcon from '@mui/icons-material/Public';
import LaunchIcon from '@mui/icons-material/Launch';

const Show3DButton = (props: TCustomGFIActionProps) => {

    const { t } = useTranslation();

    const { featureData } = props;

    const handleShow3D = () => {
        const [cx, cy] = OlTransform(
          [featureData.position[0], featureData.position[1]],
          'EPSG:3857',
          'EPSG:4326'
        ).map((x) => (x * Math.PI) / 180);
    
        // todo add z coordinate
        const url = `/3d?cx=${cx}&cy=${cy}&cz=0`;
    
        window.open(url, '_blank');
    };

    return (
        <MapCardActionButton
            startIcon={<PublicIcon />}
            onClick={handleShow3D}
            aria-label="3d"
            disabled={false}
            needPermission={[Permissions.ThreeDView]}
        >
            {t('buttons.show_3d')}
            <LaunchIcon
            style={{ marginLeft: '7px', width: '16px', height: '16px' }}
            />
        </MapCardActionButton>
    )
}

export default Show3DButton