import React from "react"
import { FunctionComponent, useContext, useEffect, useState} from "react";
import clsx from "clsx";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//MUI
import PrintIcon from '@mui/icons-material/Print';
import { styled, useTheme } from '@mui/material/styles';

//Custom components
import MapContext from "@/context/MapContext/MapContext";
import makeMUIControlStyle from "../makeMUIControlStyle";
import ButtonControl from "../Custom/Button/ButtonControl";

//Types
import { MapContextType } from "@/context/MapContext/MapContext"
import { DialogContextType } from "@/context/DialogContext/DialogContext"
import { useTranslation } from "react-i18next";
import SnackbarContext from "@/context/SnackbarContext/SnackbarContext";
import DialogContext from "@/context/DialogContext/DialogContext";
import ExportMapDialog from "@/ui/Dialog/ExportMapDialog";
import { getOptimalTextColorForBackgroundColor } from "@/lib/colorUtilities";

export interface IPrintControl {
  id?: string
  tooltip: string
  className: string
  target?: HTMLElement
  dpi?: number
  handleClick?: () => void
}

const PrintControl: FunctionComponent<IPrintControl> = (props) => {
  const [ready, setReady] = useState(false);

  const mapContext = useContext<MapContextType | null>(MapContext);
  const snackbarContext = useContext(SnackbarContext)
  const { t } = useTranslation();
  const dialogContext = useContext<DialogContextType>(DialogContext);

  const { target, className, tooltip } = props; 

  const theme = useTheme();

  const handleDialogClose = () => {

  }

  const handleOpenDialog = () => {
    dialogContext.showDialog(ExportMapDialog, {
      onClose: handleDialogClose,
      handleDownloadMap: handleDownloadMap
    });
  };

  const handleDownloadMap = (format: string, name: string) => {
    switch(format){
      case "png":
        downloadMapImage(name)
        break;
      case "pdf": 
        handleExportPDF(name)
    }
  }

  async function handleExportPDF(filename:string) {
    const map = mapContext?.map
    if(map == null) return;

    //@ts-ignore
    const baseLayerArray = map.getLayers().getArray()[0].getLayers().getArray()
  
    const activeBaseLayer = baseLayerArray.find((layer:any) => {
      return layer.getVisible() === true
    }).get('title')
    console.log(activeBaseLayer)
    if (activeBaseLayer !== 'OSM') {
      snackbarContext.showNotification("Izvoz karte u PDF moguć je samo za OSM podlogu", "error");
      return
    }

    const image = await generateImageSection(map)

    const documentDefinition = {
      content: [image],
      pageOrientation: 'landscape',

    }

    //@ts-ignore
    pdfMake.createPdf(documentDefinition).download(filename);
  
      
  }
  
  function downloadMapImage(filename: string) {
    
    if(mapContext?.map == null) return;

     //@ts-ignore
     const baseLayerArray = mapContext.map.getLayers().getArray()[0].getLayers().getArray()
  
     const activeBaseLayer = baseLayerArray.find((layer:any) => {
       return layer.getVisible() === true
     }).get('title')
     console.log(activeBaseLayer)
     if (activeBaseLayer !== 'OSM') {
       snackbarContext.showNotification("Izvoz karte moguć je samo za OSM podlogu", "error");
       return
     }

    generateImageSection(mapContext.map, 'png')
      .then((imageDataUrl) => {
        
        const link = document.createElement('a');
        link.href = imageDataUrl as any;
        link.download = filename +'.png'; 
        link.style.display = 'none';
  
        document.body.appendChild(link);
        link.click();
  
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error generating or downloading the map image:', error);
      });
  }
 

  useEffect(() => {
    if (!ready) {
      if (mapContext && mapContext?.map !== null) {
        setReady(true);
      }
    }
  }, [mapContext?.map]);


  return ready ? (
      <ButtonControl
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        id="print-btn"
        className={clsx(className, 'ol-sidebar-control')}
        title={tooltip}
        onClick={handleOpenDialog}
        target={target}
        sx={{borderRadius: 0, backgroundColor: theme.palette.primary.light}}
      >
      <PrintIcon fontSize="small" sx={{ color: getOptimalTextColorForBackgroundColor(theme.palette.primary.light) }}/>
      </ButtonControl>
  ) : null;
};

const StyledPrintControl = styled(PrintControl)(({ theme }) => {
  const styles = makeMUIControlStyle(theme);
  return {
    ...styles.sidebarItem,
  };
});

export default StyledPrintControl;

//export default PrintControl;

function generateImageSection(map:any, format="pdf") {
  return new Promise((resolve, reject) => {
      map.once('rendercomplete', function () {
          const mapCanvas = document.createElement('canvas');
          const size = map.getSize();
          mapCanvas.width = size[0];
          mapCanvas.height = size[1];
          const map2dContext = mapCanvas.getContext('2d');
          if(map2dContext == null) return;
          Array.prototype.forEach.call(
            map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
            function (canvas) {
              if (canvas.width > 0) {
                const opacity =
                  canvas.parentNode.style.opacity || canvas.style.opacity;
                map2dContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                let matrix;
                const transform = canvas.style.transform;
                if (transform) {
                  // Get the transform parameters from the style's transform matrix
                  matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                } else {
                  matrix = [
                    parseFloat(canvas.style.width) / canvas.width,
                    0,
                    0,
                    parseFloat(canvas.style.height) / canvas.height,
                    0,
                    0,
                  ];
                }
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                  map2dContext,
                  matrix
                );
                const backgroundColor = canvas.parentNode.style.backgroundColor;
                if (backgroundColor) {
                  map2dContext.fillStyle = backgroundColor;
                  map2dContext.fillRect(0, 0, canvas.width, canvas.height);
                }
                map2dContext.drawImage(canvas, 0, 0);
              }
            }
          );
          map2dContext.globalAlpha = 1;
          map2dContext.setTransform(1, 0, 0, 1, 0, 0);
          
          if(format == "pdf"){

            const image = mapCanvas.toDataURL();
            
            const imageSection = {
              image: image,
              alignment: 'center',
              width: 842,
              //style: 'image',
            }
            
            resolve(imageSection)
          }else{
            const image = mapCanvas.toDataURL(`image/${format}`);
            resolve(image);
          }
        });
  
        map.renderSync();

  })
}
