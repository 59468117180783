import React, { useContext } from 'react';

// Custom Components
import PhotoCentricPage from '@/ui/PhotoCentric/PhotoCentricPage';
import modelParkingMjesta from '@/models/parking_mjesta';
import Permissions from '@/lib/permissions';
import UserContext from '@/context/UserContext/UserContext';
import AppPage from '@/ui/AppPage/AppPage';

type ParkingMjestaPhotoCentricPageParams = {};

const ParkingMjestaPhotoCentricPage = (props: ParkingMjestaPhotoCentricPageParams) => {
  const userContext = useContext(UserContext);

  const handleCustomAction = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    let locationId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record.lokacija_id as number;
    }
    switch (action) {
      case 'show_object':
        if (id) {
          const url = `${
            location.origin
          }/rasvjeta/mjesta/?id=${id.toString()}&open_first=true`;
          window.open(url, '_blank');
        }
        break;
      case 'show_photos':
        if (id) {
          const url = `${
            location.origin
          }/ena/photodoc/?rec_id=${id.toString()}`;
          window.open(url, '_blank');
        }
        break;
      default:
        break;
    }
  };

  const viewName = userContext?.hasAnyPermission([Permissions.ThreeDView])
    ? 'processing_with_3d'
    : 'processing';

  return (
    <AppPage titlett="ena:page.photodoc" needPermission={[Permissions.Parking]}>
      <PhotoCentricPage
        titleToken="parking:titles.parking_mjesta"
        model={modelParkingMjesta}
        mapId={32}
        onCustomTableAction={handleCustomAction}
        viewName={viewName}
      />
    </AppPage>
  );
};

export default ParkingMjestaPhotoCentricPage;
