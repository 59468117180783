import React from 'react';
import { useTranslation } from 'react-i18next';

// Custom
import OlCollection from 'ol/Collection';
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import {
  flattenLayers,
  getCallDirectLayers,
  getCallGroupedLayers,
  getOnlyGroupLayers,
} from '@/lib/olHelpers';
import { GroupLayer } from '@/components/Map/Layers';
import GeoLayer from './GeoLayer';
import GeoLayerCombined from './GeoLayerCombined';

// OL

type GeoAPILayersProps = {
  layersCollection: OlCollection<OlBaseLayer>;
  hiddenIDs?: string[];
};

const GeoAPILayers = (props: GeoAPILayersProps) => {
  const { t } = useTranslation();

  const { layersCollection, hiddenIDs } = props;

  return layersCollection ? (
    <>
      {getOnlyGroupLayers(layersCollection.getArray()).filter(x => x.get("type") !== "special").map(
        (layer: OlLayerGroup, i: number) => (
          // <GroupLayer
          //   key={`${layer.get('id')}-${i}`}
          //   id={layer.get('id')}
          //   title={t(layer.get('title'))}
          // >
            <>
              {getCallDirectLayers(layer.getLayers().getArray()).filter(x => x.get("type") !== "special").map(

                (cl: OlBaseLayer, j: number) => {;
                  const visibility = hiddenIDs === undefined ? undefined : hiddenIDs.includes(cl.get('id'));
                  return <GeoLayer key={j} id={cl.get('id')} layer={cl} visible={visibility}/>
                }
              )}
              {getCallGroupedLayers(layer.getLayers().getArray()).map(
                (cgl, k: number) => {
                  const visibility = hiddenIDs === undefined ? undefined : hiddenIDs.includes(cgl.call_group);
                  return <GeoLayerCombined
                    key={k}
                    id={cgl.call_group}
                    layersCollection={new OlCollection(cgl.layers)}
                    visible={visibility}
                  />
                }
              )}
            </>
          // </GroupLayer>
        )
      )}
      {getCallDirectLayers(layersCollection.getArray()).filter(x => x.get("type") !== "special").map(
        (l: OlBaseLayer, x: number) => {
          const visibility = hiddenIDs === undefined ? undefined :hiddenIDs.includes(l.get('id'));
          return <GeoLayer key={x} id={l.get('id')} layer={l} visible={visibility}/>
        }
      )}
      {getCallGroupedLayers(layersCollection.getArray()).map(
        (gl, y: number) =>{
          const visibility = hiddenIDs === undefined ? undefined : hiddenIDs.includes(gl.call_group);
          return <GeoLayerCombined
            visible={visibility}
            key={y}
            id={gl.call_group}
            layersCollection={new OlCollection(gl.layers)}
          />
        } 
      )}
    </>
  ) : null;
};

export default GeoAPILayers;
