import { FunctionComponent, useContext, useEffect } from 'react';

//OpenLayers
import { DragRotate } from 'ol/interaction';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { registerOlInteraction } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';

const DragRotateInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    condition: undefined,
    duration: undefined,
  };

  const events = {
    change: undefined,
    'change:active': undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(
    () => registerOlInteraction(context, DragRotate, props, options, events),
    []
  );

  return null;
};

export default DragRotateInteraction;
