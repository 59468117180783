import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buffer } from 'ol/extent';

// MUI
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

// Custom
import { GridContainer, GridItem } from '@/ui/Grid';
import imgService from '@/services/imgService';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IImageData } from '@/@types/views/KomunalniObveznici';
import { AspectRatio } from '@mui/icons-material';

interface IEnaDataCentricImagesOverlayProps {
  selectedObjektId: number;
  closeOverlay: () => void;
  imgData: IImageData | null;
  objExtent: number[];

  baseRecordPath: string;
}

// const imgs = [img1, img2];

const EnaDataCentricImagesOverlay = (
  props: IEnaDataCentricImagesOverlayProps
) => {
  const { selectedObjektId, closeOverlay, imgData, objExtent } = props;
  const { baseRecordPath } = props;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const [img1, setImg1] = useState<DCRecord | null>(null);
  const [img2, setImg2] = useState<DCRecord | null>(null);
  const [img3, setImg3] = useState<DCRecord | null>(null);
  const { t } = useTranslation();

  //   const baseRecordPath = "rasvjeta/rasvjetna-mjesta"

  let mounted = false;

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedObjektId) {
      setImg1(null);
      imgService
        .getImage(baseRecordPath, selectedObjektId, 1, 800)
        .then((resp) => {
          if (resp.success && resp.data) {
            // if (mounted) {
            setImg1(resp.data as DCRecord);
            // }
          }
        });
      setImg2(null);
      imgService
        .getImage(baseRecordPath, selectedObjektId, 2, 800)
        .then((resp) => {
          if (resp.success && resp.data) {
            // if (mounted) {
            setImg2(resp.data as DCRecord);
            // }
          }
        });
    }
  }, [selectedObjektId]);

  useEffect(() => {
    if (objExtent && objExtent.length === 4) {
      setImg3(null);
      const bufferValue =
        Math.max(objExtent[3] - objExtent[1], objExtent[2] - objExtent[0]) *
        0.9;
      imgService
        .getImage(
          baseRecordPath,
          selectedObjektId,
          3,
          400,
          buffer(objExtent, bufferValue).join(',')
        )
        .then((resp) => {
          if (resp.success && resp.data) {
            // if (mounted) {
            setImg3(resp.data as DCRecord);
            // }
          }
        });
    }
  }, [objExtent]);

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        backgroundColor: '#fafafa',
        overflow: 'auto',
        zIndex: 2000,
      }}
    >
      <IconButton
        style={{ position: 'absolute', top: 1, right: 1 }}
        color="primary"
        onClick={() => closeOverlay()}
      >
        <CloseIcon />
      </IconButton>
      <GridContainer sx={{width: "100%", height: "100%"}}>
        {[img1, img2, img3].map((img, i) => (
          <GridItem key={`object_image_${i + 1}`} xs={12} md={6} lg={4} display={"flex"} justifyContent={"center"} height={"100%"} sx={{overflowX: "hidden"}}>
            <Box display="flex" flexDirection="column" alignItems={mdDown ? "center" : "flex-start"} width={"100%"} height={"100%"}>
              <Typography mb={1}>
                {i === 0
                  ? `${t('ena:objekti.id')}: ${selectedObjektId % 1000000}`
                  : i === 1
                  ? `${t('ena:objekti.broj_katova')}: ${
                      imgData && imgData[selectedObjektId]
                        ? imgData[selectedObjektId].kat + 1
                        : ''
                    }`
                  : i === 2
                  ? `${t('ena:objekti.ukupno')}: ${
                      imgData && imgData[selectedObjektId]
                        ? imgData[selectedObjektId].ukupno
                        : ''
                    }`
                  : ''}
              </Typography>
              {img && img.image ? (
                <img
                  src={`data:image/jpeg;charset=utf-8;base64,${
                    img.image as string
                  }`}
                  alt={`object_image_${i + 1}`}
                  style={{
                    maxHeight: "calc( 100% - 32px )", // -16px cus of grid doing wierd things with 16px padding and margin
                    maxWidth: "calc( 100% - 32x )",
                  }}
                />
              ) : (
                <Skeleton
                sx={{aspectRatio: "1/1"}}
                  animation="wave"
                  variant="rectangular"
                  height={"100%"}
                />
              )}
            </Box>
          </GridItem>
        ))}
      </GridContainer>
    </Box>
  );
};

export default EnaDataCentricImagesOverlay;
