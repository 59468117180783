import * as React from 'react';
import { FunctionComponent, ReactNode, useState, useContext } from 'react';

// Material UI
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';

// Custom components
import ThemeContext from '@/context/ThemeContext/ThemeContext';
import TenantContext from '@/context/TenantContext/TenantContext';

// Types
import { Theme } from '@/context/ThemeContext/ThemeContext';
export interface IThemeProvider {
  children: ReactNode;
}

// it could be your App.tsx file or theme file that is included in your tsconfig.json
// import { Theme as MuiTheme } from '@mui/material/styles';

// // ako iz nekog razloga vratimo paket @mui/styles, mozda ce trebati ovo ponovno otkomentirati
// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends MuiTheme {}
// }


export const appHeaderTheme = createTheme({
  palette: {
    secondary: {
      main: grey[800],
    },
  },
});

const ThemeProvider: FunctionComponent<IThemeProvider> = (props) => {
  const tenantContext = useContext(TenantContext);
  const tenantTheme = tenantContext?.tenantTheme

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: tenantTheme?.primary_main || '#b7c800',
        dark: tenantTheme?.primary_dark || '#808c00',
        light: tenantTheme?.primary_light || '#c6d147',
      },
      secondary: {
        main: tenantTheme?.secondary_main || '#076BAD',
        dark: tenantTheme?.secondary_dark || '#044a79',
        light: tenantTheme?.secondary_light || '#83b5d6',
      },
      background: {
        default: '#fafafa',
        paper: '#fafafa',
      }
    },
    typography: {
      fontFamily: 'Trebuchet MS, sans-serif'
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color',
            },
          },
        },
      },
    },
  });

  const lightThemeWithOverrides = createTheme({
    ...lightTheme,
    components: {
      ...lightTheme.components,
      MuiAlert: {
        styleOverrides: {
          filledInfo: {
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: lightTheme.palette.primary.dark,
            backgroundColor: lightTheme.palette.background.paper,
            color: lightTheme.palette.text.primary,
          },
        },
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: lightTheme.palette.primary,
      secondary: lightTheme.palette.secondary,
      background: {
        default: '#424242',
        paper: '#424242',
      },
    },
    components: lightTheme.components,
  });

  const darkThemeWithOverrides = createTheme({
    ...darkTheme,
    components: {
      ...darkTheme.components,
      MuiAlert: {
        styleOverrides: {
          filledInfo: {
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: lightTheme.palette.primary.dark,
            backgroundColor: darkTheme.palette.background.paper,
            color: darkTheme.palette.text.primary,
          },
        },
      },
    },
  });

  const THEME_LIGHT = 'light';
  const THEME_DARK = 'dark';

  const [theme, setTheme] = useState<Theme>(THEME_LIGHT);

  
  const switchTheme = (newTheme: Theme): void => {
    setTheme(newTheme);
  };

  const getMuiTheme = () => {
    if (theme === THEME_LIGHT) {
      return lightThemeWithOverrides;
    }
    if (theme === THEME_DARK) {
      return darkThemeWithOverrides;
    }
    return lightThemeWithOverrides;
  };

  const muiTheme = getMuiTheme();

  const providerValue = {
    theme,
    setTheme: switchTheme,
  };

  return (
    <ThemeContext.Provider value={providerValue}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
