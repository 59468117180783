import React, { ReactComponentElement, ReactElement, ReactNode } from 'react';
import moment from 'moment';

// Custom Components
import PickerControl from '@/controls/PickerControl';
import TextControl from '@/controls/TextControl';
import MultiLineControl from '@/controls/MultiLineControl';
import PasswordControl from '@/controls/PasswordControl';
import WholeNumberControl from '@/controls/WholeNumberControl';
import FloatNumberControl from '@/controls/FloatNumberControl';
import DateTimeControl from '@/controls/DateTimeControl';
import BoolControl from '@/controls/BoolControl';
import CurrencyControl from '@/controls/CurrencyControl';
import CheckboxControl from '@/controls/CheckboxControl';
import RadioControl from '@/controls/RadioControl';
// import FileControl from "@/controls/FileControl";
import LinkControl from '@/controls/LinkControl';
import ViewControl from '@/controls/ViewControl';
import FileControl from '@/controls/FileControl';

// Types
import {
  ChangeHandlerFunction,
  ChangeHandlerFunctionT,
  FormFieldControls,
} from '@/@types/lib/formGenerator';
import {
  FieldAny,
  IFieldCurrency,
  IFieldDate,
  IFieldDateTime,
  IFieldFile,
  IFieldLink,
  IFieldMail,
  IFieldMultiline,
  IFieldNumberWhole,
  IFieldNumeric,
  IFieldPassword,
  IFieldPicker,
  IFieldMultiPicker,
  IFieldPickerItems,
  IFieldsCollection,
  IFieldText,
  PickerItemValue,
  IFieldColor,
} from '@/@types/models/model';
import { DCRecord, DCFile } from '@/@types/lib/dataController';
import { FieldValidation, RecordValidation } from '@/@types/lib/appValidator';
import { PickerDataControllers } from '@/@types/components/formController';
import {
  ControlModeType,
  FormModeType,
  PickerItem,
} from '@/@types/controls/controls';
import { ColorControl } from '@/controls';
import { useTranslation } from 'react-i18next';

export interface IFormGenerator {
  generateFormControls: (
    fields: IFieldsCollection,
    formMode: FormModeType,
    controlMode: ControlModeType,
    record: DCRecord,
    validation?: RecordValidation,
    onChangeHandler?: ChangeHandlerFunction,
    pickerDataControllers?: PickerDataControllers
  ) => FormFieldControls;
}

class FormGenerator implements IFormGenerator {
  generateFormControls(
    fields: IFieldsCollection,
    formMode: FormModeType,
    controlMode: ControlModeType,
    record: DCRecord,
    validation?: RecordValidation,
    onChangeHandler?: ChangeHandlerFunction,
    pickerDataControllers?: PickerDataControllers
  ) {
    const rec: FormFieldControls = {};
    if (fields) {
      fields.forEach((field) => {
        if (field) {
          const defaultValue =
            record &&
            field &&
            field.source &&
            record.hasOwnProperty(field.source)
              ? record[field.source]
              : null;
          const fieldValidation =
            validation && validation.hasOwnProperty(field.source)
              ? validation[field.source]
              : undefined;
          const control = this.generateFieldControl(
            field,
            formMode,
            controlMode,
            record,
            defaultValue,
            fieldValidation,
            pickerDataControllers,
            onChangeHandler
          );

          rec[field.source] = control;
        }
      });
    }
    return rec;
  }

  generateFieldControl(
    field: FieldAny,
    formMode: FormModeType,
    controlMode: ControlModeType,
    record: DCRecord,
    value: any,
    validation: FieldValidation | undefined,
    pickerDataControllers?: PickerDataControllers,
    onChangeHandler?: ChangeHandlerFunction
  ): ReactElement {
    let model;
    if (field && field.hasOwnProperty('subModel') && pickerDataControllers) {
      model = pickerDataControllers[field.source];
    }

    const commonProps = {
      formMode,
      controlMode,
      validation,
      // onChange: onChangeHandler
    };

    if (controlMode === 'simpleView') {
      return (
        <ViewControl
          field={field as FieldAny}
          dc={model}
          record={record}
          value={value}
        />
      );
    }

    const { t } = useTranslation();

    switch (field.type) {
      case 'numeric':
        return (
          <FloatNumberControl
            field={field as IFieldNumeric}
            value={value as string | number | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<string | number | null>
            }
            {...commonProps}
          />
        );
      case 'wholenum':
        return (
          <WholeNumberControl
            field={field as IFieldNumberWhole}
            value={value as string | number | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<string | number | null>
            }
            {...commonProps}
          />
        );
      case 'date':
        return (
          <DateTimeControl
            field={field as IFieldDate}
            value={value as moment.Moment | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<moment.Moment | null>
            }
            {...commonProps}
            hasTime={false}
          />
        );
      case 'datetime':
        return (
          <DateTimeControl
            field={field as IFieldDateTime}
            value={value as moment.Moment | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<moment.Moment | null>
            }
            {...commonProps}
            hasTime={true}
          />
        );
      case 'picker':
        return (
          <PickerControl
            field={field as IFieldPicker}
            value={value as PickerItem | PickerItemValue | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                PickerItem | PickerItemValue | null
              >
            }
            {...commonProps}
            isLoading={!pickerDataControllers}
            dc={model}
          />
        );
      // case "multipicker":
      //   return (
      //     <PickerControl
      //     field={field as IFieldMultiPicker}
      //     value={value as PickerItem[] | PickerItemValue[] | null}
      //     onChange={onChangeHandler as ChangeHandlerFunctionT<PickerItem[] | null>}
      //     {...commonProps}
      //     isLoading={subModels ? false : true}
      //     dc={model}
      //     />
      //   );
      case 'multiline':
        return (
          <MultiLineControl
            field={field as IFieldMultiline}
            value={value as string}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string>}
            {...commonProps}
          />
        );
      case 'currency':
        return (
          <CurrencyControl
            field={field as IFieldCurrency}
            value={value as string | number | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<string | number | null>
            }
            {...commonProps}
          />
        );
      case 'boolean':
        return (
          <BoolControl
            field={field as IFieldPickerItems}
            value={value as PickerItemValue | PickerItem | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                PickerItemValue | PickerItem | null
              >
            }
            {...commonProps}
          />
        );
      case 'password':
        return (
          <PasswordControl
            field={field as IFieldPassword}
            value={value as string}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string>}
            {...commonProps}
          />
        );
      case 'checkbox':
        return (
          <CheckboxControl
            field={field as IFieldPicker}
            value={
              value as
                | PickerItem
                | PickerItemValue
                | Array<PickerItem>
                | Array<PickerItemValue>
                | null
            }
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                | PickerItem
                | PickerItemValue
                | Array<PickerItem>
                | Array<PickerItemValue>
                | null
              >
            }
            {...commonProps}
            dc={model}
          />
        );
      case 'radio':
        return (
          <RadioControl
            field={field as IFieldPicker}
            value={value as PickerItem | PickerItemValue | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                PickerItem | PickerItemValue | null
              >
            }
            {...commonProps}
            dc={model}
          />
        );
      case 'images':
        //case ('cmsimages'):
        return (
          <FileControl
            title={field.ttoken ? `${t(field.ttoken)}` : undefined}
            field={field as IFieldFile}
            value={value as DCFile | Array<DCFile> | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                DCFile | Array<DCFile> | null
              >
            }
            accept={'images'}
            {...commonProps}
          />
        );
      case 'geojson':
        return (
          <FileControl
            field={field as IFieldFile}
            value={value as DCFile | Array<DCFile> | null}
            onChange={
              onChangeHandler as ChangeHandlerFunctionT<
                DCFile | Array<DCFile> | null
              >
            }
            accept={"json"}
            {...commonProps}
          />
        );
      // case "dokumenti":
      //   return (
      //     <FileControl
      //       field={field as IFieldFile}
      //       value={value as DCFile | Array<DCFile> | null}
      //       onChange={onChangeHandler as ChangeHandlerFunctionT<DCFile | Array<DCFile> | null>}
      //       accept={"*"}
      //       {...commonProps}
      //     />
      //   );
      case 'mail':
        return (
          <LinkControl
            field={field as IFieldMail}
            value={value as string | null}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string | null>}
            {...commonProps}
            linkType={'mail'}
          />
        );
      case 'link':
        return (
          <LinkControl
            field={field as IFieldLink}
            value={value as string | null}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string | null>}
            {...commonProps}
            linkType={field.link.recordType}
            linkId={record[field.link.recordIdField] as number}
          />
        );
      case 'color':
        return (
          <ColorControl
            field={field as IFieldColor}
            value={value as string}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string>}
            {...commonProps}
          />
        );
      case 'text':
      default:
        return (
          <TextControl
            field={field as IFieldText}
            value={value as string}
            onChange={onChangeHandler as ChangeHandlerFunctionT<string>}
            {...commonProps}
          />
        );
    }
  }
}

export default FormGenerator;
