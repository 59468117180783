// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cesiumContainer {
  height: 100%;
}

.cesium-credit-textContainer {
  display: none !important;
}

.cesium-credit-expand-link {
  display: none !important; 
}

.cesium-button:hover {
  box-shadow: unset;
}

.cesium-viewer-fullscreenContainer{
  bottom: 5px;
  right: 5px; 
  border-radius: 14%;
}`, "",{"version":3,"sources":["webpack://./src/styles/cesium.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;AACpB","sourcesContent":["#cesiumContainer {\n  height: 100%;\n}\n\n.cesium-credit-textContainer {\n  display: none !important;\n}\n\n.cesium-credit-expand-link {\n  display: none !important; \n}\n\n.cesium-button:hover {\n  box-shadow: unset;\n}\n\n.cesium-viewer-fullscreenContainer{\n  bottom: 5px;\n  right: 5px; \n  border-radius: 14%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
