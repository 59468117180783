import { TFunction } from 'i18next';
import moment from 'moment';
import numbro from 'numbro';
import {
  FieldAny,
  IField,
  IFieldsCollection,
  IFieldValidationDateTime,
  IFieldValidationNumeric,
  IFieldValidationText,
  IFieldValidationWKT,
} from '../@types/models/model';
import { FieldValue, FieldValuePicker, Record } from '../@types/lib/api';
import { FieldValidation, RecordValidation } from '../@types/lib/appValidator';

import WKT from 'ol/format/WKT';
import { Feature} from 'ol';
import { Geometry } from 'ol/geom';
import { error } from 'console';
import { GeometryType } from '@/@types/common';

class AppValidator {
  t: TFunction;

  constructor(t: TFunction) {
    this.t = t;

    this.validateModel = this.validateModel.bind(this);
    this.validateField = this.validateField.bind(this);
    this.getValue = this.getValue.bind(this);
    this.mergeValidation = this.mergeValidation.bind(this);
    this.checkIfValid = this.checkIfValid.bind(this);
    this.checkIfRecordChanged = this.checkIfRecordChanged.bind(this);
    this.checkIfFieldChanged = this.checkIfFieldChanged.bind(this);

    // Custom validators
    this.checkRegexEmail = this.checkRegexEmail.bind(this);
    this.checkRegexTel = this.checkRegexTel.bind(this);
    this.checkRegexOIB = this.checkRegexOIB.bind(this);
    this.isComplexPassword = this.isComplexPassword.bind(this);
    this.validatePasswordReset = this.validatePasswordReset.bind(this);
    this.validatePasswordChange = this.validatePasswordChange.bind(this);
    this.validatePasswordSet = this.validatePasswordSet.bind(this);

    // Validation messages
    this.msgRequired = this.msgRequired.bind(this);
    this.subSectionRequired = this.subSectionRequired.bind(this);
    this.existingUsername = this.existingUsername.bind(this);
  }

  validateModel(record: Record, fields: IFieldsCollection): RecordValidation {
    const validation: RecordValidation = {};
    fields.forEach((field) => {
      validation[field.source] = this.validateField(record, field);
    });
    return validation;
  }

  validateField(record: Record, field: FieldAny): FieldValidation {
    const fieldValidation: FieldValidation = { valid: true, msg: '' };
    const isRequired = field.validation && field.validation.required;
    const isEmail =
      field.validation &&
      field.validation.hasOwnProperty('regex') &&
      (field.validation as IFieldValidationText).regex === 'email';
    const isTelefon =
      field.validation &&
      field.validation.hasOwnProperty('regex') &&
      (field.validation as IFieldValidationText).regex === 'tel';
    const isOIB =
      field.validation &&
      field.validation.hasOwnProperty('regex') &&
      (field.validation as IFieldValidationText).regex === 'oib';
    const isColor =
      field.validation &&
      field.validation.hasOwnProperty('regex') &&
      (field.validation as IFieldValidationText).regex === 'color';
    const hasMaxDate =
      field.type === 'date' &&
      field.validation &&
      field.validation.hasOwnProperty('maxDate');
    const isDateAfter =
      (field.type === 'date' || field.type === 'datetime') &&
      field.validation &&
      field.validation.hasOwnProperty('isAfter');
    const hasCharLimit =
      field.validation && field.validation.hasOwnProperty('maxLength');
    const maxLength = hasCharLimit
      ? (field.validation as IFieldValidationText).maxLength
      : null;
    const hasMinCharsRequired =
      field.validation && field.validation.hasOwnProperty('minLength');
    const value =
      record && record.hasOwnProperty(field.source)
        ? record[field.source]
        : null;
    const minLength = hasMinCharsRequired
      ? (field.validation as IFieldValidationText).minLength
      : null;
    let hasValue = Array.isArray(value) ? value.length !== 0 :  (value !== undefined && value !== null && value !== '');
    if (Array.isArray(value) && value.length === 0) {
      hasValue = false;
    }

    const hasMinValue =
      field.validation && field.validation.hasOwnProperty('minValue');
    const minValue = hasMinValue
      ? (field.validation as IFieldValidationNumeric).minValue
      : null;
    const hasMaxValue =
      field.validation && field.validation.hasOwnProperty('maxValue');
    const maxValue = hasMaxValue
      ? (field.validation as IFieldValidationNumeric).maxValue
      : null;

    const hasWktTypes = field.validation && field.validation.hasOwnProperty('wktType');

    const wktTypes = hasWktTypes 
      ? (field.validation as IFieldValidationWKT).wktType 
      : null;

    if (isRequired && !hasValue) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t('validation.required');
    } else if (
      isEmail &&
      hasValue &&
      this.checkRegexEmail(value as string) === false
    ) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t('validation.emailformat');
    } else if (
      isTelefon &&
      hasValue &&
      this.checkRegexTel(value as string) === false
    ) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t('validation.telformat');
    } else if (
      isOIB &&
      hasValue &&
      this.checkRegexOIB(value as string) === false
    ) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t('validation.oibformat');
    } else if (
      isColor &&
      hasValue &&
      this.checkRegexColor(value as string) === false
    ) {
      fieldValidation.valid = false;
      fieldValidation.msg = this.t('validation.colorformat');
    } else if (hasCharLimit) {
      if (hasValue && maxLength !== null && maxLength !== undefined) {
        if ((value as string).length > maxLength) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t('validation.max_char', {
            max_char: maxLength.toString(),
            curr_char: (value as string).length,
          });
        }
      }
    } else if (hasMinCharsRequired) {
      if (hasValue && minLength !== null && minLength !== undefined) {
        if ((value as string).length < minLength) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t('validation.min_char', {
            min_char: minLength.toString(),
            curr_char: (value as string).length,
          });
        }
      }
    } else if ((hasMinValue || hasMaxValue) && hasValue) {
      const floatValue = (
        typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value
      ) as number;
      if (
        hasMinValue &&
        minValue !== null &&
        minValue !== undefined &&
        floatValue !== null
      ) {
        if (floatValue < minValue) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t('validation.min_value', {
            min_value: minValue.toString(),
          });
        }
      }
      if (
        hasMaxValue &&
        maxValue !== null &&
        maxValue !== undefined &&
        floatValue !== null
      ) {
        if (floatValue > maxValue) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t('validation.max_value', {
            max_value: maxValue.toString(),
          });
        }
      }
    } else if (hasMaxDate) {
      const maxDate = (field.validation as IFieldValidationDateTime).maxDate;
      if (maxDate !== undefined) {
        if (
          moment(value as string).isAfter(
            moment(new Date()).add(maxDate, 'days'),
            'day'
          )
        ) {
          fieldValidation.valid = false;
          fieldValidation.msg = this.t('validation.max_date', {
            date: moment(new Date()).add(maxDate, 'days').format('DD.MM.YYYY.'),
          });
        }
      }
    } else if (isDateAfter && hasValue) {
      const isAfterDefinition = (field.validation as IFieldValidationDateTime)
        .isAfter;
      if (isAfterDefinition !== undefined) {
        const otherFieldId = isAfterDefinition.source;
        const otherValue = record[otherFieldId];
        const otherHasValue =
          otherValue !== undefined && otherValue !== null && otherValue !== '';
        const oherFieldName = this.t(isAfterDefinition.ttoken);
        if (otherHasValue) {
          if (!moment(value as string).isAfter(moment(otherValue as string))) {
            fieldValidation.valid = false;
            fieldValidation.msg = this.t('validation.isafter_date', {
              field: oherFieldName,
            });
          }
        }
      }
    } 
    if (hasWktTypes && wktTypes && wktTypes?.length>0) {
      try {
        if (!wktTypes.includes('Optional')) {
          console.log(wktTypes)
          const formatWKT = new WKT();

          const geometryFromWKT = formatWKT.readFeature(value as string);
          const geometryType = geometryFromWKT.getGeometry()?.getType().toString();

          if (!geometryType) { // No geometry
            console.log(geometryFromWKT.getGeometry(), geometryType);
            console.log("// No geometry")
            fieldValidation.valid = false; 
            fieldValidation.msg = this.t('validation.invalid_wkt' ); 
          }
        
          const wktTypeFound =  wktTypes.includes(geometryType as GeometryType);

          if (!geometryType) { // no such type found
            console.log("// no such type found",wktTypeFound)
            fieldValidation.valid = false; 
            fieldValidation.msg = this.t('validation.invalid_wkt' ); 
          }
        }
      } catch (error) { // Invalid wkt format
        console.log("Invalid wkt format")
        fieldValidation.valid = false;
        fieldValidation.msg = this.t('validation.invalid_wkt' );
      }
    }

    return fieldValidation;
  }

  mergeValidation(
    modelValidation: RecordValidation,
    customValidation: RecordValidation
  ): RecordValidation {
    let mergedValidation = modelValidation || {};
    if (customValidation !== undefined && customValidation !== null) {
      Object.keys(customValidation).forEach((key) => {
        mergedValidation[key] = customValidation[key];
        // if (mergedValidation.hasOwnProperty(key)) {
        //   mergedValidation[key] = customValidation[key];
        // } else {
        //   mergedValidation.push(key, customValidation[key]);
        // }
      });
    }
    return mergedValidation;
  }

  checkIfValid(validation: RecordValidation): boolean {
    const validatonFailed = Object.keys(validation)
      .map((key) => validation[key].valid)
      .some((x) => x === false);
    const isValid = !validatonFailed;
    return isValid;
  }

  checkIfRecordChanged(record: Record, originalRecord: Record): boolean {
    const isChanged = Object.keys(record).some((key) =>
      this.checkIfFieldChanged(record[key], originalRecord[key])
    );
    return isChanged;
  }

  checkIfFieldChanged(a: FieldValue, b: FieldValue): boolean {
    if (a === null && b === null) {
      return false;
    }
    if (moment.isMoment(a) && moment.isMoment(b)) {
      if (!a.isValid() && !b.isValid()) {
        return false;
      }
      return !a.isSame(b);
    }
    return this.getValue(a) !== this.getValue(b);
  }

  getValue(x: FieldValue) {
    if (x === null || x === undefined) {
      return null;
    }
    if (moment.isMoment(x)) {
      return x.isValid() ? x : null;
    }
    if (numbro.isNumbro(x)) {
      return x.value();
    }
    if (x.hasOwnProperty('value')) {
      return (x as FieldValuePicker).value; // option
    }
    return x; // other
  }

  returnFormValue(record: Record, k: string) {
    const key = k as keyof Record;
    if (record !== null && record.hasOwnProperty(key)) {
      const val = record[key];
      if (val !== null && val !== undefined) {
        if (typeof val === 'object' && val.hasOwnProperty('value')) {
          return (val as FieldValuePicker).value;
        }
        return val;
      }
    }
    return null;
  }

  // Validation messages
  msgRequired(): FieldValidation {
    return {
      valid: false,
      msg: this.t('validation.required'),
    };
  }

  subSectionRequired(): FieldValidation {
    return {
      valid: false,
      msg: this.t('validation.subSection_required'),
    };
  }

  existingUsername(): FieldValidation {
    return {
      valid: false,
      msg: this.t('validation.existing_username'),
    };
  }

  // Custom Validators

  checkRegexEmail(value: string): boolean {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const matches = value.match(re);
    return matches != null;
  }

  checkRegexTel(value: string): boolean {
    const re = /^\+[0-9]{11,15}$/;
    const matches = value.match(re);
    return matches != null;
  }

  checkRegexOIB(value: string): boolean {
    const re = /^[0-9]{11}$/;
    const matches = value.match(re);
    return matches != null;
  }

  checkRegexColor(value: string): boolean {
    const re = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    const matches = value.match(re);
    return matches != null;
  }

  isComplexPassword(password: string): boolean {
    if (password.length < 8) {
      return false;
    }

    const matchedCase = [];
    matchedCase.push('[A-Z]'); // Uppercase Letters
    matchedCase.push('[0-9]'); // Numbers
    matchedCase.push('[a-z]'); // Lowercase Letters

    let ctr = 0;
    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }

    return ctr === 3;
  }

  validatePasswordReset(record: Record): RecordValidation {
    const fNewPass = 'password_new';
    const fNewPassConfirm = 'password_new_confirm';

    const newPass =
      record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
      record && record.hasOwnProperty(fNewPassConfirm)
        ? record[fNewPassConfirm]
        : null;

    const validation: RecordValidation = {};

    if (
      newPass &&
      typeof newPass === 'string' &&
      newPass.length > 0 &&
      this.isComplexPassword(newPass) === false
    ) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t('validation.requirementPassword'),
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: this.t('validation.paswordMismatch'),
      };
    }

    return validation;
  }

  validatePasswordChange(record: Record): RecordValidation {
    const fOldPass = 'password';
    const fNewPass = 'password_new';
    const fNewPassConfirm = 'password_new_confirm';

    const oldPass =
      record && record.hasOwnProperty(fOldPass) ? record[fOldPass] : null;
    const newPass =
      record && record.hasOwnProperty(fNewPass) ? record[fNewPass] : null;
    const newPassConfirm =
      record && record.hasOwnProperty(fNewPassConfirm)
        ? record[fNewPassConfirm]
        : null;

    const validation: RecordValidation = {};
    if (
      newPass &&
      typeof newPass === 'string' &&
      newPass.length > 0 &&
      this.isComplexPassword(newPass) === false
    ) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t('validation.requirementPassword'),
      };
    }

    if (
      newPass &&
      typeof newPass === 'string' &&
      newPass.length > 0 &&
      oldPass === newPass
    ) {
      validation[fNewPass] = {
        valid: false,
        msg: this.t('validation.sameNewPassword'),
      };
    }

    if (newPass !== newPassConfirm) {
      validation[fNewPassConfirm] = {
        valid: false,
        msg: this.t('validation.paswordMismatch'),
      };
    }

    return validation;
  }

  validatePasswordSet(record: Record, t: TFunction): RecordValidation {
    const fPass = 'password';
    const fPassConfirm = 'password_confirm' as keyof Record;

    const pass = record && record.hasOwnProperty(fPass) ? record[fPass] : null;
    const passConfirm =
      record && record.hasOwnProperty(fPassConfirm)
        ? record[fPassConfirm]
        : null;

    const validation: RecordValidation = {};

    if (
      pass &&
      typeof pass === 'string' &&
      pass.length > 0 &&
      this.isComplexPassword(pass) === false
    ) {
      validation[fPass] = {
        valid: false,
        // msg: "Lozinka treba sadržavati barem 8 znakova, 1 malo slovo, 1 veliko slovo i 1 znamenku"
        msg: this.t('validation.requirementPassword'),
      };
    }

    if (pass !== passConfirm) {
      validation[fPassConfirm] = {
        valid: false,
        // msg: "Potvrda lozinke se ne podudara s novom lozinkom!"
        msg: this.t('validation.passwordMismatch'),
      };
    }

    return validation;
  }
}

export default AppValidator;
