import * as React from 'react';
import { FC } from 'react';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnFiltersState,
  FilterFn,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getExpandedRowModel,
  Row,
  ColumnDef,
} from '@tanstack/react-table';

// Material UI Core Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, IconButton, SxProps, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MaterialUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// MUI Icons
import SearchIcon from '@mui/icons-material/Search';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';

// Custom Components
import { useTranslation } from 'react-i18next';
import TableHeading from '@/ui/Table/TableHeading';
import TableHeaderButton from './HeaderButtons/TableHeaderButton';
import TableHeaderButtonRefresh from '@/ui/Table/HeaderButtons/TableHeaderButtonRefresh';
import TableHeaderButtonColumnPicker from '@/ui/Table/HeaderButtons/TableHeaderButtonColumnPicker';
import TableHeaderButtonExport from '@/ui/Table/HeaderButtons/TableHeaderButtonExport';
import TableHeaderButtonFilter from '@/ui/Table/HeaderButtons/TableHeaderButtonFilter';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import ThemeContext from '@/context/ThemeContext/ThemeContext';
import UserContext from '@/context/UserContext/UserContext';

// Types
import {
  IFilterValue,
  ITableBaseProps,
  RowClickFn,
} from '@/@types/ui/Table';
import { DCRecord } from '@/@types/lib/dataController';
import TableHeaderButtonLegend from './HeaderButtons/TableHeaderButtonLegend';
import TableSearch from './TableSearch';

interface IFilterState {
  [key: string]: IFilterValue;
}

interface ISort {
  id: string;
  desc: boolean;
}

export interface ITableProps extends ITableBaseProps {
  columns: ColumnDef<any, any>[];
  data: DCRecord[]; // TODO check this later @dinog
  hiddenColumnNames: Array<string>;
  onRefresh?: () => void;
  allowSearch?: boolean;
  identifier: string;
  unselectRow?: boolean;
}

const Table: FC<ITableProps> = (props) => {
  const {
    data,
    columns,
    hiddenColumnNames,
    children,
    onRefresh,
    allowColumnPicker,
    allowSelection,
    allowFilter,
    allowExport,
    allowAdd,
    allowSearch,
    allowLegend,
    addLabel,
    handleAdd,
    dc,
    tableName,
    title,
    identifier,
    onRowClick,
    rowSelectedCustom,
    unselectRow = true,
    onDoubleClick,
    onLegendClick,
    getRowStyle,
    size,
    defaultPageSize,
    smallButtons,
    disableControls,
  } = props;

  const [filters, setFiltersToggle] = React.useState<IFilterState | null>(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [showFilters, setShowFilters] = React.useState(false);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [dataCount, setDataCount] = React.useState<number>(data.length);

  const firstCellRef = React.useRef<HTMLTableCellElement | null>(null);
  const [firstRowHeight, setFirstRowHeight] = React.useState(0);

  React.useEffect(() => {
    if (firstCellRef.current) {
      setFirstRowHeight(firstCellRef.current.clientHeight+1);
    }
  }, [firstCellRef.current]);

  const { t } = useTranslation();
  const theme = useTheme();
  const themeContext = React.useContext(ThemeContext);
  const userContext = React.useContext(UserContext);

  /**
   * Checks if row satisfies the text filter
   *
   * @param row - The row
   * @param columnId - The column id
   * @returns True if row is satisfying the filter or False if not
   */
  const globalFilterFunction: FilterFn<DCRecord> = (row, columnId, value) => {
    const field = dc.getField(columnId);
    const rowValue = row.getValue(columnId);

    if (rowValue === 'null') return false; // Sometimes this happens, dont know why

    if (field && field.type) {
      const { type } = field;
      if (type === 'button' || !rowValue) {
        return false;
      }
    }

    return String(rowValue).toLowerCase().includes(String(value).toLowerCase());
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    getSubRows: (row) => row.subRows,
    enableRowSelection: allowSelection !== 'none',
    enableMultiRowSelection: allowSelection === 'many',
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: globalFilterFunction,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 100,
    enableExpanding: true,
  });

  const tableContainsSubRow = table.getCanSomeRowsExpand();
  
  const selectedRecords = React.useMemo(() => {
    const records: { [key: string]: DCRecord } = {};
    Object.keys(rowSelection).forEach((rowId) => {
      const row = table.getRowModel().rows.find((row) => row.id === rowId);
      if (row) {
        records[rowId] = row.original as DCRecord;
      }
    });
    return records;
  }, [rowSelection, table]);

  // Try me and remove this boy one more time!
  console.log;

  // initial setup of filters and columns from local storage
  React.useEffect(() => {
    // fetch data from local storage
    const userDefinedColumnVisibility =
      userContext !== null
        ? (userContext.getRecent('columns', identifier, []) as Record<
            string,
            boolean
          >)
        : [];
    const userDefinedFilters =
      userContext !== null
        ? (userContext.getRecent('filters', identifier, []) as Array<any>)
        : [];
    const userDefinedSorts =
      userContext !== null
        ? (userContext.getRecent('sorts', identifier, []) as Array<ISort>)
        : [];

    // if showFilters was true set initial filters
    const showFiltersLS: { show?: boolean } =
      userContext !== null
        ? userContext.getRecent('toggle_filter', identifier, { show: false })
        : { show: false };
    if (showFiltersLS?.show) {
      setShowFilters(true);
      setColumnFilters(userDefinedFilters);
    }
    const hiddenColumns: Record<string, boolean> = {}; // temporary fix
    // if there are data-set hidden layers add them to layers from localstorage
    if (
      hiddenColumnNames &&
      Array.isArray(hiddenColumnNames) &&
      hiddenColumnNames.length > 0 &&
      Object.keys(userDefinedColumnVisibility).length === 0
    ) {
      if (typeof hiddenColumnNames[0] === 'string')
        hiddenColumnNames.forEach((x: string) => {
          hiddenColumns[x] = false;
        });

      setColumnVisibility(hiddenColumns);
      return;
    }
    setColumnVisibility(userDefinedColumnVisibility);

    // set localstorage hidden layers
    setSorting(userDefinedSorts);

    setFirstRender(false);
  }, [hiddenColumnNames]);

  // column visibility local storage handler
  React.useEffect(() => {
    if (firstRender) return;

    if (userContext !== null) {
      userContext.setRecent('columns', identifier, columnVisibility);
    }
  }, [columnVisibility]);

  // filters local storage handler
  React.useEffect(() => {
    if (firstRender) return;

    if (userContext !== null) {
      userContext.setRecent('filters', identifier, columnFilters);
    }

    const rows = table.getPreExpandedRowModel().flatRows;

    let count = rows.length;

    rows.forEach((row) => {
      if (!row.getIsExpanded()) {
        count -= row.subRows.length;
      }
    });

    setDataCount(count)
  }, [columnFilters]);

  // sorts local storage handler
  React.useEffect(() => {
    if (firstRender) return;
    
    if (userContext !== null) {
      userContext.setRecent('sorts', identifier, sorting);
    }

  }, [sorting]);

  React.useEffect(() => { 
    setTimeout(() => { // I guess it needs timeout because otherwise it will fire before needed
    
      const filteredRowData = table.getExpandedRowModel().rows;
      const selectedRow =  filteredRowData.find(
        (x) => x.original.id === rowSelectedCustom
      ) as Row<any>;

      if (!selectedRow) return; // happens when rowSelectedCustom is 0

      const selectedRowIndex = filteredRowData.indexOf(selectedRow);

      const pageOfSelectedRecord = Math.floor(
        selectedRowIndex / table.getState().pagination.pageSize
      );

      if (!selectedRow.getIsSelected()) // happens when rowSelectedCustom is changed without clicking on row
        selectedRow.toggleSelected()
      table.setPageIndex(pageOfSelectedRecord);
    }, 0) // Somehow works with 0ms timeout but doesn't work without timeout???? 
    
  }, [rowSelectedCustom]);

  /**
   * Returns the text color for the inactive row taking into consideration the theme
   *
   * @param isActive - Is the row active
   * @param lightTheme - Is the light theme active
   * @returns rgba color string
   */
  const textFilterColor = (isActive: boolean, lightTheme: boolean) => {
    if (!isActive) {
      if (lightTheme) {
        return 'rgba(0,0,0,0.4)';
      }
      return 'rgba(250,250,250,0.5)';
    }
    return '';
  };

  /**
   * Function that toggles the filters
   */
  const handleToggleFilter = () => {
    table.resetColumnFilters();

    if (userContext !== null) {
      userContext.setRecent('toggle_filter', identifier, {
        show: !showFilters,
      });
    }

    setFiltersToggle(filters === null ? {} : null);
    setShowFilters((prevState) => !prevState);
  };

  const handleRowClick: RowClickFn = (evt, row) => {
    if (!row.getCanExpand()) {
      switch (allowSelection) {
        case 'none':
          return;
        case 'one':
          if (unselectRow || rowSelection[row.id] !== true) {
            table.toggleAllRowsSelected(false);
            row.toggleSelected();
            if (onRowClick) {
              const selObj = {} as { [key: string]: DCRecord };
              selObj[row.id] = row.original as DCRecord;
              onRowClick(evt, selObj);
            }
          }
          return;
        case 'many':
          if (!row.getCanExpand()) {
            row.toggleSelected();
          }
          break;
        default:
          break;
      }
    } else if (allowSelection !== 'none') {
      row.toggleExpanded(!row.getIsExpanded());
    }
  };

  React.useEffect(() => {
    // USED WHEN EXPANDING AND COLLAPSING ROWS. ADJUSTS PAGINATION.

    const rows = table.getPreExpandedRowModel().flatRows;

    let count = rows.length;

    rows.forEach((row) => {
      if (!row.getIsExpanded()) {
        count -= row.subRows.length;
      }
    });

    setDataCount(count);

  }, [table.getState().expanded, data.length, table.getState().globalFilter]);

  const collapseAllChildren = (row: Row<any>) => {
    if (row.getCanExpand()) {
      // Here you got the handler function then call it to execute the expansion
      row.toggleExpanded(false);
      row.subRows.forEach(childRow => {
        collapseAllChildren(childRow);

      })
    }
  };

  React.useEffect(() => {
    if (defaultPageSize) {
      table.setPageSize(defaultPageSize);
    }
  }, []);

  const generateTableRow = (row: Row<any>) => {
    const isActive =
      row.original.active === undefined ? true : row.original.active;
    const isSelected = rowSelection[row.id] && rowSelection[row.id] === true;

    const rowStyle = getRowStyle ? getRowStyle(row.original.id) : {};

    return (
      <TableRow
        key={row.id+"-row"}
        hover
        selected={isSelected}
        role="check"
        aria-checked={isSelected}
        style={rowStyle}
        onClick={(evt) => handleRowClick(evt, row)}
      >
        {tableContainsSubRow ? (
          <TableCell sx={tableCellStyle}>
            {row.getCanExpand() ? (
              <IconButton
                sx={{
                  ml: row.depth,
                  p:0
                }}
                onClick={() => {
                  if (row.getIsExpanded()) {
                    collapseAllChildren(row);
                  } else {
                    row.toggleExpanded();
                  }
                }}
              >
                <ExpandMore sx={{transition: "transform 0.2s", transform: row.getIsExpanded() ? "rotate(0.5turn)" : "rotate(0turn)"}}/>
              </IconButton>
            ) : (
              <Box />
            )}
          </TableCell>
        ) : (
          <></>
        )}
        {row.getVisibleCells().map((cell) => (
          <TableCell
            sx={tableCellStyle}
            onDoubleClick={(evt) => {
              if (onDoubleClick && !row.getCanExpand()) {
                const selection: { [key: string]: DCRecord } = {};
                selection[row.id] = row.original;
                onDoubleClick(evt, selection);
              }
            }}
            key={cell.id+"-cell"}
            style={{
              overflowWrap: 'break-word',
              cursor: 'pointer',
              color: textFilterColor(
                isActive,
                !!(themeContext && themeContext.theme === 'light')
              ),
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const tableCellStyle: SxProps = {
    padding: {
      xs: "2px 4px",
      md: "6px 16px"
    }
  }

  const generateTableRows = (rows: Row<any>[]) => {
    const generatedRows: JSX.Element[] = [];

    rows.map((row) => {
      generatedRows.push(generateTableRow(row));
    });

    return generatedRows;
  };

  const handleSetGlobalFilter = (value: string) => {
    setGlobalFilter(value);
  }

  return (
    <Box m={0} p={0}
      sx={{
        height: '100%',
        p: 1,
      }}
    >
      <Box m={0} p={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: '100%',
          padding: `0px !important`,
        }}
      >
        <Toolbar variant="regular" disableGutters>
          {title ? <TableHeading>{title}</TableHeading> : null}
          {allowSearch ? (
           <TableSearch
            globalFilter={globalFilter}
            setGlobalFilter={handleSetGlobalFilter}
            dataCount={dataCount}
           />
          ) : null}
          {disableControls === true ? (
            <TablePagination
              onPageChange={(evt, page) => table.setPageIndex(page)}
              page={table.getState().pagination.pageIndex}
              rowsPerPage={table.getState().pagination.pageSize}
              count={dataCount}
              labelRowsPerPage={t('table.rows_per_page') as string}
              onRowsPerPageChange={(evt) =>
                table.setPageSize(parseInt(evt.target.value, 10))
              }
              sx={{
                maxHeight: '60px',
                maxWidth: '200px',
                display: 'flex',
                alignContent: 'end',
                justifyContent: 'end',
                marginLeft: '30px',
                marginBottom: '10px',
                overflowY: 'hidden',
                '& > *': {
                  // transform: 'translateX(55%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
            />
          ) : null}

          {onRefresh ? (
            <TableHeaderButtonRefresh onClick={() => onRefresh()} />
          ) : null}
          <ToolbarFillContent />
          {allowLegend ? (
            <TableHeaderButtonLegend
              onClick={(evt) => {
                if (onLegendClick) onLegendClick();
              }}
            />
          ) : null}
          {allowColumnPicker ? (
            <TableHeaderButtonColumnPicker
              columns={table.getAllLeafColumns()}
              smallButtons={smallButtons}
            />
          ) : null}
          {allowFilter ? (
            <TableHeaderButtonFilter
              filters={showFilters}
              onClick={handleToggleFilter}
            />
          ) : null}
          {allowExport ? (
            <TableHeaderButtonExport
              dc={dc}
              rows={table.getExpandedRowModel().rows}
              records={data}
              tableName={tableName}
            />
          ) : null}
          {allowAdd && handleAdd !== undefined ? (
            <TableHeaderButton
              onClick={() => handleAdd()}
              startIcon={<AddIcon />}
              variant="contained"
            >
              {addLabel}
            </TableHeaderButton>
          ) : null}
        </Toolbar>
        <TableContainer
          sx={{
            '.MuiTableContainer-root': {
              [theme.breakpoints.down('md')]: {
                overflowX: 'scroll',
              },
            },
          }}
        >
          <MaterialUITable stickyHeader size={size === undefined ? 'small' : size}>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup, indexHeaderGroup) => (
                <>
                <TableRow  key={headerGroup.id+"-header-group"}>
                  {tableContainsSubRow ? (
                    <TableCell sx={tableCellStyle}>
                      <Box />
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {headerGroup.headers.map((header, indexHeader) => (
                    <TableCell 
                    sx={tableCellStyle}
                    ref={indexHeaderGroup === 0 && indexHeader === 0 ? firstCellRef : null}
                    key={header.id+"-header"}>
                      {!header.isPlaceholder && header.column.getCanSort() ? (
                        <div>
                          <TableSortLabel
                            active={header.column.getIsSorted() === 'asc'}
                            direction={
                              header.column.getIsSorted() ? 'desc' : 'asc'
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <Typography
                              variant="caption"
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: 800,
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Typography>
                          </TableSortLabel>
                        </div>
                      ) : (
                        <Typography
                          variant="caption"
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: 800,
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {showFilters && 
                <TableRow key={headerGroup.id+"-header-group-filter"}>
                {tableContainsSubRow ? (
                  <TableCell sx={tableCellStyle} style={{ top: firstRowHeight+"px" }}>
                    <Box />
                  </TableCell>
                ) : (
                  <></>
                )}
                {headerGroup.headers.map((header) => (
                  <TableCell sx={tableCellStyle} style={{ top: firstRowHeight+"px" }} key={header.id+"-header-filter"}>
                    {header.column.getCanFilter() ? (
                      <div
                        className="table-filter"
                        key={`${header.column.id}-filter`}
                      >
                        {header.column.columnDef?.meta?.filterComponent({
                          column: {
                            id: header.column.columnDef.id,
                            filterValue: header.column.getFilterValue(),
                            setFilter: header.column.setFilterValue,
                            preFilteredRows: data,
                            filterActive: showFilters,
                          },
                        })}
                      </div>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>} </>
              ))}
            </TableHead>
            <TableBody>{generateTableRows(table.getRowModel().rows)}</TableBody>
           
          </MaterialUITable>
          <div className="h-4" />
        </TableContainer>
        {disableControls === false ? (
          <TablePagination sx={{overflow: "unset"}}
            onPageChange={(evt, page) => table.setPageIndex(page)}
            page={table.getState().pagination.pageIndex}
            rowsPerPage={table.getState().pagination.pageSize}
            count={dataCount}
            labelRowsPerPage={t('table.rows_per_page') as string}
            onRowsPerPageChange={(evt) =>
              table.setPageSize(parseInt(evt.target.value, 10))
            }
          />
        ) : null}
        {children ? (
          <Toolbar variant="dense" disableGutters>
            <div style={{ flexGrow: 1 }} />
            {React.Children.map(children, (child) =>
              React.cloneElement(child, { selection: selectedRecords })
            )}
          </Toolbar>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Table;
