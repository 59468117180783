import * as React from 'react';

import AppPage from '@/ui/AppPage/AppPage';
import ModelForm from './ModelForm';
import Box from '@mui/material/Box';
import Permissions from '@/lib/permissions';

import { useParams } from 'react-router-dom';
import dataController from '@/lib/DataController';
import GFIModel from '@/models/gfi_model';

function ModelPage() {
  const { id } = useParams();
  const dc = new dataController(GFIModel);

  const recordId = parseInt(id ? id : '', 10);

  return (
    <AppPage
      titlett="admin:page.model"
      needPermission={[Permissions.Administration]}
    >
      <Box m={2}>
        <ModelForm dc={dc} recordId={recordId} mode="update" form="update" />
      </Box>
    </AppPage>
  );
}

export default ModelPage;
