import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

// Custom Components
import useAuthFormController from '@/components/useAuthFormController';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import FormButton from '@/ui/Form/FormButton';
import UserContext from '@/context/UserContext/UserContext';

// Types
import { LoginFormProps } from '@/@types/views/Auth';
import { DCFieldValue, DCRecord } from '@/@types/lib/dataController';
import { DoLoginResponseSuccess } from '@/@types/components/authFormController';

const LoginForm = (props: LoginFormProps) => {
  const userContext = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { record, validation, fields, onFieldChange, doLogin } =
    useAuthFormController(props);

  const { dc, form } = props;

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value as DCFieldValue, source);
    }
  };

  // const handleRegistration = () => {
  //    navigate("/register");
  // };

  const handlePasswordReset = () => {
    navigate('/password-reset-request', { replace: true });
  };

  const handleSubmit = () => {
    if (doLogin) {
      doLogin()
        .then((result) => {
          if (result && result.success) {
            if (userContext !== null) {
              userContext.setUserData((result as DoLoginResponseSuccess).user);
            }
            navigate('/');
          } else if (result && result.validationPass === false) {
            // console.log(result.validation);
          }
        })
        .catch((e) => {
          // TODO handle exception
          console.warn('Exception: ', e);
        });
    }
  };

  return (
    <Card>
      <CardContent>
        <form>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h4" component="h2">
                {t('titles.login')}
              </Typography>
            </GridItem>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              onSubmit={handleSubmit}
              mode="insert"
              columns={1}
            />
          </GridContainer>
        </form>
      </CardContent>
      <CardActions>
        <FormButton variant="contained" onClick={handleSubmit}>
          {t('buttons.login')}
        </FormButton>
        <FormButton variant="text" onClick={handlePasswordReset}>
          {t('buttons.password_reset')}
        </FormButton>
      </CardActions>
    </Card>
  );
};

export default LoginForm;
