import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';

// MUI Icons
import { Popover, Tabs, Tab } from '@mui/material';

// OpenLayers
import Geometry from 'ol/geom/Geometry';
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';
import { get as OlGetProjection } from 'ol/proj';
import OlLayerGroup from 'ol/layer/Group';
import OlSourceVector from 'ol/source/Vector';
import MapEvent from 'ol/MapEvent';
import { DrawEvent } from 'ol/interaction/Draw';
import BaseEvent from 'ol/events/Event';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlFeature from 'ol/Feature';
import OlPoint from 'ol/geom/Point';
import OlLineString from 'ol/geom/LineString';
import OlCollection from 'ol/Collection';
import OlStyle from 'ol/style/Style';
import WKT from 'ol/format/WKT';
import RenderFeature from 'ol/render/Feature';
import OlVectorLayer from 'ol/layer/Vector';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlLayerTile from 'ol/layer/Tile';

// Custom Components
import Map from '@/components/Map/Map';
import {
  Controls,
  CoordZoomStatusControl,
  FullScreenControl,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  GeoLocateControl,
  ZoomSliderControl,
  CenterMarkerControl,
  LayerTree,
  ViewHistoryControl,
} from '@/components/Map/Controls';
import {
  SidebarContent,
  SidebarControl,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem,
  SidebarHeading,
} from '@/components/Map/Controls/Sidebar';
import {
  InfoPane,
  MeasuresPane,
  MapViewsPane,
  LegendPane,
  CommentsPane,
} from '@/components/MapSidebarPanes';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import {
  Overlays,
  PopupOverlay,
  MeasureTooltipOverlay,
} from '@/components/Map/Overlays';
import {
  Interactions,
  DefaultInteractions,
  DrawInteraction,
} from '@/components/Map/Interactions';
import {
  measurementsStyle,
  measuringStyle,
  selectedStyle,
  commentsStyle,
  commentingStyle,
  highlightedVectorStyle,
} from '@/components/Map/mapStyles';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import GSInfoCard from '@/components/Map/InfoCards/GSInfoCard';
import PrintControl from '@/components/Map/Controls/OpenLayers/PrintControl';
import DataPane from '@/components/MapSidebarPanes/DataPane';
import useWindowSize, { Size } from '@/components/useWindowSize';

// Views
import MiscCard from '@/views/Comments/MiscCard';
import { HighlightAnimation } from '@/views/Animations/HighlightAnimation';
import CommentDialog from '@/views/Comments/CommentDialog';

// Context
import UserContext from '@/context/UserContext/UserContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';

// Lib
import { flattenLayers } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';
import Permissions from '@/lib/permissions';
import useApi from '@/lib/api/useApi';
import { helpers } from '@/lib/helpers';

// Models
import modelAdrese from '@/models/adrese';
import modelDKP from '@/models/dkp';
import modelNC from '@/models/nerazvrstane_ceste';
import modelComments from '@/models/comments';
import modelCommentsPhotos from '@/models/comments_photos';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';
import { authService } from '@/services/authService';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import {
  IMapProps,
  CardPosition,
  OverlayCardType,
  IMiscCardOverlayData,
  IGFICardOverlayData,
  ICardOverlayData,
  IGFIOverlayFeatureIndex,
} from '@/@types/views/map';
import {
  DrawingType,
  DrawingInteractionType,
  CoordinateSystemType,
  BoolSettings,
  ViewOptionsType,
  IMapView,
} from '@/@types/common/index';
import { GFIResponseType } from '@/@types/services/gsService';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';
import PPVInfoCard from '@/components/Map/InfoCards/PPVInfoCard';
import OlPolygon from 'ol/geom/Polygon';
import OlMultiPolygon from 'ol/geom/MultiPolygon';
import OlMultiLineString from 'ol/geom/MultiLineString';
import OlMultiPoint from 'ol/geom/MultiPoint';

const MainMap = (props: IMapProps) => {
  const userContext = useContext(UserContext);
  const loaderContext = useContext(LoaderContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const tenantContext = useContext(TenantContext);
  const apiInstance = useApi();

  const { map_id } = props;

  const [mapInitialized, setMapInitialized] = useState(false);

  // Default values for initial view
  let viewsState: IMapView | undefined;

  // Values saved in localStorage
  const viewsStateJSON = localStorage.getItem(
    `viewsState_${userContext?.username}`
  );
  if (
    viewsStateJSON !== null &&
    viewsStateJSON !== undefined &&
    JSON.parse(viewsStateJSON).views.length > 1
  ) {
    const viewsStateHolder = JSON.parse(viewsStateJSON);
    viewsState = viewsStateHolder.views[viewsStateHolder.currentViewIndex - 1];
  }

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: viewsState
      ? viewsState.center
      : tenantContext?.mapSettings &&
        tenantContext?.mapSettings.initial_view_center &&
        tenantContext?.mapSettings.initial_view_center.length === 2
        ? tenantContext.mapSettings.initial_view_center
        : [1731757, 5581737],
    zoom: viewsState
      ? viewsState.zoom
      : tenantContext?.mapSettings &&
        tenantContext?.mapSettings.initial_view_zoom
        ? tenantContext.mapSettings.initial_view_zoom
        : 8,
    extent:
      tenantContext?.mapSettings &&
        tenantContext?.mapSettings.max_extent &&
        tenantContext?.mapSettings.max_extent.length > 0
        ? tenantContext.mapSettings.max_extent
        : undefined,
    // projection: wgs84PM,
    minZoom: 7,
    maxZoom: 20,
    constrainResolution: true,
  });

  const [elevationPointsSource, setElevationPointsSource] = useState<
    OlSourceVector<OlPoint>
  >(new OlSourceVector({}));
  const elevationPointsFeatures = useMemo(
    () => elevationPointsSource.getFeatures(),
    [elevationPointsSource]
  );
  const [profileChartSettings, setProfileChartSettings] =
    useState<any>(undefined);

  const [drawingSource, setDrawingSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );
  const [measurementsSource, setMeasurementsSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));

  const [chosenCoordinateSystem, setChosenCoordinateSystem] =
    useState<CoordinateSystemType>('WGS84');
  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [drawType, setDrawType] = useState<DrawingType | undefined>(undefined);
  const [drawInteractionType, setDrawInteractionType] = useState<
    DrawingInteractionType | undefined
  >(undefined);
  const [isDrawingRoute, setIsDrawingRoute] = useState(false);
  const [feature, setFeature] = useState<OlFeature<Geometry> | undefined>(
    undefined
  );
  const [measuringFeature, setMeasuringFeature] = useState<
    OlFeature<Geometry> | undefined
  >(undefined);
  const [GFICardOverlay, setGFICardOverlay] = useState<
    IGFICardOverlayData[] | undefined
  >(undefined);
  const [miscCardOverlay, setMiscCardOverlay] = useState<
    IMiscCardOverlayData | undefined
  >(undefined);

  const [overlayTabActive, setOverlayTabActive] = useState<number>(0); // from layer dropdown on GSInfoCard
  const [overlayFeatureIndex, setOverlayFeatureIndex] = useState<IGFIOverlayFeatureIndex>({}); // from pagination on GSInfoCard
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [defaultExtent, setDefaultExtent] = useState(
    tenantContext?.mapSettings?.default_extent &&
      tenantContext?.mapSettings?.default_extent.length > 0
      ? tenantContext.mapSettings.default_extent
      : [1437016, 5271097, 2206278, 5860579]
  );

  const [workingRecords, setWorkingRecords] = useState<DCRecord[]>([]);
  const [workingSource, setWorkingSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );

  const [selectedRecords, setSelectedRecords] = useState<DCRecord[]>([]);
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedStyle, setHighlightedVectorStyle] = useState<OlStyle>(
    highlightedVectorStyle
  );

  const [recordsAdrese, setRecordsAdrese] = useState<DCRecord[]>([]);
  const [recordsDKP, setRecordsDKP] = useState<DCRecord[]>([]);
  const [recordsNC, setRecordsNC] = useState<DCRecord[]>([]);

  const [commentRecords, setCommentRecords] = useState<DCRecord[]>([]);
  const [commentDrawType, setCommentDrawType] = useState<
    DrawingType | undefined
  >(undefined);
  const commentDrawId = useRef<string | undefined>(undefined);
  const [commentingFeature, setCommentingFeature] = useState<
    OlFeature<Geometry> | undefined
  >(undefined);
  const [commentsVisible, setCommentsVisible] = useState<boolean>(
    localStorage.getItem('maplayers') &&
      localStorage.getItem('maplayers') !== null &&
      // @ts-ignore
      JSON.parse(localStorage.getItem('maplayers'))
      ? // @ts-ignore
      JSON.parse(localStorage.getItem('maplayers'))['spatial-comments'] ===
      true
      : false
  );

  const [expandedPosjednici, setExpandedPosjednici] = useState(false);
  const [expandedVlasnici, setExpandedVlasnici] = useState(false);

  const zoomToDefaultExtentElementRef = useRef<HTMLLIElement>(null);
  const zoomToSelectedExtentElementRef = useRef<HTMLLIElement>(null);
  const fullScreenElementRef = useRef<HTMLLIElement>(null);
  const geoLocateElementRef = useRef<HTMLLIElement>(null);
  const printElementRef = useRef<HTMLLIElement>(null);
  const [centerMarkerElement, setCenterMarkerElement] =
    useState<HTMLLIElement | null>(null);
  const centerMarkerElementCallback = useCallback(
    (node: HTMLLIElement | null) => {
      setCenterMarkerElement(node);
    },
    []
  );
  const layerSwitcherElementRef = useRef<JSX.Element>();

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection('EPSG:3765');
  const wgs84 = OlGetProjection('EPSG:4326');
  const wgs84PM = OlGetProjection('EPSG:3857');

  // defaultViewCenter = [1731757, 5581737];
  // initialDefaultExtent = [1688674,5501166,1688774,5501266];
  const defaultViewCenter = tenantContext?.mapSettings?.initial_view_center;
  const initialDefaultExtent = tenantContext?.mapSettings?.default_extent;

  const dcKomentari = new DataController(modelComments);
  const dcAdrese = new DataController(modelAdrese);
  const dcDKP = new DataController(modelDKP);
  const dcNC = new DataController(modelNC);

  const size: Size = useWindowSize();

  useEffect(() => {
    getLayers();

    if (userContext?.permissionIds.includes(Permissions.View_Adrese.code)) {
      getAdrese();
    }
    if (userContext?.permissionIds.some(permId => permId === Permissions.View_DKP.code || permId === Permissions.View_DKP2.code)) {
      getDKP();
    }
    if (userContext?.permissionIds.includes(Permissions.NerazvrstaneCeste.code)) {
      getNC();
    }

    apiInstance.get('profile/params').then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        const data = {
          ...resp.data,
          layer: new OlLayerTile({
            source: new OlSourceTileWMS({
              url: process.env.REACT_APP_GEOSERVERPATH,
              params: {
                // @ts-ignore
                LAYERS: resp.data?.layers as string,
                FORMAT: 'image/png',
                TILED: true,
              },
            }),
            visible: true,
          }),
        };

        setProfileChartSettings(data);
      } else {
        console.log('DEM settings not found');
      }
    });
  }, []);

  const getLayers = () => {
    mapService.getLayers(map_id).then((coll) => {
      if (userContext?.hasAnyPermission([Permissions.Private])) {
        const comments_layer = new OlVectorLayer({
          source: commentsSource,
          properties: {
            id: '_spatial-comments',
            title: 'map:layer.spatial-comments',
            ttoken: 'titles.spatial-comments',
            type: 'special',
            query: false,
            call_group: null,
            z_index: 900,
            style: commentsStyle,
          },
        });

        coll.push(comments_layer);
      }

      setLayersCollection(coll);
      setMapInitialized(true);
      getComments();
    });
  };

  const getAdrese = () => {
    dcAdrese.GetData().then((resp) => {
      if (resp.success) {
        setRecordsAdrese(resp.data as DCRecord[]);
      }
    });
  };

  const getDKP = () => {
    dcDKP.GetData().then((resp) => {
      if (resp.success) {
        setRecordsDKP(resp.data as DCRecord[]);
      }
    });
  };

  const getNC = () => {
    dcNC.GetData().then((resp) => {
      if (resp.success) {
        setRecordsNC(resp.data as DCRecord[]);
      }
    });
  };
  // getDefaultData() {
  //   mapService.getDefaultData(MAP_ID).then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       this.setState((prevState) => {
  //         return {
  //           ...prevState,
  //           viewOptions: {
  //             ...prevState.viewOptions,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           },
  //           zoomToExtent: viewData.default_extent
  //         };
  //       });
  //     }
  //   });
  // }

  const getComments = () => {
    if (userContext?.hasAnyPermission([Permissions.Private])) {
      dcKomentari
        .GetData()
        .then((resp) => {
          if (resp.success && resp.data) {
            if (Array.isArray(resp.data)) {
              // const features = resp.data.map((x) => x.geom);
              const tempComments: DCRecord[] = [];
              for (const x of resp.data) {
                if (x.hasOwnProperty('geom') && x.geom && x.geom !== null) {
                  tempComments.push(x);
                }
              }
              setCommentRecords(tempComments);
            } else {
              setCommentRecords([]);
            }
          } else {
            setCommentRecords([]);
          }
        })
        .catch((resp) => {
          // console.log(resp);
          setCommentRecords([]);
        });
    }
  };

  const getPhotosForComment = (
    feature: RenderFeature | OlFeature<Geometry>,
    evt?: OlMapBrowserEvent<any>,
    coordinates?: [number, number]
  ) => {
    let photos: any = [];

    const dc = new DataController(modelCommentsPhotos);

    dc.GetData(`comments/${String(Number(feature.getId()))}/photos`)
      .then((resp) => {
        if (resp.success && resp.data && resp.data !== null) {
          const features = [feature];
          if (Array.isArray(resp.data) && resp.data.length > 0) {
            photos = resp.data.slice();
            showCardOverlay(
              'commentinfo',
              {
                position: evt
                  ? (evt.coordinate as CardPosition)
                  : (coordinates as CardPosition),
                features,
                record: photos,
                type: 'commentinfo',
              },
              null
            );
          } else {
            showCardOverlay(
              'commentinfo',
              {
                position: evt
                  ? (evt.coordinate as CardPosition)
                  : (coordinates as CardPosition),
                features,
                type: 'commentinfo',
              },
              null
            );
          }
        }
      })
      .catch(() => {
        const features = [feature];
        showCardOverlay(
          'commentinfo',
          {
            position: evt
              ? (evt.coordinate as CardPosition)
              : (coordinates as CardPosition),
            features,
            type: 'commentinfo',
          },
          null
        );
      });
  };

  // For drawing comments
  const commentsSource = useMemo(() => {
    const commentsSource = new OlSourceVector();
    // @ts-ignore
    commentRecords.map((x) => {
      // @ts-ignore
      commentsSource.addFeature(x.geom);
    });
    return commentsSource;
  }, [commentRecords]);

  const setViewCenter = (view: IMapView) => {
    setViewOptions((prevState) => ({
      ...prevState,
      center: view.center,
      zoom: view.zoom,
    }));
  };

  const changeCoordinateSystemDisplay = (type: CoordinateSystemType) => {
    setChosenCoordinateSystem(type);
  };

  const handleClick = useCallback(
    (evt: OlMapBrowserEvent<any>) => {
      let hit = false;
      setSelectedSource(new OlSourceVector({}));

      if (userContext && userContext.expireTime) {
        checkToken(userContext.expireTime);
      }

      if (drawType || isDrawingRoute || commentDrawType) {
        return;
      }

      const feature = evt.map.forEachFeatureAtPixel(
        evt.pixel,
        (feature) => feature,
        {
          hitTolerance: 10,
        }
      );

      closeAllOverlays();
      setOverlayFeatureIndex({});
      setOverlayTabActive(0);

      if (
        feature &&
        feature.getId() !== null &&
        feature.getId() !== undefined &&
        commentDrawType === undefined
      ) {
        if (
          feature &&
          feature.getId() !== null &&
          feature.getId() !== undefined
        ) {
          getPhotosForComment(feature, evt); // comment was found
          setSelectedSource(
            new OlSourceVector({ features: [feature as OlFeature<Geometry>] })
          );
          // Potential Feature - Perform Animation upon Click:
          // setHighlightedSource(
          //   new OlSourceVector({ features: [feature as OlFeature<Geometry>] })
          // );
        }
        hit = true;
      }

      const timeBeforeLoader = 300; // time in miliseconds before loader appears while waiting on getFeatureInfo
      let loaderNeeded = true;
      let loading = false;

      function handleLoader() {
        if (loaderNeeded) {
          loaderContext.toggleLoading(true);
          loading = true;
        } else if (loading) {
          loading = false;
          loaderContext.toggleLoading(false);
        }
      }

      if (!hit) {
        setTimeout(() => {
          handleLoader();
        }, timeBeforeLoader);

        const item = localStorage.getItem('maplayers');
        const visibility = item
          ? (JSON.parse(item) as BoolSettings)
          : ({} as BoolSettings);
        const hiddenLayers = visibility
          ? Object.keys(visibility).filter((key) => visibility[key] === false)
          : [];
        const allLayers = layersCollection
          ? flattenLayers(layersCollection.getArray()).filter(
            (x) => !(x instanceof OlLayerGroup)
          )
          : [];
        const visibleLayers = allLayers.filter(
          (x) => hiddenLayers.indexOf(x.get('id')) === -1
        );
        const gsLayers = visibleLayers.filter((x) => x.get('query') === true);

        gsService
          .getFeatureInfo(evt.map, evt.pixel, gsLayers)
          .then((resp) => {
            if (resp && Object.keys(resp).length != 0) {
              const layerKeys = Object.keys(resp);

              if (layerKeys.includes('photos360')) {
                const data = {
                  position: evt.coordinate as CardPosition,
                  features: resp[layerKeys[0]],
                  record: undefined,
                } as ICardOverlayData;

                showCardOverlay(
                  'photos360',
                  {
                    ...data,
                    type: 'photos360',
                  },
                  null
                );
              } else {
                layerKeys.forEach((layerKey) => {
                  const data = {
                    position: evt.coordinate as CardPosition,
                    features: resp[layerKey],
                    record: undefined,
                  } as ICardOverlayData;

                  let layer;

                  try {
                    layer = layersCollection // puca za dem
                      ?.getArray()
                      .filter(x => x.get('query'))
                      .find(
                        // geoserver layer native name needs to be same as layer column in db.map_layers (after : sanitation)
                        (layer) => {
                          const layerParts = layer.get('layer').split(':')
                          if (layerParts.length > 1) {
                            return layerParts[1] === layerKey ? true : false;
                          } else {
                            return layerParts[0] === layerKey ? true : false;
                          }
                        }
                      );
                  } catch (error) {
                    console.error('GFI Layer Error', error);
                    return;
                  }
                  showCardOverlay(
                    'gsinfo',
                    {
                      ...data,
                      layerData: {
                        layer: layerKey,
                        model: layer?.get('model'),
                        layerFunctionalityId: layer?.get(
                          'layer_functionality_id'
                        ),
                      },
                    },
                    null
                  );
                });
              }
            }
          })
          .finally(() => {
            loaderNeeded = false;
            handleLoader();
          });
      }
    },
    [drawType, layersCollection, commentDrawType]
  );

  const showCardOverlay = (
    type: OverlayCardType,
    data: IGFICardOverlayData | IMiscCardOverlayData,
    ft: OlFeature<any> | null
  ) => {
    // TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
      setFeature(ft);
    } else {
      setFeature(undefined);
    }

    if (type === 'gsinfo') {
      setGFICardOverlay((prev) =>
        addCardToGFI(prev, data as IGFICardOverlayData)
      );
    } else {
      setMiscCardOverlay(data as IMiscCardOverlayData);
    }
  };

  const closeGFIOverlay = () => {
    setGFICardOverlay(undefined);
  };

  const closeMiscOverlay = () => {
    setMiscCardOverlay(undefined);
  };

  const closeAllOverlays = () => {
    closeGFIOverlay();
    closeMiscOverlay();
  };

  const addCardToGFI = (
    GFI: IGFICardOverlayData[] | undefined,
    newCard: IGFICardOverlayData
  ) => {
    if (GFI === undefined) {
      return [newCard];
    }
    const newGFI = [...GFI];
    newGFI.push(newCard);
    return newGFI;
  };

  const handleViewChangeCenter = (evt: MapEvent) => {
    if (evt && evt.map) {
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom(),
      };
      setViewOptions(Object.assign(viewOptions, newView));
    }
  };

  const handleSidebarPaneChange = useCallback(
    (id: string) => {
      if (drawType && id && id !== 'measures') {
        setDrawType(undefined);
        setDrawInteractionType(undefined);
        setMeasuringFeature(undefined);
      } else if (id) {
        if (size && size.width && size.width > 768) {
          setDrawType(undefined);
          setDrawInteractionType(undefined);
          setMeasuringFeature(undefined);
        }
      }
    },
    [drawType]
  );

  const changeDrawType = useCallback(
    (type: DrawingType) => {
      if (drawType !== type) {
        setDrawType(type);
        setDrawInteractionType(type === 'Profile' ? 'LineString' : type);
        setMeasuringFeature(undefined);
        if (type === 'Profile') {
          handleEraseMeasurements();
        }
      } else {
        setDrawType(undefined);
        setDrawInteractionType(undefined);
        setMeasuringFeature(undefined);
      }
    },
    [drawType]
  );

  const handleDrawMeasureStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setMeasuringFeature(evt.feature);
  };

  const handleDrawMeasureEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    const feat = evt.feature;
    feat.set('display_crs', chosenCoordinateSystem);
    if (drawType === 'Profile') {
      handleEraseMeasurements();
      setMeasurementsSource(
        (prevState) =>
          new OlSourceVector({
            features: [evt.feature],
          })
      );
    } else {
      setMeasurementsSource(
        (prevState) =>
          new OlSourceVector({
            features: [...prevState.getFeatures(), evt.feature],
          })
      );
    }

    if (drawType === 'Point' || drawType === 'Profile') {
      if (profileChartSettings) {
        // @ts-ignore
        getProfilePoints(evt.target.getMap() as OlMap, feat);
      }
    }

    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  const getProfilePoints = (
    map: any,
    feat: OlFeature<OlPoint | OlLineString>
  ) => {
    const geom = feat.getGeometry();
    const feat_uuid = helpers.createUUID();

    if (geom) {
      if (geom instanceof OlPoint) {
        const p = geom.getCoordinates();
        const pxPoint = map.getPixelFromCoordinate(p);
        profileChartSettings &&
          gsService
            .getFeatureInfo(map, pxPoint, [profileChartSettings.layer])
            .then((resp) => {
              const feat = parseAltResponse(resp, p);
              if (feat) {
                setElevationPointsSource(
                  (prevState) =>
                    new OlSourceVector({
                      features: prevState.getFeatures().concat([feat]),
                    })
                );
                setForceRefreshCounter((prevState) =>
                  prevState ? prevState + 1 : 1
                );
              }
            });
      } else if (geom instanceof OlLineString) {
        // coordinates in View projection
        const coords = geom.getCoordinates();
        // but we need pixel coordinates on a map
        const pxPoints = coords.map((x) => map.getPixelFromCoordinate(x));
        // this can take some time so we turn on loader
        loaderContext.toggleLoading(true);
        // we call all GFI calls and continue when all responses are received (to calculcate dh we need to make sure that previous point is already received)
        Promise.all(
          pxPoints.map((px) =>
            Promise.resolve(
              profileChartSettings &&
              gsService.getFeatureInfo(map, px, [profileChartSettings.layer])
            )
          )
        )
          .then((responses) => {
            // create features from responses
            const features = responses.map((resp, ii) =>
              parseAltResponse(
                resp,
                coords[ii],
                feat_uuid,
                ii,
                ii > 0 ? getAlt(responses[ii - 1]) : undefined
              )
            );
            // filter null features if any
            const validFeatures = features.filter(
              (x) => x !== null
            ) as OlFeature<OlPoint>[];
            // update source with all values at once
            setElevationPointsSource(
              (prevState) =>
                new OlSourceVector({
                  features: prevState.getFeatures().concat(validFeatures),
                })
            );
            // force refresh
            setForceRefreshCounter((prevState) =>
              prevState ? prevState + 1 : 1
            );
          })
          .finally(() => {
            // when everything is ready, we turn off loader
            loaderContext.toggleLoading(false);
          });
      }
    }
  };

  const getAlt = (resp: GFIResponseType | null) => {
    const alt = resp ? (resp.dem[0].properties.DEM as number) : undefined; // Ugly because response returns an object with key "" which containes an array
    if (typeof alt === 'number' && (alt < -413 || alt > 8848)) return undefined; // Check if elevation data makes sense or its dem shenanigans
    return resp ? (resp.dem[0].properties.DEM as number) : undefined;
  };

  const parseAltResponse = (
    resp: GFIResponseType | null,
    point: number[],
    feat_uuid?: string,
    ord?: number,
    prevAlt?: number
  ) => {
    if (resp && Object.keys(resp).length !== 0) {
      // @ts-ignore
      const alt: number = resp.dem[0].properties.DEM; // Ugly because response returns an object with key "" which containes an array
      if (alt < -413 || alt > 8848) return null; // Check if elevation data makes sense or its dem shenanigans
      const newFeat = new OlFeature({
        geometry: new OlPoint(point),
      });
      newFeat.set('alt', alt.toFixed(2));
      if (feat_uuid) {
        newFeat.set('line_uuid', feat_uuid);
      }
      if (ord !== undefined) {
        newFeat.set('ord', ord);
        // console.log('got response for ord: ', ord);
      }

      if (prevAlt !== undefined) {
        newFeat.set('dh', (alt - prevAlt).toFixed(2));
      } else {
        newFeat.set('dh', 'N/A');
      }

      return newFeat;
    }
    return null;
  };

  const handleDrawMeasureChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleEraseMeasurements = () => {
    setMeasurementsSource(new OlSourceVector({}));
    setDrawType(undefined);
    setDrawInteractionType(undefined);
    setMeasuringFeature(undefined);
  };

  const handleLayerVisibilityChange = () => {
    setForceRefreshCounter((prevState) => prevState + 1);
  };

  const onToggleDrawRoute = (isActive: boolean) => {
    setIsDrawingRoute(isActive);
  };

  function checkToken(expireTime: Moment) {
    const isExpired = expireTime < moment();
    if (isExpired) {
      authService.logout(tenantContext as TenantContextType);
      snackbarContext.showNotification(t('messages.token_expired'), 'warning');
      navigate('/login');
    }
  }

  const changeCommentDrawType = useCallback(
    (type: DrawingType) => {
      if (commentDrawType !== type) {
        setCommentDrawType(type);
        setCommentingFeature(undefined);
      } else {
        setCommentDrawType(undefined);
        setCommentingFeature(undefined);
      }
    },
    [commentDrawType]
  );

  const handleDrawCommentsStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setCommentingFeature(evt.feature);
  };

  const handleDrawCommentsEnd = (evt: DrawEvent) => {
    setTimeout(() => {
      const newFeature = evt.feature;
      setDrawingSource(new OlSourceVector({}));

      const format = new WKT();

      const dialogData: any = {
        dc: dcKomentari,
        form: 'insert',
        mode: 'insert',
        initialRecord: {
          geom: newFeature,
          extent: newFeature.getGeometry()?.getExtent(),
        },
        refreshLayers: getLayers,
        onClose: () => {
          setCommentDrawType(undefined);
          setCommentingFeature(undefined);
          getComments();
        },
      };

      commentDrawId.current = undefined;
      dialogContext.showDialog(CommentDialog, dialogData);
      setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
    }, 1000);
  };

  const handleDrawCommentsChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const closeOverlays = (callback: any) => {
    setMiscCardOverlay(undefined);
    setExpandedPosjednici(false);
    setExpandedVlasnici(false);
    if (callback !== undefined && typeof callback === 'function') {
      callback();
    }
  };

  const handlePopoverOverlayClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!GFICardOverlay) return;

    const activeLayer = GFICardOverlay[overlayTabActive];
    if (!activeLayer) return;

    let featureIndex = overlayFeatureIndex[activeLayer.layerData.layer]

    if (!featureIndex) featureIndex = 0;

    const featureData = activeLayer.features[featureIndex];

    let geometry;

    switch (featureData.geometry.type) {
        case 'Point':
            geometry = new OlPoint(featureData.geometry.coordinates);
            break;
        case 'MultiPoint':
            geometry = new OlMultiPoint(featureData.geometry.coordinates);
            break;
        case 'LineString':
            geometry = new OlLineString(featureData.geometry.coordinates);
            break;
        case 'MultiLineString':
            geometry = new OlMultiLineString(featureData.geometry.coordinates);
            break;
        case 'Polygon':
            geometry = new OlPolygon(featureData.geometry.coordinates);
            break;
        case 'MultiPolygon':
            geometry = new OlMultiPolygon(featureData.geometry.coordinates);
            break;
        default:
            console.error('Unsupported geometry type:', featureData.geometry.type);
    }

    const newFeature = new OlFeature({
        geometry: geometry,
        id: featureData.id,
        properties: featureData.properties
    });

    setSelectedSource(new OlSourceVector({features: [newFeature]}))
  
  }, [GFICardOverlay, overlayFeatureIndex, overlayTabActive])

  if (userContext && layersCollection) {
    return (
      <Map
        height="800px"
        view={viewOptions}
        onClick={handleClick}
        onMoveend={handleViewChangeCenter}
        className="sidebar-map"
        id="main-map"
        initialized={mapInitialized}
        moveTolerance={10}
        maxTilesLoading={10}
      >
        <Controls>
          <CoordZoomStatusControl
            chosenCoordinateSystem={chosenCoordinateSystem}
          />
          <StatusControl
            changeCoordinateSystem={changeCoordinateSystemDisplay}
          />
          <ZoomControl
            zoomInTipLabel={`${t('map:controls.zoom_in')}`}
            zoomOutTipLabel={`${t('map:controls.zoom_out')}`}
          />
          <ZoomSliderControl />
          <ScaleRatioControl ppi={96} />
          <ScaleLineControl />
          <RotateControl
            autoHide={false}
            tipLabel={`${t('map:controls.reset_north')}`}
          />
          {fullScreenElementRef?.current && mapInitialized ? (
            <FullScreenControl
              id="full-screen"
              tipLabel={`${t('map:controls.full_screen')}`}
              target={fullScreenElementRef?.current}
              className="ol-sidebar-control"
            />
          ) : null}

          {zoomToDefaultExtentElementRef?.current && mapInitialized ? (
            <ZoomToExtentControl
              isSidebar
              id="zoom-extent-default"
              target={zoomToDefaultExtentElementRef.current}
              extent={defaultExtent}
              tipLabel={`${t('map:controls.zoom_to_extent')}`}
              className="ol-sidebar-control"
            />
          ) : null}
          {geoLocateElementRef?.current && mapInitialized ? (
            <GeoLocateControl
              id="geo-locate"
              tooltip={`${t('map:controls.geolocate')}`}
              target={geoLocateElementRef.current}
              className="ol-sidebar-control"
            />
          ) : null}
          {centerMarkerElement && mapInitialized ? (
            <CenterMarkerControl
              id="center-marker"
              tooltip={`${t('map:controls.center_marker')}`}
              target={centerMarkerElement}
              className="ol-sidebar-control"
            />
          ) : null}
          {printElementRef?.current && mapInitialized ? (
            <PrintControl
              tooltip={t('map:controls.print')}
              dpi={300}
              target={printElementRef.current}
              className="ol-sidebar-control"
            />
          ) : null}
          <ViewHistoryControl />
          <GeoBaseLayerSwitcher allowNoLayer />
          <SidebarControl
            initialOpenId="layers"
            initialOpenHeading={t('map:sidebar.layers') as string}
            onTabChange={handleSidebarPaneChange}
          >
            <SidebarHeading/>
            <SidebarTabs>
              <SidebarTabList>
                <SidebarTabListItem
                  id="info"
                  title={`${t('map:sidebar.info')}`}
                  icon={<i className="fas fa-info-circle" />}
                  needPermission={[Permissions.Core]}
                />
                <SidebarTabListItem
                  id="layers"
                  title={`${t('map:sidebar.layers')}`}
                  icon={<i className="fas fa-layer-group" />}
                  needPermission={[Permissions.Core]}
                />
                <SidebarTabListItem
                  id="legend"
                  title={t('map:sidebar.legend')}
                  icon={<i className="fas fa-list-alt" />}
                  needPermission={[Permissions.Core]}
                />
                <SidebarTabListItem
                  id="mapviews"
                  title={`${t('map:sidebar.map_views')}`}
                  icon={<i className="fas fa-bookmark" />}
                  needPermission={[Permissions.Private]}
                />
                <SidebarTabListItem
                  id="comments"
                  title={`${t('map:sidebar.comments')}`}
                  icon={<i className="fas fa-comment" />}
                  needPermission={[Permissions.Private]}
                />
                <SidebarTabListItem
                  id="adrese"
                  title={t('map:sidebar.adrese')}
                  icon={<i className="fas fa-address-book" />}
                  needPermission={[Permissions.View_Adrese]}
                />
                <SidebarTabListItem
                  id="dkp"
                  title={t('map:sidebar.dkp')}
                  icon={<i className="fas fa-globe" />}
                  needPermission={[Permissions.View_DKP]}
                />
                <SidebarTabListItem
                  id="nc"
                  title={t("titles.nerazvrstane_ceste")}
                  icon={<i className="fas fa-bacon" />}
                  needPermission={[Permissions.NerazvrstaneCeste]}
                />
                <SidebarTabListItem
                  id="measures"
                  title={`${t('map:sidebar.measures')}`}
                  icon={<i className="fas fa-pencil-ruler" />}
                  needPermission={[Permissions.Core]}
                  onSidebarCollapse={() => {handleSidebarPaneChange("mesaures")}}
                />
                <li ref={zoomToDefaultExtentElementRef} />
                <li ref={fullScreenElementRef} />
                <li ref={geoLocateElementRef} />
                <li
                  id="center-marker-container"
                  ref={centerMarkerElementCallback}
                />
                <li ref={printElementRef} />
              </SidebarTabList>
            </SidebarTabs>
            <SidebarContent>
              <SidebarPane id="info">
                <InfoPane />
              </SidebarPane>
              <SidebarPane id="layers">
                <LayerTree
                  ready={!!layersCollection}
                  layersCollection={layersCollection}
                  onLayerVisibilityChange={handleLayerVisibilityChange}
                />
              </SidebarPane>
              <SidebarPane id="legend">
                <LegendPane layersCollection={layersCollection} />
              </SidebarPane>
              <SidebarPane id="mapviews">
                <MapViewsPane />
              </SidebarPane>
              <SidebarPane id="comments">
                <CommentsPane
                  // toggleVisibility={() => {
                  //   setCommentsVisible((prev) => !prev);
                  // }}
                  // visible={commentsVisible}
                  changeDrawType={changeCommentDrawType} // changeCommentDrawType
                  drawType={commentDrawType} // commentDrawType
                  records={commentRecords} // commentRecords
                  setSelectedSource={(x) => {
                    setHighlightedSource(x);
                    setSelectedSource(x);
                  }}
                />
              </SidebarPane>
              <SidebarPane
                id="adrese"
                needPermission={[Permissions.View_Adrese]}
              >
                <DataPane
                  dc={dcAdrese}
                  tableName="adrese"
                  viewName="sidebar"
                  records={recordsAdrese}
                  closeOverlays={closeOverlays}
                  setSelectedSource={setHighlightedSource}
                  setStyle={setHighlightedVectorStyle}
                />
              </SidebarPane>
              <SidebarPane
                id="dkp"
                needPermission={[Permissions.View_DKP, Permissions.View_DKP2]}
              >
                <DataPane
                  dc={dcDKP}
                  tableName="dkp_short"
                  viewName="sidebar"
                  records={recordsDKP}
                  closeOverlays={closeOverlays}
                  setSelectedSource={setHighlightedSource}
                  setStyle={setHighlightedVectorStyle}
                />
              </SidebarPane>
              <SidebarPane 
                id="nc"
                needPermission={[Permissions.NerazvrstaneCeste]}
              >
                <DataPane
                  dc={dcNC}
                  tableName="nerazvrstane_ceste"
                  viewName="sidebar"
                  records={recordsNC}
                  closeOverlays={closeOverlays}
                  setSelectedSource={setHighlightedSource}
                  setStyle={setHighlightedVectorStyle}
                />
              </SidebarPane>
              <SidebarPane id="measures">
                <MeasuresPane
                  // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                  changeDrawType={changeDrawType}
                  handleEraseMeasurements={handleEraseMeasurements}
                  drawType={drawType}
                  elevationPointsFeatures={elevationPointsFeatures}
                  measurementsSource={measurementsSource}
                  profileSettings={profileChartSettings}
                />
              </SidebarPane>
            </SidebarContent>
          </SidebarControl>
          <HighlightAnimation
            features={highlightedSource.getFeatures()}
            setSource={setHighlightedSource}
          />
        </Controls>
        <Layers>
          {layersCollection ? (
            <Layers>
              <GeoAPILayers layersCollection={layersCollection} />
              <VectorLayer
                id="_measurements"
                source={measurementsSource}
                style={measurementsStyle}
                zIndex={900}
              />
              <VectorLayer
                id="_selected"
                source={selectedSource}
                style={selectedStyle}
                zIndex={910}
              />
              <VectorLayer
                id="_spatial-comments"
                source={commentsSource}
                style={commentsStyle}
                zIndex={905}
              />
            </Layers>
          ) : null}
        </Layers>
        <Overlays>
          <PopupOverlay
            id="feature-overlay"
            position={
              GFICardOverlay && GFICardOverlay[overlayTabActive]
                ? GFICardOverlay[overlayTabActive].position
                : undefined
            }
            autoPan
            onClose={() => { }}
          >
            {GFICardOverlay && GFICardOverlay.length > 1 ? (
              <Popover
                id="simple-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={overlayTabActive}
                  onChange={(event: React.SyntheticEvent, newValue: number) => {
                    setOverlayTabActive(newValue);
                    setAnchorEl(null);
                  }}
                >
                  {GFICardOverlay.map((value, index) => (
                    <Tab
                      label={value.layerData.layer}
                      id={value.layerData.layer}
                    />
                  ))}
                </Tabs>
              </Popover>
            ) : (
              <></>
            )}

            {GFICardOverlay && GFICardOverlay.length > overlayTabActive ? (
              GFICardOverlay[overlayTabActive].layerData
                .layerFunctionalityId ===
                EnumLayerFunctionality.cjenovni_blok_custom_actions ? (
                <PPVInfoCard
                  featureData={GFICardOverlay[overlayTabActive]}
                  onClose={closeGFIOverlay}
                  layerPickerDisabled={GFICardOverlay.length <= 1}
                  setIndex={setOverlayFeatureIndex}
                  currentIndex={overlayFeatureIndex}
                  handlePopoverOverlayClick={handlePopoverOverlayClick}
                />
              ) : (
                <GSInfoCard
                  featureData={GFICardOverlay[overlayTabActive]}
                  onClose={closeGFIOverlay}
                  layerPickerDisabled={GFICardOverlay.length <= 1}
                  setIndex={setOverlayFeatureIndex}
                  currentIndex={overlayFeatureIndex}
                  handlePopoverOverlayClick={handlePopoverOverlayClick}
                />
              )
            ) : (
              <></>
            )}
          </PopupOverlay>
          <PopupOverlay
            id="comment-overlay"
            position={miscCardOverlay ? miscCardOverlay.position : undefined}
            autoPan
            onClose={() => { }}
          >
            {miscCardOverlay ? (
              <MiscCard
                featureData={miscCardOverlay}
                onClose={closeMiscOverlay}
                onCommentDelete={getComments}
                refreshLayers={getComments}
                refreshPhotos={getPhotosForComment}
              />
            ) : null}
          </PopupOverlay>

          <MeasureTooltipOverlay
            id="measure-overlay"
            feature={measuringFeature}
            position={undefined}
          />
        </Overlays>
        <Interactions>
          <DefaultInteractions />
          {drawInteractionType ? (
            <DrawInteraction
              source={drawingSource}
              type={drawInteractionType}
              style={measuringStyle}
              onChange={handleDrawMeasureChange}
              onDrawstart={handleDrawMeasureStart}
              onDrawend={handleDrawMeasureEnd}
            />
          ) : null}
          {commentDrawType ? (
            <DrawInteraction
              source={drawingSource}
              type={commentDrawType}
              style={commentingStyle}
              onChange={handleDrawCommentsChange}
              onDrawstart={handleDrawCommentsStart}
              onDrawend={handleDrawCommentsEnd}
            />
          ) : null}
        </Interactions>
      </Map>
    );
  }
  return null;
};

export default MainMap;
