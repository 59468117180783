import * as React from 'react';
import { CSSProperties, FunctionComponent, useState } from 'react';

// Custom components
import FormHeaderStatusBreadCrumbs from '@/ui/Form/FormHeaderStatusBreadCrumbs';

// Types
import { Status } from '@/@types/common';

export interface IFormHeaderStatus {
  recordStatus: number;
  statuses: Status[];
}

const FormHeaderStatus: FunctionComponent<IFormHeaderStatus> = (props) => {
  const [height, setHeight] = useState(20);
  const [showLabels, setShowLabels] = useState(false);
  const { recordStatus, statuses } = props;

  const handleClick = () => {
    setHeight((prevState) => (prevState === 20 ? 40 : 20));
    setShowLabels((prevState) => !prevState);
  };

  const colorBarStyle: CSSProperties = {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    height: height,
    maxHeight: height,
    minHeight: height,
    backgroundColor: '#F3F5FA',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };

  return recordStatus ? (
    <div style={colorBarStyle}>
      <FormHeaderStatusBreadCrumbs
        recordStatus={recordStatus}
        statuses={statuses}
        onClick={handleClick}
        showLabels={showLabels}
      />
    </div>
  ) : null;
};

export default FormHeaderStatus;
