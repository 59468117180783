import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

// Types
import { ModuleCardType } from '@/@types/common';

interface ModuleCardProps {
  module: ModuleCardType;
}

const ModuleCard = (props: ModuleCardProps) => {
  const { t } = useTranslation();
  const { module } = props;
  const { titleTtoken, descriptionTtoken, imageSrc, path, external, icon } =
    module;

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea href={path} sx={{ height: '100%' }}>
        <CardMedia
          component="img"
          height="170px"
          image={imageSrc}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {t(titleTtoken)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t(descriptionTtoken)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ModuleCard;
