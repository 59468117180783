import useApi from '@/lib/api/useApi';
import arrayBufferToBase64 from '@/lib/arrayBufferToBase64';

export interface IImgService {
  getImage: (
    baseRecordPath: string,
    baseRecordId: number,
    imgNo: number,
    imgSize?: number,
    objExtent?: string
  ) => Promise<any>;
  getObjImage: (
    baseRecordPath: string,
    baseRecordId: number,
    imgId: number,
    width: number,
    clip?: [number, number, number, number]
  ) => Promise<any>;
  getRecordImageThumb: (
    baseRecordPath: string,
    baseRecordId: number,
    imageId: number
  ) => Promise<any>;
}

const imgService: IImgService = {
  getImage,
  getObjImage,
  getRecordImageThumb,
};

function getImage(
  baseRecordPath: string,
  baseRecordId: number,
  imgNo: number,
  imgSize?: number,
  objExtent?: string
): Promise<any> {
  const apiInstance = useApi();
  const endsWithSlash =
    baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1;
  const url = `${baseRecordPath}${
    endsWithSlash ? '' : '/'
  }${baseRecordId}/images?img_number=${imgNo}&img_size=${
    imgSize || 190
  }&extent=${objExtent || ''}`;
  // const url = baseRecordPath + baseRecordId.toString()
  //   + "/images?img_number=" + imgNo.toString()
  //   + "&img_size=" + (imgSize ? imgSize : 190).toString()
  //   + "&extent=" + (objExtent ? objExtent : "");

  return apiInstance.get(url);
}

function getObjImage(
  baseRecordPath: string,
  baseRecordId: number,
  imgId: number,
  width: number,
  clip?: [number, number, number, number]
): Promise<any> {
  const apiInstance = useApi();
  const endsWithSlash =
    baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1;
  const clipParam = clip ? `&clip=${clip.join(',')}` : null;
  const url = `${baseRecordPath}${
    endsWithSlash ? '' : '/'
  }${baseRecordId}/images/${imgId}?width=${width || 0}${clipParam || ''}`;

  return apiInstance.get(url);
}

// Returns thumbnail for the record's image
function getRecordImageThumb(
  baseRecordPath: string,
  baseRecordId: number,
  imageId: number
) {
  const apiInstance = useApi();

  const endsWithSlash =
    baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1;
  const url = `${baseRecordPath}${
    endsWithSlash ? '' : '/'
  }${baseRecordId}/images/${imageId}/thumb`;

  return apiInstance.download(url).then((resp) => {
    if (resp.success && resp.data) {
      return resp.data.then((data: Blob) =>
        data
          .arrayBuffer()
          .then((arrBuffer: ArrayBuffer) => arrayBufferToBase64(arrBuffer))
      );
    }
    return Promise.reject(null);
  });
}

export default imgService;
