import { IModel } from '../@types/models/model';
import commonFields from './helpers/commonFields';
import coreFields from './helpers/coreFields';

const TTokens: IModel = {
  title: 'Translation tokens',
  apiPath: 'system/translation',
  forms: {
    default: {
      fields: ['id', 'tenant_id', 't_namespace','ttoken','trans_en','trans_hr'],
    },
    update: {
        fields: ['id', 'tenant_id', 't_namespace','ttoken','trans_en','trans_hr'],
    },
    insert: {
        fields: ['t_namespace', 'tenant_id','ttoken','trans_en','trans_hr'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'ttoken', 'tenant_id','trans_en','trans_hr'],
    },
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      filter: true
    },
    {
        title: 'Translation namespace',
        source: 't_namespace',
        ttoken: 'translation.namespace',
        type: 'text',
    },
    {
        title: 'Translation token',
        source: 'ttoken',
        ttoken: 'translation.ttoken',
        type: 'text',
        width: 'full',
        filter: true
    },
    {
        title: 'English translation',
        source: 'trans_en',
        ttoken: 'translation.english',
        type: 'text',
        width: 'full',
        filter: true
    },
    {
        title: 'Croatian translation',
        source: 'trans_hr',
        ttoken: 'translation.croatian',
        type: 'text',
        width: 'full',
        filter: true
    },
  ],
};

export default TTokens;
