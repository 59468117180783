import React from 'react';
import { useTranslation } from 'react-i18next';

//MUI Icons
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

//Custom Components
import TableButton from './TableButton';

//Types
import { ITableActionButtonDetail } from '@/@types/ui/Table';

interface ITableShowPhotosButton extends ITableActionButtonDetail {
  disabled?: boolean;
}

function TableButtonEdit(props: ITableShowPhotosButton) {
  const { t } = useTranslation();

  const { disabled } = props;

  return (
    <TableButton
      allow={disabled ? 'none' : 'always'}
      startIcon={<PhotoCameraIcon />}
      selection={{}}
      {...props}
    >
      {t('buttons.show_photos')}
    </TableButton>
  );
}

export default TableButtonEdit;
