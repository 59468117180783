import { createContext } from 'react';
import Map from 'ol/Map';
import Control from 'ol/control/Control';
import Overlay from 'ol/Overlay';
import Interaction from 'ol/interaction/Interaction';
import Layer from 'ol/layer/Layer';
import Group from 'ol/layer/Group';
import Source from 'ol/source/Source';

// Types
import { BoolSettings, StringSettings } from '@/@types/common';

export type InitLayerType = Layer<Source, any> | Group;

export type InitOptionsType = {
  controls: Control[];
  layers: InitLayerType[];
  overlays: Overlay[];
  interactions: Interaction[];
};

export type MapContextType = {
  map: Map | null;

  initOptions: InitOptionsType;

  setLayerVisibility: (id: string, isVisible: boolean) => void;
  getLayerVisibility: () => BoolSettings;

  setLayerTime: (id: string, time: string) => void;
  getLayerTime: () => StringSettings;

  forceRefreshCounter: number;

  sidebarCollapsed: boolean;
  setSidebarCollapsed: (c: boolean) => void;
};

const MapContext = createContext<MapContextType | null>(null);

MapContext.displayName = 'MapContext';
export default MapContext;
