import React from 'react';
import { FunctionComponent, PropsWithChildren } from 'react';

// Types
export interface IFormFillContent {
}

const FormFillContent: FunctionComponent<IFormFillContent> = (props: PropsWithChildren<IFormFillContent>) => {
  const { children } = props;

  return (
    <div
      style={{
        flexGrow: 1,
        paddingLeft: 5,
        paddingRight: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
};

export default FormFillContent;
