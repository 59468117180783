import * as React from 'react';
import { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

// Types
export interface IHeaderButton {
  desc?: string;
  children: ReactNode;
  onClick: (evt: SyntheticEvent) => void;
  startIcon: ReactNode;
  endIcon?: ReactNode;
}

const HeaderButton: FunctionComponent<IHeaderButton> = (props) => {
  const { t } = useTranslation();
  const { desc, onClick, startIcon, endIcon, ...rest } = props;

  const title: string = desc ? t(`tooltips.header.${desc}`) : '';

  const style = {
    marginRight: 0,
    color: 'common.white',
    '&:hover': {
      bgcolor: 'primary.dark',
    },
  };

  if (desc) {
    return (
      <Tooltip disableFocusListener placement="bottom" arrow title={title}>
        <Button
          color="inherit"
          size="small"
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          {...rest}
          sx={style}
        >
          {props.children}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      color="inherit"
      size="small"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      {...rest}
      sx={style}
    >
      {props.children}
    </Button>
  );
};

export default HeaderButton;
