import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// MUI
import { Grid, useMediaQuery, useTheme } from '@mui/material';

// Custom Components
import DataCentricPrimaryTable from './EnaDataCentricPrimaryTable';
import DataCentricSecondaryTable from './EnaDataCentricSecondaryTable';
import DataCentricMiniMap from './EnaDataCentricMiniMap';
import DataCentricImagesOverlay from './EnaDataCentricImagesOverlay';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IImageData } from '@/@types/views/KomunalniObveznici';

import { IModel } from '@/@types/models/model';

export type EnaDataCentricPageParams = {
  obj_id?: string;

  primaryModel: IModel;
  primaryTitleToken: string;
  primaryRecordLocationField: string;

  secondaryModel: IModel;
  secondaryTitleToken: string;

  baseRecordPath: string;
  childRecordRelativePath: string;
  geomRelativePath: string;

  mapId: number;
  mapModel: IModel;

  filterByLocation?: boolean;
  allowShowImages?: boolean;

  disableControlsPrimary?: boolean;
  disableControlsSecondary?: boolean;
  allowLegend?: boolean;
  onLegendClick?: () => void;
  allowFilterTogglePrimary?: boolean;
  // allowFilterToggleSecondary?: boolean;
};

const EnaDataCentricPage = (props: EnaDataCentricPageParams) => {
  const [primaryRecord, setPrimaryRecord] = useState<DCRecord | null>(null);
  const [lokacijaid, setLokacijaId] = useState(0);
  const [selectedObjektId, setSelectedObjektId] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<IImageData | null>(null);
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const [internalObjId, setInternalObjId] = useState<number>(-1);

  const mapRef = useRef<HTMLDivElement>(null);
  const primaryRef = useRef<HTMLDivElement>(null);

  const {
    primaryModel,
    primaryTitleToken,
    primaryRecordLocationField,
    secondaryModel,
    secondaryTitleToken,
    mapId,
    mapModel,
    baseRecordPath,
    childRecordRelativePath,
    geomRelativePath,
    filterByLocation,
    allowShowImages,
    disableControlsPrimary,
    disableControlsSecondary,
    allowLegend,
    onLegendClick,
    allowFilterTogglePrimary,
    // allowFilterToggleSecondary
  } = props;

  const [searchParams] = useSearchParams();

  const obj_id = searchParams.get('obj_id');
  const openFirst = searchParams.get('open_first');
  const loc_id = searchParams.get('loc_id');

  const int_obj_id = obj_id ? parseInt(obj_id, 10) : undefined;
  const bool_open_first = openFirst === 'true';
  const int_loc_id = loc_id ? parseInt(loc_id, 10) : undefined;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleLokacijaSelect = (id: number, record: DCRecord | null) => {
    if (id === lokacijaid) {
      setLokacijaId(0);
      setPrimaryRecord(null);
    } else if (!id) {
      setInternalObjId(-1);
      setSelectedObjektId(0);
    } else {
      if (record !== null) {
        setLokacijaId(record[primaryRecordLocationField] as number);
      }
      setPrimaryRecord(record);
      scrollIntoMap();
    }
  };

  const scrollIntoMap = () => {
    if (!mdUp && mapRef.current) {
      mapRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const scrollIntoPrimary = () => {
    if (!mdUp && primaryRef.current) {
      primaryRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const handleLokacijeLoad = (records: Array<DCRecord>) => {
    if (bool_open_first && records.length > 0) {
      const firstRec = records[0];
      const firstRecLokId = firstRec[primaryRecordLocationField] as number;
      setLokacijaId(firstRecLokId);
      setPrimaryRecord(firstRec);
    }
  };

  const handleObjektSelect = (id: number) => {
    if (id === selectedObjektId) {
      setSelectedObjektId(0);
    } else {
      setSelectedObjektId(id);
    }
    if (!showImages && id) {
      setShowImages(true);
    }
  };

  const handleObjektiLoad = (records: Array<DCRecord>) => {
    if (bool_open_first && records.length > 0) {
      const firstObj = records[0];
      const firstObjId = firstObj.id as number;
      if (internalObjId !== -1) {
        setSelectedObjektId(internalObjId);
        setShowImages(true);
        setInternalObjId(-1); // when internal object is selected, delete internal object
      }
    }
  };

  useEffect(() => {
    setShowImages(selectedObjektId !== 0 && selectedObjektId !== null);
  }, [selectedObjektId]);

  useEffect(() => {
    if (showImages && selectedObjektId) {
      scrollIntoPrimary();
    }
  },[showImages, selectedObjektId])

  useEffect(() => {
    if (int_obj_id === undefined) {
      setPrimaryRecord(null);
      setLokacijaId(0);
      setSelectedObjektId(0);
    } else {
      setInternalObjId(int_obj_id);
    }
  }, [int_obj_id]);

  useEffect(() => {
    if (int_loc_id !== undefined) {
      setLokacijaId(int_loc_id);
    }
  }, [int_loc_id]);

  return (
    <Grid columns={24} container height={'calc( 100vh - 48px )'}>
      {/* screen width - header */}
      <Grid item xs={24}  sx={{ maxHeight: '60%', minHeight: {xs: allowShowImages && showImages ? '60%' : '0', md: '60%'} }} position={'relative'}>
        <div style={{width: "100%", height: "100%"}} ref={primaryRef}>
          <DataCentricPrimaryTable
            onRowSelect={handleLokacijaSelect}
            onLoad={handleLokacijeLoad}
            lokacijaId={lokacijaid}
            filterByLocation={filterByLocation}
            allowFilterToggle={
              allowFilterTogglePrimary !== undefined
                ? allowFilterTogglePrimary
                : false
            }
            model={primaryModel}
            titleToken={primaryTitleToken}
            locationField={primaryRecordLocationField}
            disableControls={
              disableControlsPrimary !== undefined ? disableControlsPrimary : true
            }
            allowLegend={allowLegend !== undefined ? allowLegend : false}
            onLegendClick={onLegendClick || undefined}
          />
          {allowShowImages && showImages ? (
            <DataCentricImagesOverlay
              selectedObjektId={selectedObjektId}
              closeOverlay={() => setShowImages(false)}
              imgData={imgData}
              objExtent={objExtent}
              baseRecordPath={primaryModel.apiPath}
            />
          ) : null}
        </div>
      </Grid>
      
      <Grid  item sx={{ height: "40%" }} xs={24} md={9} lg={7} xl={5}>
        <div ref={mapRef} style={{width: "100%", height: "100%"}}>
          <DataCentricMiniMap
            lokacijaId={lokacijaid}
            onObjektSelect={handleObjektSelect}
            onLokacijaSelect={handleLokacijaSelect}
            selectedObjektId={selectedObjektId}
            _setObjExtent={setObjExtent}
            model={mapModel}
            mapId={mapId}
            baseRecordPath={baseRecordPath}
            geomRelativePath={geomRelativePath}
            locationField={primaryRecordLocationField}
          />
        </div>
      </Grid>
      <Grid item sx={{ maxHeight: {xs:'60%',md:'40%'}, minHeight: {xs: '0', md: '40%'}}} xs={24} md={15} lg={17} xl={19}>
        <DataCentricSecondaryTable
          lokacijaId={lokacijaid}
          primaryRecord={primaryRecord}
          onObjektSelect={handleObjektSelect}
          selectedObjektId={selectedObjektId}
          setImgData={setImgData}
          onLoad={handleObjektiLoad}
          model={secondaryModel}
          titleToken={secondaryTitleToken}
          baseRecordPath={baseRecordPath}
          childRecordRelativePath={childRecordRelativePath}
          disableControls={
            disableControlsSecondary !== undefined
              ? disableControlsSecondary
              : true
          }
        />
      </Grid>
    </Grid>
  );
};

export default EnaDataCentricPage;
