import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FC,
  ChangeEvent,
  ReactElement,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { UserContextType } from '@/context/UserContext/UserContext';
import { INumHash, IStringHash } from '@/@types/common';

export interface IFormTabs {
  value: number | string;
  onChange: (value: number) => void;
  identifier?: string;
  children: ReactElement | ReactElement[];
}

// usePrevious is a function to store the previous props or state
function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const FormTabs: FC<IFormTabs> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const location = useLocation();
  const prevChildren = usePrevious(props.children);
  const navigate = useNavigate();

  const { children, onChange, identifier, value } = props;
  const [tabHashes, setTabHashes] = useState({} as IStringHash);

  const keepTrackOfRecentTab = identifier !== undefined;

  const isEmpty = (obj: object) => {
    const keys = Object.keys(obj);
    return !!(Array.isArray(keys) && keys.length > 0);
  };

  const handleChangeTab = (evt: React.SyntheticEvent, value: number): void => {
    onChange(value);
    if (keepTrackOfRecentTab) {
      if (!isEmpty(tabHashes)) {
        const hash_id = getTabHash(value);
        if (hash_id) {
          userContext.setRecentTab(identifier as string, hash_id);
          navigate(`${location.pathname}#${hash_id}`, { replace: true });
        }
      }
    }
  };

  const getTabHash = (value: string | number): string | number => {
    const strValue = String(value);
    return tabHashes[strValue];
  };

  // Used to compare prev children with current strictly to see if a tab has been toggled
  const compareChildren = (prev: any, current: any): boolean => {
    for (const index in current) {
      const compare = (prev[index] === null) === (current[index] === null);
      if (!compare) {
        return compare;
      }
    }
    return true;
  };

  // denote_index: Used for the case of hidden tabs, which do not show up as children of this component
  // Done so to keep tab indexs in sequential order
  const genTabHash = (hash: string = 'default'): number | string => {
    const hash_id_object = {} as INumHash;
    let recent_index = 0;
    let denote_index = 0;
    if (children && Array.isArray(children)) {
      (children as ReactElement[]).forEach((child, i) => {
        if (child) {
          if (child.props.id !== undefined) {
            hash_id_object[i - denote_index] = `#${child.props.id}`;
            if (
              hash !== 'default' &&
              hash_id_object[i - denote_index] === hash
            ) {
              recent_index = i - denote_index;
            }
          }
        } else {
          denote_index++;
        }
      });
    }
    if (!isEmpty(hash_id_object)) {
      const newTabHash = {} as IStringHash;
      Object.keys(hash_id_object).map(
        (x) => (newTabHash[String(x)] = hash_id_object[parseInt(x, 10)])
      );
      setTabHashes(newTabHash);
      if (history !== undefined && keepTrackOfRecentTab) {
        const key = hash === 'default' ? String(value) : String(recent_index);
        const keyVal = newTabHash[key];
        if (keyVal) {
          navigate(`${location.pathname}#${keyVal}`, { replace: true });
        }
      }
    }
    return hash === 'default'
      ? value
      : isEmpty(hash_id_object)
      ? value
      : recent_index;
  };

  useEffect(() => {
    if (prevChildren && !isEmpty(tabHashes)) {
      if (!compareChildren(prevChildren, children)) {
        const recent_index = genTabHash(location.hash);
        const numIndex = parseInt(String(recent_index));
        onChange(numIndex);
      }
    }
  }, [children]);

  useEffect(() => {
    if (location.hash) {
      const recent_index = genTabHash(location.hash);
      if (keepTrackOfRecentTab) {
        userContext.setRecentTab(identifier as string, location.hash);
      }
      const numIndex = parseInt(String(recent_index));
      onChange(numIndex);
    } else if (keepTrackOfRecentTab) {
      const recent_tab = userContext.getRecentTab(identifier as string, 0);
      const recent_index = genTabHash(recent_tab as string);
      const numIndex = parseInt(String(recent_index));
      onChange(numIndex);
    } else {
      genTabHash();
    }
  }, []);

  return (
    <Box
      borderColor="grey.200"
      border={1}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
    >
      <Tabs
        indicatorColor="primary"
        textColor="secondary"
        variant="fullWidth"
        onChange={handleChangeTab}
        value={value}
        sx={{
          paddingTop: '0px',
          position: 'sticky',
          top: '0',
          zIndex: 9,
          borderTop: '1px solid #CCC',
          borderBottom: '1px solid primary.main',
        }}
      >
        {children}
      </Tabs>
    </Box>
  );
};

export default FormTabs;
