import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import buttonFields from './helpers/buttonFields';

const MapLayer: IModel = {
  title: 'Map Layers',
  apiPath: 'system/map_layers',
  forms: {
    insert: {
      fields: [
        'map_id',
        'tenant_id',
        'ui_code',
        'ui_title',
        'ui_ttoken',
        'gs_layer_source',
        'gs_layer_type',
        'gs_url',
        'gs_layer',
        'gs_projection',
        'active',
        'gs_format',
        'ui_legend',
        'legend_conf_id',
        'ui_has_opacity_slider',
        { source: 'gfi_model_id', readonly: false },
        'layer_functionality_id',
      ],
    },
    update: {
      fields: [
        'map_id',
        'tenant_id',
        'ui_code',
        'ui_title',
        'ui_ttoken',
        'gs_layer_source',
        'gs_layer_type',
        'gs_url',
        'gs_layer',
        'gs_projection',
        'ui_extent',
        'gs_styles',
        'gs_format',
        'gs_tiled',
        'gs_call_group',
        'ui_visible',
        'gs_can_query',
        'ui_zoomable',
        'ui_sortorder',
        'ui_z_index',
        'use_cache',
        'ui_parent_layer_code',
        'gs_timeseries',
        'gs_visible_min_zoom',
        'gs_visible_max_zoom',
        'ui_legend',
        'legend_conf_id',
        'ui_has_opacity_slider',
        { source: 'gfi_model_id', readonly: false },
        'layer_functionality_id',
      ],
    },
    search: {
      fields: ['map_id', 'tenant_id', 'ui_code', 'ui_title']
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'map_id',
        'tenant_id',
        'ui_code',
        'ui_title',
        'copy',
      ],
    },
  },
  fields: [
    coreFields.id,
    coreFields.created_on,
    coreFields.modified_on,
    buttonFields.copy,
    {
      title: 'map_id',
      source: 'map_id',
      ttoken: 'admin:layers.map_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/maps',
        format: '{label}',
        idattr: 'id',
      },
      validation: {
        required: true,
      },
      filter: true,
      readonly: false,
      translate: false,
    },
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      filter: true,
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'ui_code',
      source: 'ui_code',
      ttoken: 'admin:common.code',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 30,
      },
    },
    {
      title: 'ui_title',
      source: 'ui_title',
      ttoken: 'core:common.title',
      type: 'text',
      filter: true,
      validation: {
        maxLength: 250,
      },
    },
    {
      title: 'ui_ttoken',
      source: 'ui_ttoken',
      ttoken: 'admin:common.ttoken',
      type: 'text',
      validation: {
        required: true,
        maxLength: 50,
      },
    },
    {
      title: 'gs_layer_source',
      source: 'gs_layer_source',
      ttoken: 'admin:common.source',
      type: 'picker',
      items: {
        labels: [
          'GROUP',
          'OSM',
          'STAMEN',
          'VectorSource',
          'ImageWMS',
          'TileWMS',
          'WMTS',
          'TMS',
        ],
        values: [
          'GROUP',
          'OSM',
          'STAMEN',
          'VectorSource',
          'ImageWMS',
          'TileWMS',
          'WMTS',
          'TMS',
        ],
      },
      validation: {
        maxLength: 20,
        required: true,
      },
    },
    {
      title: 'gs_layer_type',
      source: 'gs_layer_type',
      ttoken: 'core:common.type',
      type: 'picker',
      items: {
        labels: [
          'GROUP',
          'VectorLayer',
          'ImageLayer',
          'TileLayer',
          'WebGLTileLayer',
        ],
        values: [
          'GROUP',
          'VectorLayer',
          'ImageLayer',
          'TileLayer',
          'WebGLTileLayer',
        ],
      },
      validation: {
        maxLength: 20,
        required: true,
      },
    },
    {
      title: 'gs_url',
      source: 'gs_url',
      ttoken: 'core:common.url',
      type: 'text',
      validation: {
        maxLength: 250,
      },
    },
    {
      title: 'gs_layer',
      source: 'gs_layer',
      ttoken: 'core:common.layer',
      type: 'text',
      validation: {
        maxLength: 250,
      },
    },
    {
      title: 'gs_projection',
      source: 'gs_projection',
      ttoken: 'core:common.projection',
      type: 'text',
      validation: {
        maxLength: 10,
      },
    },
    {
      title: 'ui_extent',
      source: 'ui_extent',
      ttoken: 'admin:layers.extent',
      type: 'array',
    },
    {
      title: 'gs_styles',
      source: 'gs_styles',
      ttoken: 'admin:layers.styles',
      type: 'text'
    },
    {
      title: 'gs_format',
      source: 'gs_format',
      ttoken: 'core:common.format',
      type: 'picker',
      items: {
        labels: [
          'image/png',
          'image/png8',
          'image/jpeg',
          'image/vnd.jpeg-png',
          'image/vnd.jpeg-png8',
          'image/gif',
          'image/tiff',
          'image/tiff8',
          'image/geotiff',
          'image/geotiff8',
          'image/svg',
          'application/pdf',
          'rss',
          'kml',
          'kmz',
          'application/openlayers',
          'application/json;type=utfgrid',
        ],
        values: [
          'image/png',
          'image/png8',
          'image/jpeg',
          'image/vnd.jpeg-png',
          'image/vnd.jpeg-png8',
          'image/gif',
          'image/tiff',
          'image/tiff8',
          'image/geotiff',
          'image/geotiff8',
          'image/svg',
          'application/pdf',
          'rss',
          'kml',
          'kmz',
          'application/openlayers',
          'application/json;type=utfgrid',
        ],
      },
      validation: {
        maxLength: 20,
      },
    },
    {
      title: 'gs_tiled',
      source: 'gs_tiled',
      ttoken: 'admin:layers.tiled',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
    },
    {
      title: 'gs_call_group',
      source: 'gs_call_group',
      ttoken: 'admin:layers.call_group',
      type: 'text',
      validation: {
        maxLength: 20,
      },
    },
    {
      title: 'ui_visible',
      source: 'ui_visible',
      ttoken: 'admin:layers.visible',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: true,
      },
    },
    {
      title: 'gs_can_query',
      source: 'gs_can_query',
      ttoken: 'admin:layers.can_query',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
    },
    {
      title: 'active',
      source: 'active',
      ttoken: 'core:common.active',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: true,
      },
    },
    {
      title: 'ui_zoomable',
      source: 'ui_zoomable',
      ttoken: 'admin:layers.zoomable',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
    },
    {
      title: 'ui_sortorder',
      source: 'ui_sortorder',
      ttoken: 'core:common.sortorder',
      type: 'numeric',
    },
    {
      title: 'ui_z_index',
      source: 'ui_z_index',
      ttoken: 'admin:layers.z_index',
      type: 'numeric',
    },
    {
      title: 'use_cache',
      source: 'use_cache',
      ttoken: 'admin:layers.use_cache',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
    },
    {
      title: 'ui_parent_layer_code',
      source: 'ui_parent_layer_code',
      ttoken: 'admin:layers.parent_layer_code',
      type: 'text',
      validation: {
        maxLength: 20,
      },
    },
    {
      title: 'gs_timeseries',
      source: 'gs_timeseries',
      ttoken: 'admin:layers.timeseries',
      type: 'text',
      validation: {
        maxLength: 500,
      },
    },
    {
      title: 'gs_visible_min_zoom',
      source: 'gs_visible_min_zoom',
      ttoken: 'admin:layers.visible_min_zoom',
      type: 'numeric',
    },
    {
      title: 'gs_visible_max_zoom',
      source: 'gs_visible_max_zoom',
      ttoken: 'admin:layers.visible_max_zoom',
      type: 'numeric',
    },
    {
      title: 'ui_legend',
      source: 'ui_legend',
      ttoken: 'core:common.legend',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: true,
      },
    },
    {
      title: 'legend_conf_id',
      source: 'legend_conf_id',
      ttoken: 'admin:layers.legend_conf_id',
      filter: true,
      type: 'picker',
      subModel: {
        apiPath: 'system/map_legend_conf?view=picker',
        format: '{label}',
        idattr: 'id',
      },
    },
    {
      title: 'gfi_model_id',
      source: 'gfi_model_id',
      ttoken: 'admin:common.model_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/models?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      translate: false,
    },
    {
      title: 'ui_has_opacity_slider',
      source: 'ui_has_opacity_slider',
      ttoken: 'admin:layers.has_opacity_slider',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
    },
    {
      title: 'layer_functionality_id',
      source: 'layer_functionality_id',
      ttoken: 'admin:layers.layer_functionality',
      type: 'picker',
      translate: true,
      items: {
        labels: [
          'map:layer.functionality.dkp_show_posjednici_vlasnici',
          'map:layer.functionality.dkp_show_posjednici',
          'map:layer.functionality.cjenovni_blok_custom_actions',
          'map:layer.functionality.odvoz_location_button',
          'map:layer.functionality.kuce_za_odmor_button',
          'map:layer.functionality.toggle_dof',
          'map:layer.functionality.google_maps_navigate_button'
        ],
        values: [
          1,
          2,
          3,
          4,
          5,
          6,
          7
        ],
      },
    },
  ],
};

export default MapLayer;
