import React from 'react';

// MUI
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

const MapCardHeaderButtonClose = (props: IconButtonProps) => (
  <IconButton
    color="inherit"
    aria-label="close"
    aria-controls="close"
    {...props}
  >
    <CloseIcon sx={{color: "white"}} />
  </IconButton>
);

export default MapCardHeaderButtonClose;
