import React, { ChangeEvent } from 'react';

// Material-UI Components
import TextField from '@mui/material/TextField';

// Types
import { TextFilterWrapperFn } from '@/@types/ui/Table';

/**
 * Filter wrapper function that returns the TextColumnFilter component
 *
 * @returns The SelectColumnFilter component
 */
const TextColumnFilter: TextFilterWrapperFn = () => (filter) => {
  const { column } = filter;
  const { id, filterValue, setFilter } = column;
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    if (value) {
      setFilter(value);
    } else {
      setFilter(undefined);
    }
  };
  return (
    <TextField
      value={filterValue}
      onChange={handleChange}
      size="small"
      margin="dense"
    />
  );
};

export default TextColumnFilter;
