import React from 'react';

// Material-UI Components
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import {
  SelectFilterWrapperFn,
  PickerValue,
  FilterFn,
  ISelectFilter,
} from '@/@types/ui/Table';

// Types
import { IDataControllerSub } from '@/@types/lib/dataControllerSub';
import { DCRecord } from '@/@types/lib/dataController';
import { PickerItem } from '@/@types/controls/controls';
import { PickerItemValue } from '@/@types/models/model';

/**
 * Filter wrapper function that returns the SelectColumnFilter component
 *
 * @param {ISubModelsWithData} subModelsWithData - array of sub models filed with data
 * @param {IPickerItems} fieldItems - picker items
 * @param {TFunction} t - translation function
 *
 * @returns The SelectColumnFilter component
 */
const SelectColumnFilter: SelectFilterWrapperFn = (
  subModelsWithData,
  fieldItems,
  t
) => {
  const SelectFn: FilterFn<ISelectFilter> = (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter, preFilteredRows } = column;

    const handleChange = (event: SelectChangeEvent<typeof filterValue>) => {
      const { value } = event.target;
      setFilter(value);
    };

    const options = (() => {
      const optionSet = new Set<PickerItem>();

      if (fieldItems) {
        fieldItems.labels.forEach((label, index) => {
          const foundValue = preFilteredRows?.find(
            (row: DCRecord) => row[id] === fieldItems.values[index]
          );

          if (foundValue !== undefined) {
            if (fieldItems.icons) {
              optionSet.add({
                color: 'primary',
                icon: fieldItems.icons[index],
                value: fieldItems.values[index],
                label,
              });
            } else {
              optionSet.add({
                value: fieldItems.values[index],
                label,
              });
            }
          }
        });
      } else if (subModelsWithData && subModelsWithData[id]) {
        const { records } = subModelsWithData[id];
        records.forEach((record) => {
          const foundValue = preFilteredRows?.find((row: DCRecord) => {
            const rowValue = row[id] as PickerItemValue;
            return Array.isArray(rowValue)
              ? rowValue.includes(record.value)
              : rowValue === record.value;
          });

          if (foundValue !== undefined) {
            optionSet.add(record);
          }
        });
      }

      return Array.from(optionSet);
    })();

    return (
      <Select
        value={filterValue ? String(filterValue) : ''}
        onChange={handleChange}
        MenuProps={{ variant: 'menu' }}
        variant="outlined"
        displayEmpty
        input={<OutlinedInput size="small" margin="dense" />}
      >
        <MenuItem value="">{t('common.all')}</MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={`select-${option.value}-${index}`}
            value={
              typeof option.value === 'boolean'
                ? option.value.toString()
                : option.value
            }
          >
            {option.icon ? (
              <Icon fontSize="small" style={{ color: option.color }}>
                {option.icon}
              </Icon>
            ) : null}
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return SelectFn;
};

export default SelectColumnFilter;
