import React, { useContext } from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import DialogContext, {
  DialogContextType,
} from '@/context/DialogContext/DialogContext';
import DataController from '@/lib/DataController';
import StatusDialog from './KomunalneStatusDialog';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelKomunalniObveznici from '@/models/komunalni_obveznici';
import modelObjekti from '@/models/objekti';
import modelStatusi from '@/models/obveznici_statusi';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import EnaDataCentricPage from '@/ui/EnaDataCentric/EnaDataCentricPage';

type KomunalniObvezniciDataCentricPageParams = {};

const KomunalniObvezniciDataCentricPage = (
  props: KomunalniObvezniciDataCentricPageParams
) => {
  const dialogContext = useContext<DialogContextType>(DialogContext);

  const showStatusDialog = () => {
    const dcStatusi = new DataController(
      modelStatusi,
      'ena/komunalni-obveznici/statusi'
    );
    dcStatusi
      .GetData()
      .then((resp) => {
        if (
          resp.success &&
          resp.data &&
          resp.data !== null &&
          Array.isArray(resp.data)
        ) {
          // statusFields.current = resp.data.slice();
          // console.log(resp.data);
          dialogContext.showDialog(StatusDialog, {
            fields: resp.data as DCRecord[],
          });
        }
      })
      .catch((resp) => console.log(resp));
  };

  return (
    <AppPage
      titlett="ena:page.komunalni_obveznici"
      needPermission={[Permissions.Ena]}
    >
      <EnaDataCentricPage
        primaryModel={modelKomunalniObveznici}
        primaryTitleToken="titles.komunalne_obveze"
        primaryRecordLocationField="lokacija_id"
        secondaryModel={modelObjekti}
        secondaryTitleToken="titles.objekti"
        baseRecordPath={modelKomunalniObveznici.apiPath}
        childRecordRelativePath="objekti"
        geomRelativePath="objekti-geom"
        allowShowImages
        mapId={21}
        mapModel={modelObjekti}
        filterByLocation
        allowLegend
        onLegendClick={showStatusDialog}
      />
    </AppPage>
  );
};

export default KomunalniObvezniciDataCentricPage;
