import React from 'react';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

//Types
import { SvgIconProps } from '@mui/material';

function EnaSettingIcon(props: SvgIconProps) {
  return <RoomPreferencesIcon {...props} />;
}

export default EnaSettingIcon;
