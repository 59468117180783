import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';

// Custom Components
import { GridContainer, GridItem } from '@/ui/Grid';
import PhotoCentricTable from './PhotoCentricTable';
// import PhotoCentricMiniMap from './PhotoCentricMiniMap';
import PhotoCentricThumbs from './PhotoCentricThumbs';
import useWindowSize, { Size } from '@/components/useWindowSize';
import LoaderContext, {
  LoaderContextType,
} from '@/context/LoaderContext/LoaderContext';
import SnackbarContext, {
  SnackbarContextType,
} from '@/context/SnackbarContext/SnackbarContext';
import DataController, { IDataController } from '@/lib/DataController';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IImageData } from '@/@types/views/KomunalniObveznici';

import { IModel } from '@/@types/models/model';
import { RowActionFn } from '@/@types/ui/Table';
import PhotoCentricMiniMap from './PhotoCentricMiniMap';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

type PhotoCentricPageParams = {
  titleToken: string;
  model: IModel;
  mapId: number;
  onCustomTableAction?: RowActionFn;
  viewName?: string;
  fetchGeomFromMW?: boolean;
  baseRecordPath?: string;
  geomRelativePath?: string;
};

const PhotoCentricPage = (props: PhotoCentricPageParams) => {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const [selectedRecordId, setSelectedRecordId] = useState<number>(0);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<IImageData | null>(null);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const mapRef = useRef<HTMLDivElement>(null);

  const size: Size = useWindowSize();

  const {
    titleToken,
    model,
    mapId,
    onCustomTableAction,
    viewName,
    fetchGeomFromMW,
    baseRecordPath,
    geomRelativePath = '',
  } = props;

  const [searchParams] = useSearchParams();

  const dc: IDataController = new DataController(model);

  const rec_id = searchParams.get('rec_id');
  const int_rec_id = rec_id ? parseInt(rec_id, 10) : undefined;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
          // if (onLoad) {
          //   onLoad(resp.data);
          // }
          if (resp.data.length === 0) {
            snackbarContext.showNotification('messages.nodata', 'warning');
          }
          if (int_rec_id !== undefined) {
            const rec = resp.data.find((x) => x.id === int_rec_id);
            if (rec) {
              setSelectedRecordId((rec?.id as number) || 0);
            }
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRecordSelect = (id: number) => {
    if (id === selectedRecordId) {
      setSelectedRecordId(0);
    } else {
      setSelectedRecordId(id);
      scrollIntoMap();
    }
    // if (!showImages && id) {
    //   setShowImages(true);
    // }
  };

  const scrollIntoMap = () => {
    if (!mdUp && mapRef.current) {
      mapRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  useEffect(() => {
    setShowImages(selectedRecordId !== 0);
  }, [selectedRecordId]);

  useEffect(() => {
    if (int_rec_id === undefined) {
      setSelectedRecordId(0);
    } else {
      const rec = records.find((x) => x.id === int_rec_id);
      if (rec) {
        setSelectedRecordId((rec?.id as number) || 0);
      }
    }
  }, [int_rec_id]);

  const firstRowHeight = size.height ? `${(size.height - 50) * 0.6}px` : '60%';
  const secondRowHeight = size.height ? `${(size.height - 50) * 0.4}px` : '40%';
  // const mapWidth = secondRowHeight;

  return (
    <Grid columns={24} container height={'100%'}>
      <Grid item xs={24}  sx={{ maxHeight: '60%', minHeight: {xs: '0', md: '60%'} }}>
        <PhotoCentricTable
          records={records}
          onRowSelect={handleRecordSelect}
          onRowAction={onCustomTableAction}
          rowSelectedCustom={selectedRecordId}
          titleToken={titleToken}
          dc={dc}
          viewName={viewName}
        />
      </Grid>
      <Grid  item sx={{ height: "40%" }} xs={24} md={9} lg={7} xl={5}>
        <div ref={mapRef} style={{width: "100%", height: "100%"}}>
            <PhotoCentricMiniMap
              onRecordSelect={handleRecordSelect}
              selectedRecordId={selectedRecordId}
              records={records}
              model={model}
              mapId={mapId}
              fetchGeomFromMW={fetchGeomFromMW}
              baseRecordPath={baseRecordPath}
              geomRelativePath={geomRelativePath}
            />
        </div>
      </Grid>
      <Grid item sx={{ height: {xs: "35%", md: "40%"}}} xs={24} md={15} lg={17} xl={19}>
        <PhotoCentricThumbs
          baseRecordPath={model.apiPath}
          recordId={selectedRecordId}
        />
      </Grid>
    </Grid>
  );
};

export default PhotoCentricPage;
