import React, { useMemo, useEffect } from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

// Types
import { Checkbox, Divider, FormControl, ListItemText } from '@mui/material';
import {
  FilterFn,
  IMultiFilter,
  MultiFilterWrapperFn,
  PickerValue,
} from '@/@types/ui/Table';
import { PickerItem } from '@/@types/controls/controls';
import { DCRecord } from '@/@types/lib/dataController';
import { PickerItemValue } from '@/@types/models/model';

/**
 * Filter wrapper function that returns the MultiColumnFilter component
 *
 * @param {ISubModelsWithData} subModelsWithData - array of sub models filed with data
 * @param {IPickerItems} fieldItems - picker items
 * @param {TFunction} t - translation function
 *
 * @returns The MultiColumnFilter component
 */
const MultiColumnFilter: MultiFilterWrapperFn = (
  subModelsWithData,
  fieldItems,
  t
) => {
  const MultiFilterFn: FilterFn<IMultiFilter> = (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter, preFilteredRows } = column;

    const options = (() => {
      const optionSet = new Set<PickerItem>();

      if (fieldItems) {
        fieldItems.labels.forEach((label, i) => {
          const foundValue = preFilteredRows?.find((row: DCRecord) => {
            const preFilteredValue = row[id] as PickerItemValue;
            const valueIteration = fieldItems.values[i];
            if (Array.isArray(preFilteredValue)) {
              return preFilteredValue.includes(valueIteration);
            }
            return preFilteredValue === valueIteration;
          });

          if (foundValue !== undefined) {
            optionSet.add({
              value: fieldItems.values[i],
              label,
            });
          }
        });
      } else if (subModelsWithData && subModelsWithData[id]) {
        const { records } = subModelsWithData[id];
        records.forEach((record) => optionSet.add(record));
      } else {
        preFilteredRows?.forEach((value: DCRecord) => {
          if (value[id]) {
            if (Array.isArray(value[id])) {
              (value[id] as PickerItemValue[]).forEach((element) => {
                optionSet.add({
                  value: element as PickerItemValue,
                  label: element as string,
                });
              });
            } else {
              optionSet.add({
                value: value[id] as PickerItemValue,
                label: value[id] as string,
              });
            }
          }
        });
      }

      return Array.from(optionSet);
    })();

    if (filterValue === undefined) {
      // If filterValue is undefined, that means filter is uninitialized. Set it to include all options.
      setFilter(options.map((option) => option.value));
    }

    const handleChange = (event: SelectChangeEvent<typeof filterValue>) => {
      const {
        target: { value },
      } = event;

      // On autofill we get a stringified value.
      const valueArray = !Array.isArray(value) ? value.split(',') : value;

      if (value.includes('all')) {
        setFilter(
          filterValue.length === options.length
            ? []
            : options.map((option) => option.value)
        );
      } else {
        setFilter(valueArray);
      }
    };

    const getRenderValue = (selected: PickerItemValue[]) => {
      const tags: string[] = [];

      if (Array.isArray(selected)) {
        selected.forEach((optValue) => {
          for (const option of options) {
            if (option.value === optValue) {
              tags.push(t(option.label));
            }
          }
        });
      }

      const joinedTags = tags.join(', ');

      return joinedTags.length > 35
        ? joinedTags.substring(0, 35).trim() + '...'
        : joinedTags;
    };

    return (
      <FormControl>
        <Select
          sx={{ minWidth: '125px' }}
          MenuProps={{
            variant: 'menu',
            MenuListProps: {
              sx: { padding: 0 },
            },
          }}
          variant="outlined"
          id="multiple-checkbox"
          multiple
          value={filterValue}
          input={<OutlinedInput size="small" margin="dense" />}
          renderValue={getRenderValue}
          onChange={handleChange}
        >
          <MenuItem value="all" sx={{ padding: 0 }}>
            <Checkbox
              checked={filterValue && filterValue.length === options.length}
              indeterminate={
                filterValue &&
                filterValue.length > 0 &&
                filterValue.length < options.length
              }
            />
            <ListItemText primary={t('common.all')} />
          </MenuItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          {options.map((option, index) => (
            <MenuItem
              key={'multi-filter-' + id + '-' + index}
              value={
                typeof option.value === 'boolean'
                  ? option.value.toString()
                  : option.value
              }
              sx={{
                padding: 0,
                '&.Mui-selected': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Checkbox
                checked={
                  filterValue ? filterValue.includes(option.value) : false
                }
              />
              <ListItemText primary={t(option.label)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  return MultiFilterFn;
};

export default MultiColumnFilter;
