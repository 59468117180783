import React, { useContext, useEffect, useRef, useState } from 'react';
import MapContext from '@/context/MapContext/MapContext';
import { MapContextType } from '@/context/MapContext/MapContext';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { Geometry, LineString, Polygon } from 'ol/geom';
import { buffer } from 'ol/size';
import OlSourceVector from "ol/source/Vector";


type HighlightAnimationProps = {
  features: any, // Feature<Geometry>[] | Point[] | VectorSource<Geometry>, // Features | Points | Points
  setSource: (source: any) => void
}


export const HighlightAnimation = (props: HighlightAnimationProps) => {
  const { features, setSource } = props;
  const mapContext = useContext(MapContext) as MapContextType;

  const [ vectorSource, setVectorSource ] = useState<VectorSource<any>>(new VectorSource);
  const layerLoaded = useRef<boolean>(false);
  // section because we need to reduce it bit by bit
  const colorSection = 'rgba(31,154,255, ';
  const colorMain = 'rgba(31,154,255, 1)';
  const colorDark = 'rgba(31,154,255, 0.2)';

  const currentAnimationFeatures = useRef<Array<any>>([]);
  const currentAnimationPoint = useRef<number>(0);

  const pointStyle = new Style({
    stroke: new Stroke({
    color: colorMain,
    width: 2,
    }),
    fill: new Fill({
    color: colorDark,
    }),
  });

  useEffect(() => {
    if (mapContext && mapContext !== null && mapContext.map !== null && layerLoaded.current === false) {
      // @ts-ignore
      mapContext.map.addLayer(new VectorLayer({
        className: 'ol-layer-animation-expanding',
        source: vectorSource,
        zIndex: 899
      }));
      layerLoaded.current = true;
    }
  }, [mapContext]);

  const animatePolygon = (feature: any, direction: number, color: number, maxColor: number, originalStyle: Style) => {
    if(direction === 1) {
      color += maxColor/75;
    } else {
      color -= maxColor/100;
    }
    
    const newStyle = new Style({
      stroke: new Stroke({
        color: colorMain,
        width: 4
      }),
      fill: new Fill({
        color: colorSection + String(0 + color),
      }),
    });
    feature.setStyle(newStyle);
    // vectorSource.addFeature(feature);

    // Recursive calls to the animation API
    if (direction === 1 && color <= maxColor) {
      requestAnimationFrame(() => animatePolygon(feature, 1, color, maxColor, originalStyle));
    } else if (color >= 0.5) {
      requestAnimationFrame(() => animatePolygon(feature, 2, color, maxColor, originalStyle));
    } else {
      // vectorSource.removeFeature(feature);
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(geom => geom !== feature.getGeometry());
        setSource(new OlSourceVector({}));
      }, 300);
    }
  }

  const animateLineString = (feature: any, direction: number, width: number, maxWidth: number, originalStyle: Style) => {
    if(direction === 1) {
      width += maxWidth/50;
    } else {
      width -= maxWidth/75;
    }
    
    const newStyle = new Style({
      stroke: new Stroke({
        color: colorMain,
        width: width
      }),
      fill: new Fill({
        color: colorDark,
      }),
    });
    feature.setStyle(newStyle);
    // vectorSource.addFeature(feature);

    // Recursive calls to the animation API
    if (direction === 1 && width <= maxWidth) {
      requestAnimationFrame(() => animateLineString(feature, 1, width, maxWidth, originalStyle));
    } else if (width >= 4) {
      requestAnimationFrame(() => animateLineString(feature, 2, width, maxWidth, originalStyle));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(geom => geom !== feature.getGeometry());
        setSource(new OlSourceVector({}));
      }, 300);
    }
  }

  const animatePoint = (feature: any, direction: number, radius: number, maxRadius: number, originalStyle: Style, prevCircleFeature?: any) => {
    if(direction === 1) {
      radius += maxRadius/50;
    } else {
      radius -= maxRadius/75;
    }
    
    // @ts-ignore
    const circleGeometry = new Circle(feature && feature.getGeometry() && feature.getGeometry()?.getCoordinates() ? feature.getGeometry().getCoordinates() : [1], radius);
    const circleFeature = new Feature({ id: 1, geometry: circleGeometry });

    if(prevCircleFeature) {
      vectorSource.removeFeature(prevCircleFeature);
    }
    
    circleFeature.setStyle(pointStyle);
    vectorSource.addFeature(circleFeature);

    // Recursive calls to the animation API
    if (direction === 1 && radius <= maxRadius) {
      requestAnimationFrame(() => animatePoint(feature, 1, radius, maxRadius, originalStyle, circleFeature));
    } else if (radius >= 0) {
      requestAnimationFrame(() => animatePoint(feature, 2, radius, maxRadius, originalStyle, circleFeature));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(geom => geom !== feature.getGeometry());
        setSource(new OlSourceVector({}));
        vectorSource.removeFeature(circleFeature);
      }, 1);
    }
  }

  useEffect(() => {
    if (layerLoaded.current && features && features.length > 0) {
      vectorSource.clear();

      for(const feature of features) {
        if(currentAnimationFeatures.current.indexOf(feature.getGeometry()) === -1) {
          const originalStyle = feature.getStyle();

          if(feature.getGeometry() instanceof Polygon) {
            currentAnimationFeatures.current = [...currentAnimationFeatures.current, feature.getGeometry()];
            requestAnimationFrame(() => animatePolygon(feature, 1, 0.5, 0.9, originalStyle));
          }
          if(feature.getGeometry() instanceof LineString) {
            currentAnimationFeatures.current = [...currentAnimationFeatures.current, feature.getGeometry()];
            requestAnimationFrame(() => animateLineString(feature, 1, 4, 12, originalStyle));
          }
          if(feature.getGeometry() instanceof Point && feature.id_ !== currentAnimationPoint.current) {
            currentAnimationPoint.current = feature.id_;
            requestAnimationFrame(() => animatePoint(feature, 1, 5, 15, originalStyle)); 
            setTimeout(() => {
              currentAnimationPoint.current = 0;
            }, 1000);
          }
        }
      }
    }
  }, [features]);

  return null;
}