import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const GrobnaMjesta: IModel = {
  title: 'Grobna mjesta',
  apiPath: 'groblja/grobna-mjesta',
  forms: {
    default: {
      fields: [
        'id',
        'id_grmj',
        'odjel',
        'polje',
        'red',
        'broj',
        'polozaj',
        'vrsta_id',
        'status_id',
        'knjiga',
        'knjiga_strana',
        'is_slobodno',
        'is_spomenik',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'id_grmj',
        'odjel',
        'polje',
        'red',
        'broj',
        'polozaj',
        'vrsta_id',
        'status_id',
        'knjiga',
        'knjiga_strana',
        'is_slobodno',
        'is_spomenik',
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'id_grmj',
      source: 'id_grmj',
      ttoken: 'groblja:grobna_mjesta.id_grmj',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'odjel',
      source: 'odjel',
      ttoken: 'groblja:grobna_mjesta.odjel',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'polje',
      source: 'polje',
      ttoken: 'groblja:grobna_mjesta.polje',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'red',
      source: 'red',
      ttoken: 'groblja:grobna_mjesta.red',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'broj',
      source: 'broj',
      ttoken: 'groblja:grobna_mjesta.broj',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'polozaj',
      source: 'polozaj',
      ttoken: 'groblja:grobna_mjesta.polozaj',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'duzina',
      source: 'duzina',
      ttoken: 'groblja:grobna_mjesta.duzina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'sirina',
      source: 'sirina',
      ttoken: 'groblja:grobna_mjesta.sirina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'povrsina',
      source: 'povrsina',
      ttoken: 'groblja:grobna_mjesta.povrsina',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'vrsta_id',
      source: 'vrsta_id',
      ttoken: 'groblja:grobna_mjesta.vrsta',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/grobna-mjesta/vrste',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'status_id',
      source: 'status_id',
      ttoken: 'groblja:grobna_mjesta.status',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/grobna-mjesta/statusi',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'knjiga',
      source: 'knjiga',
      ttoken: 'groblja:grobna_mjesta.knjiga',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'knjiga_strana',
      source: 'knjiga_strana',
      ttoken: 'groblja:grobna_mjesta.knjiga_strana',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'is_slobodno',
      source: 'is_slobodno',
      ttoken: 'groblja:grobna_mjesta.slobodno',
      type: 'boolean',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'is_spomenik',
      source: 'is_spomenik',
      ttoken: 'groblja:grobna_mjesta.spomenik',
      type: 'boolean',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default GrobnaMjesta;
