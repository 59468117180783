// These are some user fields that are present in multiple UI views - they all look on same DB table
// They can be added to UI models...

import { IFieldText, IFieldPassword, IFieldDateTime } from "@/@types/models/model";

const username: IFieldText = {
  title: "Username",
  source: "username",
  ttoken: "users.username",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  export: {
    width: 15
  }
};

const password: IFieldPassword = {
  title: "Lozinka",
  source: "password",
  ttoken: "users.password",
  type: "password",
  validation: {
    required: true
  }
};

const password_confirm: IFieldPassword = {
  title: "Potvrda lozinke",
  source: "password_confirm",
  ttoken: "users.password_confirm",
  type: "password",
  validation: {
    required: true
  }
};

const first_name: IFieldText = {
  title: "Ime",
  source: "first_name",
  ttoken: "users.first_name",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  export: {
    width: 25
  }
};

const last_name: IFieldText = {
  title: "Prezime",
  source: "last_name",
  ttoken: "users.last_name",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  export: {
    width: 25
  }
};

const last_login_on: IFieldDateTime = {
  title: "Datum zadnje prijave",
  source: "last_login_on",
  ttoken: "common.last_login_on",
  type: "datetime",
  readonly: true,
  export: {
    width: 20
  }
};

const is_anoniman: IFieldText = {
  title: "Anonimni korisnik",
  source: "is_anoniman",
  ttoken: "user_states.anoniman",
  readonly: true,
  type: "text",
  items: {
    labels: ["common.ne", "common.da"],
    values: [false, true],
    default: false
  },
  translate: true
}



export const userFields = {
  username: username,
  first_name: first_name,
  last_name: last_name,
  password: password,
  password_confirm: password_confirm,
  last_login_on: last_login_on
};
