import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useRef } from 'react';

//MUI Components
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';

//Custom components
import MapContext from '@/context/MapContext/MapContext';
import { findControl, getDefinedOptions, getEvents } from '@/lib/olHelpers';
import Status from './ol5-status';
import { getOptimalTextColorForBackgroundColor } from '@/lib/colorUtilities';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { IStatusRoot } from '@/@types/components/Map/Controls/Status';

const StatusRoot: FunctionComponent<IStatusRoot> = (
  props: React.PropsWithChildren<IStatusRoot>
) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const statusRef = useRef(props.ref);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (statusRef.current) {
      const options = {
        element: statusRef.current,
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new Status(definedOptions);

      if (props.id) {
        control.set('id', props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Status);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  return (
    <Box
      id="status-control"
      ref={statusRef}
      sx={{
        position: 'absolute',
        maxWidth: 300,
        height: 20,
        backgroundColor: 'primary.main',
        opacity: 0.75,
        color: getOptimalTextColorForBackgroundColor(theme.palette.primary.main),
        bottom: mdUp ? 36 : 42,
        right: 8,
        borderRadius: 1,
      }}
    >
      {props.children}
    </Box>
  );
};

export default StatusRoot;
