import React, { FunctionComponent, useContext, useEffect } from "react";

//Types
import { ButtonGroupProps } from "@mui/material";
import ButtonGroupControlRoot from "./ButtonGroupControlRoot";


export interface IButtonGroupControl extends ButtonGroupProps {
  id: string
  order?: number
}

const ButtonGroupControl: FunctionComponent<IButtonGroupControl> = (props) => {

  return (
    <ButtonGroupControlRoot {...props} >
      {props.children}
    </ButtonGroupControlRoot>
  )
};

export default ButtonGroupControl;

