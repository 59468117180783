import React, {
  useState,
  useContext,
  SyntheticEvent,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI Core Components
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';

// Custom Components
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';
import useFormController from '@/components/useFormController';

import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import DialogActionButtonAdd from '@/ui/Dialog/ActionButtons/DialogActionButtonAdd';

import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';

// Types
import {
  ClosingDetails,
  FormControllerProps,
} from '@/@types/components/formController';
import Permissions from '@/lib/permissions';

const MapAddViewDialog = (props: any) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();
  const { dc, onClose } = props;

  const { record, validation, fields, onFieldChange, doInsert } =
    useFormController(props as FormControllerProps);

  function handleFieldChange(value: any, source: string) {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  function handleInsert() {
    if (doInsert) {
      doInsert().then((result) => {
        if (result.success) {
          close({ dataChanged: true, action: 'insert' });
        } else if (result.error) {
          const msg = t('error.addRecord') + result.error;
          snackbarContext.showNotification(msg, 'error');
        } else if (result.validationPass === false) {
          // do nothing
        }
      });
    }
  }

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: 'cancel' });
  };

  const close = (result: ClosingDetails) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open maxWidth="xs" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <DialogToolbarHeading>
            {t('titles.add_map_view')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={dc.getFormFieldsNames('default')}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode="insert"
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonAdd
          variant="contained"
          onClick={() => handleInsert()}
          needPermission={[Permissions.Private]}
        />
      </DialogActions>
    </DraggableDialog>
  );
};

export default MapAddViewDialog;
