import * as React from 'react';
import { Fragment, FunctionComponent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

// MUI Core Components
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

// Custom Components

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import OlSourceVector from 'ol/source/Vector';
import OlPoint from 'ol/geom/Point';
import OlLayerVector from 'ol/layer/Vector';

import OlFeature from 'ol/Feature';
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';
import { transform } from 'ol/proj';
import { Input, Typography } from '@mui/material';
import { routePointStyle } from '@/components/Map/mapStyles';

// Custom Components
import MapContext from '@/context/MapContext/MapContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import generateDxf from '@/lib/dxfGenerator';

// Types
import { MapContextType } from '@/context/MapContext/MapContext';
import api from '@/lib/api/useApi';
import exportCSV from '@/lib/exportCSV';
import ProfileChart from '@/ui/Charts/ProfileChart';
import { DrawingType } from '@/@types/common';
import { GridContainer, GridItem } from '@/ui/Grid';
import { FloatNumberControl } from '@/controls';
import { IFieldNumeric } from '@/@types/models/model';
import useApi from '@/lib/api/useApi';

// Types
export interface IMeasuresPane {
  changeDrawType: (type: DrawingType) => void;
  handleEraseMeasurements: () => void;
  drawType: DrawingType | undefined;
  elevationPointsFeatures: any;
  measurementsSource: any;
  profileSettings: any;
}
const LINE = 'LineString';
const POLY = 'Polygon';
const PT = 'Point';
const PROFILE = 'Profile';

type Point2D = [number, number];
type Point3D = [number, number, number];

const buttonStyle = {
  width: '100%',
};

const floatField: IFieldNumeric = {
  title: "",
  source: "preciznost_profila",
  ttoken: "",
  type: "numeric"
}

const MeasuresPane: FunctionComponent<IMeasuresPane> = (props) => {
  const mapContext = React.useContext(MapContext) as MapContextType;
  const loaderContext = React.useContext(LoaderContext);
  const { t } = useTranslation();

  const {
    changeDrawType,
    handleEraseMeasurements,
    drawType,
    elevationPointsFeatures,
    measurementsSource,
    profileSettings,
  } = props;

  const exportEnabled = checkIfProfileExist(elevationPointsFeatures);

  const [series, setSeries] = React.useState<{
    name: string;
    data: number[][];
  }>();
  const [profileData, setData] = React.useState<any>([]);
  const [precision, setPrecision] = React.useState<number>(0.15);
  const [precisionDisplay, setPrecisionDisplay] = React.useState<string>("0,15");
  const [floatHelperText, setFloatHelperText] = React.useState<string>("");
  const [minPrecision, setMinPrecision] = React.useState<number>(0.1)
  const pointOnRouteSourceRef = React.useRef<OlSourceVector<OlPoint>>(
    new OlSourceVector({})
  );
  const [pointOnRouteLayer, setPointOnRouteLayer] = React.useState<
    OlLayerVector<OlSourceVector<OlPoint>>
  >(
    new OlLayerVector({
      source: pointOnRouteSourceRef.current,
      style: routePointStyle,
    })
  );
  const [redrawProfileToken, setRedrawToken] = React.useState<boolean>(false);
  
  profileSettings ? profileSettings.width.toFixed(2) : null;

  const api_instance = useApi();

  // define proj
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  // TODO: uncomment if we get DEM, commented to improve performance
  React.useEffect(()=>{
    const path = "profile/params"
  
    loaderContext.toggleLoading(true);
    api_instance.get(path).then((resp)=>{
      if(resp.success){
        const data = resp.data as any
        if(!data) return;
        const value = Math.round(data.width*100) / 100
        setMinPrecision(value);
        setPrecision(value);
        setPrecisionDisplay(value.toString());
      }
    }).catch((e)=>{
      // console.log(e)
    }).finally(()=>{
      loaderContext.toggleLoading(false);
    });
  }, [])

  React.useEffect(() => {
    if (mapContext.map) {
      pointOnRouteLayer.setMap(mapContext.map);
    }
  }, [mapContext.map]);

  React.useEffect(() => {
    if (exportEnabled && drawType === 'Profile') {
      const sendingData: any = [];
      let first_line_uuid: string | null = null;

      elevationPointsFeatures?.forEach((feature: any) => {
        const featureProps = feature.getProperties();
        if (featureProps) {
          const { alt, dh, line_uuid } = featureProps;

          if (first_line_uuid == null) first_line_uuid = line_uuid;
          if (sendingData[line_uuid]) {
            sendingData[line_uuid] = [
              ...sendingData[line_uuid],
              {
                alt,
                dh,
                line_uuid,
                coordinates: featureProps.geometry.getCoordinates(),
              },
            ];
          } else {
            sendingData[line_uuid] = [
              {
                alt,
                dh,
                line_uuid,
                coordinates: featureProps.geometry.getCoordinates(),
              },
            ];
          }
        }
      });

      /*
        Sending data should now look like 
          [
            [ point1, point2, point3...]
          ]

          later on we can make it 
          [
            line1_id: [ point1, point2],
            line2_id: [ point1, point2, point3...]
          ]
          when we decide to show mutiple routes on chart
          
          where each point contains:
            alt: altitude
            dh: altitude change from the prevoius point
            coordinates: x,y of the feature
            line_uuid: used to group points for a line
            
          backend will only need coordinates from the line the user wants to draw on the chart
          its possible to use different styles for drawing each route and drawing multipe routes to chart
          
          */

      // API CALL TO BACKEND WITH LINES
      const path = 'profile/generate';
      if (first_line_uuid == null) return;

      const myLine = sendingData[first_line_uuid];
      let wkt = 'LINESTRING(';
      wkt += myLine
        .map((p: any) => {
          const coords = p.coordinates;
          return `${coords[0]} ${coords[1]}`;
        })
        .join(',');
      wkt += ')';

      loaderContext.toggleLoading(true);
      api_instance
        .post(path, { line_wkt: wkt, precision })
        .then((resp) => {
          if (resp.success) {
            const data = resp.data as any;
            if (!data) return;
            setData(data);
            setSeries({
              name: '',
              data: data.route.map((c: number[]) => [
                Math.round(c[0]),
                c[1].toFixed(2),
              ]) as number[][],
            });
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loaderContext.toggleLoading(false);
        });
    }
  }, [elevationPointsFeatures, drawType, redrawProfileToken]);

  // Temporary function that checks if feature has line_uuid property which currently only profile points do
  function checkIfProfileExist(features: any) {
    const profileFeatures = features.filter((feature: any) => {
      const properties = feature.getProperties();
      return 'line_uuid' in properties;
    });

    return profileFeatures.length > 0;
  }

  const handleChartMouseEnter = (e: any, chart: any, options: any) => {
    const ind = options.dataPointIndex;
    const m = chart.data.twoDSeriesX[ind];
    const last = chart.data.twoDSeriesX[chart.data.twoDSeriesX.length - 1];
    const mrel = m / last;

    const geom = measurementsSource?.getFeatures()[0]?.getGeometry(); // selectedRoute?.feature.getGeometry();
    if (geom) {
      const p = geom.getCoordinateAt(mrel);
      const pFeatures = pointOnRouteSourceRef.current.getFeatures();
      if (Array.isArray(pFeatures) && p) {
        if (pFeatures.length === 0) {
          pointOnRouteSourceRef.current.addFeature(
            new OlFeature<OlPoint>({
              geometry: new OlPoint(p),
            })
          );
        } else {
          const pf = pFeatures[0];
          pf.setGeometry(new OlPoint(p));
        }
      }
    }
  };

  const handleChartMouseLeave = (e: any, chart: any, options: any) => {
    pointOnRouteSourceRef.current.clear();
  };

  const createXmlString = (lines: number[][][]): string => {
    let result =
      '<gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" version="1.1" creator="runtracker"><metadata/><trk><name></name><desc></desc>';
    result += lines.reduce((accum, curr) => {
      let segmentTag = '<trkseg>';
      segmentTag += curr
        .map(
          (point) =>
            `<trkpt lat="${point[1]}" lon="${point[0]}" ><ele>${point[2]}</ele></trkpt>`
        )
        .join('\n');
      segmentTag += '</trkseg>';

      return (accum += segmentTag);
    }, '');
    result += '</trk></gpx>';
    return result;
  };

  const handleDownloadGPX = () => {
    const profileData4326 = profileData.points
      .flat()
      .map((coord3857: any, index: number) => [
        ...transform(coord3857, 'EPSG:3857', 'EPSG:4326'),
        parseFloat(series?.data[index][1].toString() || '0'),
      ]);
    const lines = profileData ? [profileData4326] : [];
    const xml = createXmlString(lines);
    const url = `data:text/json;charset=utf-8,${encodeURIComponent(xml)}`;
    const link = document.createElement('a');
    link.download = `profile.gpx`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadDXF = () => {
    const pointsXY: Point2D[] = profileData.points.flat();

    // Map through each point and add relevant altitude data to it
    const pointsXYZ: Point3D[] = pointsXY.map((point: any, index: number) => {
      const zVal = parseFloat(series?.data[index][1].toString() || '0');
      const pointXYZ = point.concat(zVal);
      return pointXYZ;
    });

    const dxfContent = generateDxf(pointsXYZ);
    const blob = new Blob([dxfContent], {
      type: 'application/dxf;charset=utf-8',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'profile.dxf';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = () => {
    let profileCsvContent = 'x, y, z\n';
    console.log(profileData.points);
    profileData.points.flat().forEach((dataPoint: any, index: number) => {
      profileCsvContent += `${dataPoint[0]}, ${dataPoint[1]}, ${parseFloat(
        series?.data[index][1].toString() || '0'
      )}\n`;
    });
    console.log(profileCsvContent);
    if (profileCsvContent) {
      exportCSV(profileCsvContent, 'profile.csv');
    }
  };

  const handleChartDataMounted = () => {
    // console.log("Data mounted");
    // loaderContext.toggleLoading(false);
  };

  const handleChange = (value: string | number | null, source: string) => {
    if (typeof value === "number") {
      setPrecisionDisplay(value.toString());
    } else if (typeof value === "string") {
        value = value.replace(",",".");
        setPrecisionDisplay(value);
    } else if (value === null) {
        setPrecisionDisplay("");
    }
  }

  const handleBlur = () => {
    const value = precision;
    const numValue = parseFloat(precisionDisplay);
    if (isNaN(numValue) || numValue < minPrecision) {
      setFloatHelperText("map:measure.minimum_precision");
      setPrecision(minPrecision);
      setPrecisionDisplay(minPrecision.toString());
    } else {
        setFloatHelperText("")
        setPrecision(numValue);
    }
  }

  const handleKeyDown = (evt: any) => {
    if (evt.key === "Enter") {
      handleBlur();
      setRedrawToken((prev:boolean) => {return !prev})
    }
    if (evt.key === "Escape") {
      setPrecisionDisplay("");
    }
  }

  return (
    <Box sx={{ padding: '8px' }}>
      <GridContainer style={{ marginBottom: '10px' }}>
        <GridItem xs={6}>
          <Tooltip title={t('map:measure.tooltips.line') as string}>
            <Button
              value="length"
              color="primary"
              onClick={() => changeDrawType(LINE)}
              variant={drawType === LINE ? 'contained' : 'outlined'}
              startIcon={<i className="fas fa-ruler-horizontal" />}
              sx={buttonStyle}
            >
              {t('map:measure.buttons.line')}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem xs={6}>
          <Tooltip title={t('map:measure.tooltips.area') as string}>
            <Button
              value="area"
              color="primary"
              onClick={() => changeDrawType(POLY)}
              variant={drawType === POLY ? 'contained' : 'outlined'}
              startIcon={<i className="fas fa-vector-square" />}
              sx={buttonStyle}
            >
              {t('map:measure.buttons.area')}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem xs={6}>
          <Tooltip title={t('map:measure.tooltips.point') as string}>
            <Button
              value="point"
              color="primary"
              onClick={() => changeDrawType(PT)}
              variant={drawType === PT ? 'contained' : 'outlined'}
              startIcon={<i className="fas fa-map-marker-alt" />}
              sx={buttonStyle}
            >
              {t('map:measure.buttons.point')}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem xs={6}>
          <Tooltip title={t('map:measure.tooltips.elevation') as string}>
            <Button
              value="elevation"
              color="primary"
              disabled={profileSettings === undefined}
              onClick={() => changeDrawType(PROFILE)}
              variant={drawType === PROFILE ? 'contained' : 'outlined'}
              startIcon={<i className="fas fa-chart-area" />}
              sx={buttonStyle}
            >
              {t('map:measure.buttons.elevation')}
            </Button>
          </Tooltip>
        </GridItem>
      </GridContainer>
      <Divider sx={{ mb: 1 }} />
      <GridContainer>
        <GridItem xs={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleEraseMeasurements()}
            startIcon={<i className="fas fa-trash" />}
            sx={buttonStyle}
          >
            {t('map:measure.buttons.erase')}
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Typography>{t('map:measure.precision')}</Typography>
          {
            <FloatNumberControl
            sx={{width: "40%"}}
            variant="standard"
            field={floatField}
            value={precisionDisplay}
            onChange={handleChange}
            formMode={"form"}
            controlMode={"edit"}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            helperText={t(floatHelperText, {precision: minPrecision.toString()})}
            FormHelperTextProps={{error: true}}
          />
          }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setRedrawToken((prev: boolean) => !prev);
            }}
            startIcon={<i className="fas fa-refresh" />}
            disabled={!exportEnabled}
            sx={{ marginLeft: 2 }}
          >
            {t('map:measure.buttons.draw_profile')}
          </Button>
        </GridItem>
      </GridContainer>
      {exportEnabled && drawType === 'Profile' ? (
        <>
          <Box sx={{ paddingTop: 2 }}>
            <ProfileChart
              title={t('map:route.headings.profile')}
              // @ts-ignore
              series={series}
              handleChartMouseEnter={handleChartMouseEnter}
              handleChartMouseLeave={handleChartMouseLeave}
              handleChartDataMounted={handleChartDataMounted}
            />
          </Box>
          <GridContainer>
            <GridItem xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDownloadCSV}
                startIcon={<i className="fas fa-download" />}
                sx={buttonStyle}
                disabled={!exportEnabled}
              >
                CSV
              </Button>
            </GridItem>
            <GridItem xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDownloadDXF}
                startIcon={<i className="fas fa-download" />}
                sx={buttonStyle}
                disabled={!exportEnabled}
              >
                DXF
              </Button>
            </GridItem>
            <GridItem xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDownloadGPX}
                startIcon={<i className="fas fa-download" />}
                sx={buttonStyle}
                disabled={!exportEnabled}
              >
                GPX
              </Button>
            </GridItem>
          </GridContainer>
        </>
      ) : null}
    </Box>
  );
};

export default MeasuresPane;
