import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Ukopani: IModel = {
  title: 'Ukopani',
  apiPath: 'groblja/grobna-mjesta/{grobno_mjesto_id}/ukopani',
  forms: {
    default: {
      fields: ['id', 'ime', 'prezime', 'godina', 'is_povijesna_osoba'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'ime', 'prezime', 'godina', 'is_povijesna_osoba'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'grobno_mjesto_id',
      source: 'grobno_mjesto_id',
      ttoken: 'groblja:grobna_mjesta.grobno_mjesto',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/grobna-mjesta',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
    },
    {
      title: 'ime',
      source: 'ime',
      ttoken: 'users.first_name',
      type: 'text',
      readonly: true,
    },
    {
      title: 'prezime',
      source: 'prezime',
      ttoken: 'users.last_name',
      type: 'text',
      readonly: true,
    },
    {
      title: 'godina',
      source: 'godina',
      ttoken: 'common.year',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'is_povijesna_osoba',
      source: 'is_povijesna_osoba',
      ttoken: 'groblja:grobna_mjesta.povijesna_osoba',
      type: 'boolean',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
      },
      readonly: true,
    },
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default Ukopani;
