import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Custom components
import {
  MultiColumnFilter,
  SelectColumnFilter,
  TextColumnFilter,
  NumberRangeFilter,
} from '@/ui/Table/Filters';
import DateColumnFilter from '@/ui/Table/Filters/DateColumnFilter';
import DateYearColumnFilter from '@/ui/Table/Filters/DateYearColumnFilter';
import { sortBasic, sortLink, sortDateTime } from '@/lib/table/sort';
import { filterDate, filterDateYear, filterMulti, filterRange } from '@/lib/table/filter';
import DataControllerSubModel from '@/lib/DataControllerSubModel';
import Table from '@/ui/Table/Table';
import TableGenerator from '@/lib/TableGenerator';
import TableCheckbox from './TableCheckbox';

// Context
import UserContext from '@/context/UserContext/UserContext';

// Models
import radio from '@/models/submodels/radio';
import checkbox from '@/models/submodels/checkbox';
import picker from '@/models/submodels/picker';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import {
  SetFilterFn,
  ISubModelsWithData,
  IColumnInfoState,
  ITableBaseProps,
  AllowRowActionFn,
  RowActionFn,
} from '@/@types/ui/Table';
import { FieldAny, IFieldPickerModel } from '@/@types/models/model';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData, TValue> {
    filterComponent: (props: any) => any;
    isCustomFilterComponent: boolean;
  }
}

export interface IModelTableProps extends ITableBaseProps {
  viewName?: string;
  allowRowAction: AllowRowActionFn;
  onRowAction: RowActionFn;
  onRefresh?: () => void;
  allowSearch?: boolean;
  unselectRow?: boolean;
}

type PickersAnalysisType = {
  done: boolean,
  needData: boolean
};

type PDCStatusType = {
  loaded: boolean;
  error: boolean;
  dict: ISubModelsWithData
}

const ModelTable: FC<IModelTableProps> = (props) => {
  const [pickersAnalysis, setPickersAnalysis] = useState<PickersAnalysisType>({ done: false, needData: false});
  const [pdcStatus, setPdcStatus] = useState<PDCStatusType>({loaded: false, error: false, dict: {}});
  const [tableReady, setTableReady] = useState<boolean>(false);
  const [columnInfo, setColumnInfo] = useState<IColumnInfoState>({
    columns: [],
    hiddenColumnNames: [],
  });

  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const {
    dc,
    viewName,
    records,
    children,
    allowAdd,
    allowColumnPicker,
    allowExport,
    allowFilter,
    allowSelection,
    allowRowAction,
    allowLegend = false,
    onRowAction,
    onRowClick,
    onDoubleClick,
    onRefresh,
    onLegendClick,
    tableName,
    allowSearch = true,
    addLabel,
    handleAdd,
    title,
    rowSelectedCustom,
    unselectRow,
    getRowStyle,
    size,
    defaultPageSize,
    smallButtons,
    disableControls,
  } = props;

  const viewFields = dc.getViewFields(viewName || '');
  const src = dc.getSource();
  const identifier = src + viewName;

  const tg = new TableGenerator(t, onRowAction, allowRowAction);

  useEffect(() => {
    analyzePickers();
   }, [])
 
   useEffect(() => {
     resetReadinessChecks();
   }, [viewName]);

   useEffect(() => {
    if (pickersAnalysis.done === false) {
      analyzePickers();
    } else if (pickersAnalysis.done && pickersAnalysis.needData) {
      loadPickersData();
    } else if (pickersAnalysis.done && pickersAnalysis.needData === false) {
      prepareColumns({});
    }
   }, [pickersAnalysis])
 
   useEffect(() => {
     if (pdcStatus.loaded && pdcStatus.error === false) {
       prepareColumns(pdcStatus.dict);
     }
   }, [pdcStatus]);

   useEffect(() => {
    if (columnInfo) {
      setTableReady(true);
    }
   }, [columnInfo])

  const resetReadinessChecks = () => {
    setPickersAnalysis({ done: false, needData: false});
    setPdcStatus({loaded: false, error: false, dict: {}});
    setTableReady(false);
  }

  const analyzePickers = () => {
    const fieldsWithModel = viewFields.filter(
      (f: any) =>
        [
          'picker',
          'checkbox',
          'radio',
          'boolean',
          'active',
          'iconStatus',
        ].indexOf(f.type) !== -1 && f.hasOwnProperty('subModel')
    ) as Array<IFieldPickerModel>;

    if (fieldsWithModel.length === 0) {
      setPickersAnalysis({ done: true, needData: false});
    } else {
      setPickersAnalysis({ done: true, needData: true});
    }
  }

  const loadPickersData = () => {
    const fieldsWithModel = viewFields.filter(
      (f: any) =>
        [
          'picker',
          'checkbox',
          'radio',
          'boolean',
          'active',
          'iconStatus',
        ].indexOf(f.type) !== -1 && f.hasOwnProperty('subModel')
    ) as Array<IFieldPickerModel>;

    const pdcs: ISubModelsWithData = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
        f.type === 'picker'
          ? picker
          : f.type === 'radio'
          ? radio
          : f.type === 'checkbox'
          ? checkbox
          : null;

      if (modelType !== null) {
        Object.assign(pdcs, {
          [f.source]: new DataControllerSubModel(modelType, f),
        });
      }
    });

    const getDataPromises = Object.keys(pdcs).map((f) => pdcs[f].GetData());

    Promise.all(getDataPromises)
    .then(() => {
      setPdcStatus({loaded: true, error: false, dict: pdcs});
    })
    .catch(() => {
      setPdcStatus({loaded: true, error: true, dict: pdcs});
    })
    .finally(() => {
    });

  }

  const prepareColumns = (pdc: ISubModelsWithData) => {
    const viewFields = dc.getViewFields(viewName || '');

    const columns = [];

    viewFields.map((f: any) => {
      const renderFn = tg.generateRenderFunction(f, pdc);
      const sortType = getSortType(f);
      columns.push({
        accessorFn: (row: DCRecord) =>
          renderFn({
            value: row[f.source],
            row,
            subModelsWithData: pdc[f.source]?.records,
          }),
        // @ts-ignore
        cell: (props) => (
          <>
            {Array.isArray(props.getValue())
              ? props.getValue().join(', ')
              : props.getValue()}
          </>
        ),
        id: f.source,
        header: f.ttoken ? t(f.ttoken) : t(f.title),
        enableFilter: f.filter || false,
        enableColumnFilter: f.filter || false,
        enableSorting: f.sort === undefined || f.sort,
        sortingFn: sortType,
        filterFn: getFilterCompareFn(f),
        meta: {
          filterComponent: getFilterControl(f),
        },
      });
    });

    if (allowSelection == 'many') {
      columns.push({
        id: 'select',
        // @ts-ignore
        header: ({ table }: { table: Table<DCRecord> }) => (
          <TableCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: Row<DCRecord> }) => (
          <div className="px-1">
            <TableCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        enableSorting: false,
      });
    }

    const hiddenColumnNames = dc.getHiddenViewFieldsNames(viewName || '');

    setColumnInfo({
      columns,
      hiddenColumnNames,
    });
  };

  /**
   * Function that return the Filter Control based on Field type
   *
   * @param f - Field
   * @returns The column filter component for that Field type
   */
  const getFilterControl = (f: FieldAny) => {
    switch (f.type) {
      case 'text':
      case 'mail':
      case 'link':
      case 'multiline':
        return TextColumnFilter();
      case 'radio':
      case 'boolean':
      case 'iconStatus':
      case 'active':
      case 'picker':
        return SelectColumnFilter(
          pdcStatus.dict,
          f.items,
          t
        );
      case 'date':
      case 'datetime':
        if (!f.filterType || f.filterType === 'default')
          return DateColumnFilter(t);
        else if (f.filterType === 'year') 
          return DateYearColumnFilter(
            pdcStatus.dict,
            f.items,
             t)
      case 'checkbox':
      case 'array':
        return MultiColumnFilter(
          pdcStatus.dict,
          f.items,
          t
        );
      case 'currency':
      case 'wholenum':
      case 'numeric':
        return NumberRangeFilter();
      default:
        return null;
    }
  };

  /**
   * Function that return the Filter compare function on Field type
   *
   * @param f - Field
   * @returns The column filter compare function for that Field type
   */
  const getFilterCompareFn = (f: FieldAny) => {
    switch (f.type) {
      case 'radio':
      case 'boolean':
      case 'iconStatus':
        return (row: Row<DCRecord>, columnid: string, currValue: unknown) =>
          row.original[columnid] === currValue;
      case 'picker':
        return (row: Row<DCRecord>, columnid: string, currValue: any) => {
          if (f.multi) {
            return filterMulti(row.original[columnid] as number[], currValue);
          }
          return row.original[columnid] === currValue;
        };
      case 'active':
        return (row: Row<DCRecord>, columnid: string, currValue: unknown) =>
          currValue === 'all' || row.original[columnid] === currValue;
      case 'checkbox':
      case 'array':
        return (row: Row<DCRecord>, columnid: string, currValue: any) =>
          filterMulti(row.original[columnid] as any[], currValue);
      case 'date':
      case 'datetime':
        if (!f.filterType || f.filterType==='default') {
          return (row: Row<DCRecord>, columnid: string, currValue: any) =>
            filterDate(row.original[columnid] as unknown as Date, currValue);
        } else if (f.filterType === 'year') {
          return (row: Row<DCRecord>, columnid: string, currValue: any) =>
            filterDateYear(row.original[columnid] as moment.Moment, currValue);
        }
      case 'currency':
      case 'wholenum':
      case 'numeric':
        return (row: Row<DCRecord>, columnid: string, currValue: any) =>
          filterRange(row.original[columnid] as number, currValue);
      default:
        return (row: Row<DCRecord>, columnid: string, currValue: any) =>
          `${row.original[columnid]}`
            .toUpperCase()
            .indexOf(currValue.toUpperCase()) >= 0;
    }
  };

  /**
   * Function that return the Filter sort type on Field type
   *
   * @param f - Field
   * @returns The column filter sort type for that Field type
   */
  const getSortType = (f: FieldAny) => {
    switch (f.type) {
      case 'date':
      case 'datetime':
        return sortDateTime;
      case 'link':
        return sortLink;
      default:
        return sortBasic;
    }
  };

  return tableReady ?
    <Table
      defaultPageSize={defaultPageSize}
      data={records || []}
      columns={columnInfo.columns}
      hiddenColumnNames={columnInfo.hiddenColumnNames}
      title={title}
      allowColumnPicker={allowColumnPicker}
      allowSelection={allowSelection}
      allowFilter={allowFilter}
      allowExport={allowExport}
      allowAdd={allowAdd}
      allowSearch={allowSearch}
      allowLegend={allowLegend}
      addLabel={addLabel}
      handleAdd={handleAdd}
      dc={dc}
      tableName={tableName}
      onRowClick={onRowClick}
      onDoubleClick={onDoubleClick}
      onRefresh={onRefresh}
      onLegendClick={onLegendClick}
      identifier={identifier}
      rowSelectedCustom={rowSelectedCustom}
      unselectRow={unselectRow}
      getRowStyle={getRowStyle}
      size={size}
      smallButtons={smallButtons !== undefined ? smallButtons : false}
      disableControls={disableControls !== undefined ? disableControls : false}
    >
      {children}
    </Table>
    : null;
};

export default ModelTable;
