import { createContext } from 'react';
import { ITenantStatePreParsed, TenantThemeType } from '@/@types/common';
import { IMapSettings } from '@/@types/common';

// Types
export type TenantContextType = {
  loaded: boolean;
  id: number;
  name: string;
  publicToken: string;
  tenantTheme: TenantThemeType;
  mapSettings: IMapSettings;
  setTenantData: (data: ITenantStatePreParsed) => void;
};

const TenantContext = createContext<TenantContextType | null>(null);
TenantContext.displayName = 'TenantContext';

export default TenantContext;
