import React, { useContext } from 'react';

//OL
import OlLayerGroup from 'ol/layer/Group';
import OlBaseLayer from 'ol/layer/Base';
import OlCollection from 'ol/Collection';

//Custom Components
import TileLayer from '@/components/Map/Layers/TileLayer';
import MapContext from '@/context/MapContext/MapContext';
import { flattenLayers } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

type GeoLayerCombinedProps = {
  id: string;
  layersCollection: OlCollection<OlBaseLayer>;
  visible?: boolean;
};

function GeoLayerCombined(props: GeoLayerCombinedProps) {
  const mapContext = useContext<MapContextType | null>(MapContext);
  const { id, visible, layersCollection, ...otherProps } = props;

  const layerVisibility = mapContext?.getLayerVisibility();
  const hiddenLayers = layerVisibility
    ? Object.keys(layerVisibility).filter((key) => layerVisibility[key] === false)
    : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter(
    (x) => !(x instanceof OlLayerGroup)
  );
  // console.log(allLayers);

  const visibleLayers = allLayers.filter(
    (x) => hiddenLayers.indexOf(x.get('id')) === -1
  );
  // console.log(visibleLayers);

  const GSlayerNames = visibleLayers.map((x) => x.get('layer'));
  // console.log(GSlayerNames);

  const visibilityFromMap = GSlayerNames.length > 0;
  const visibilityFromProp = visible;

  const visibility = visible === undefined ? visibilityFromMap : visibilityFromProp;

  const format =
    visibleLayers.length > 0 ? visibleLayers[0].get('format') : 'image/png8';

  const tiled = visibleLayers.length > 0 ? visibleLayers[0].get('tiled') : true;

  // console.log('render GSCombined', id, GSlayerNames, visible);

  return (
    <TileLayer
      id={id}
      title={id}
      {...otherProps}
      visible={visibility}
      wms={{
        url: gs_url,
        params: {
          LAYERS: GSlayerNames.join(','),
          FORMAT: format,
          TILED: tiled,
        },
      }}
    />
  );
}

export default GeoLayerCombined;
