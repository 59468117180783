import React, { useContext, MouseEvent } from 'react';

// MUI
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';

// Custom Components
import UserContext from '@/context/UserContext/UserContext';

// Types
import { ITableHeaderButtonProps } from '@/@types/ui/Table';

const TableHeaderButton: React.FC<ITableHeaderButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const { onClick, needPermission, smallButtons, ...otherProps } = props;

  const handleClick = function (evt: MouseEvent<HTMLButtonElement>) {
    onClick(evt);
  };

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down(1050));

  if (
    needPermission &&
    userContext &&
    !userContext.hasAnyPermission(needPermission)
  ) {
    return null;
  }
  return (mdDown || smallButtons === true) ? (
    <Fab
      color="primary"
      size="small"
      onClick={handleClick}
      sx={{
        minHeight: {xs: 26, md: 32},
        minWidth: 40,
        mx: {xs:"4px", sm: 1, md: 2}
      }}
    >
      {otherProps.startIcon}
    </Fab>
  ) : (
    <Button
      color="primary"
      size="small"
      {...otherProps}
      onClick={handleClick}
      sx={{
        minHeight: 32,
        ml: 1,
        mr: 0,
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableHeaderButton;
