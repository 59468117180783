import React from 'react';

// MUI
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

// MUI Icons
import LayersIcon from '@mui/icons-material/Layers';

const MapCardHeaderButtonLayers = (props: IconButtonProps) => (
  <IconButton
    color="inherit"
    aria-label="layers"
    aria-controls="layers"
    {...props}
  >
    <LayersIcon sx={{color: "white"}}/>
  </IconButton>
);

export default MapCardHeaderButtonLayers;
