import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Collapse, Pagination, Stack, Typography } from '@mui/material';

// MUI Icons
import PublicIcon from '@mui/icons-material/Public';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LaunchIcon from '@mui/icons-material/Launch';

// Openlayers
import { transform } from 'ol/proj';

// Custom Components
import MapCard from '@/ui/MapCard/MapCard';
import MapCardHeader from '@/ui/MapCard/MapCardHeader';
import MapCardAvatar from '@/ui/MapCard/MapCardAvatar';
import MapCardContent from '@/ui/MapCard/MapCardContent';
import MapCardActions from '@/ui/MapCard/MapCardActions';
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import MapCardHeaderButtonClose from '@/ui/MapCard/MapCardHeaderButtonClose';
import MapCardHeaderButtonLayers from '@/ui/MapCard/MapCardHeaderButtonLayers';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import ModelTable from '@/ui/Table/ModelTable';
import GoogleMapsButton from './LayerFunctionalities/GoogleMapsNavigate';

// Lib
import Permissions from '@/lib/permissions';
import DataController from '@/lib/DataController';

// Models
import modelData from '@/models/data';
import modelPosjednici from '@/models/posjednici';
import modelVlasnici from '@/models/vlasnici';

// Types
import { IGFICardOverlayData, IGFIOverlayFeatureIndex } from '@/@types/views/map';
import { DCRecord } from '@/@types/lib/dataController';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';
import Show3DButton from './LayerFunctionalities/Show3DButton';
import VlasniciButton from './LayerFunctionalities/VlasniciButton';
import PosjedniciButton from './LayerFunctionalities/PosjedniciButton';

export interface GSInfoCardProps {
  featureData: IGFICardOverlayData;
  onClose: () => void;
  setIndex: React.Dispatch<React.SetStateAction<IGFIOverlayFeatureIndex>>;
  currentIndex: any;
  handlePopoverOverlayClick: any;
  layerPickerDisabled?: boolean;
}

const GSInfoCard = (props: GSInfoCardProps) => {
  const { t } = useTranslation();

  const {
    featureData,
    onClose,
    setIndex,
    currentIndex,
    handlePopoverOverlayClick,
    layerPickerDisabled,
  } = props;

  const [page, setPage] = useState<number>(1); // For GFI pagination, when multiple features of same layer are found on click
  const [collapsedNode, setCollapsedNode] = useState<ReactNode | null>(null); 
  const [expanded, setExpanded] = useState<string | null>(null)

  const handleExpand = (node: ReactNode, identifier: string) => { 
    if (expanded === identifier) {
      setExpanded(null);
      setTimeout(() => {
        setCollapsedNode(null);
      }, 300); // Allow time for collapse animation
    } else {
      setCollapsedNode(node);
      if (expanded !== null) {
        setExpanded(identifier);
      } else {
        setTimeout(() => {
          setExpanded(identifier);
        }, 300); // Allow time for collapse animation
      }
      }
  };

  const { layerData } = featureData;

  const { layer, model, layerFunctionalityId } = layerData;

  const modelFields =
    model?.fields && model?.fields.length > 0 ? model.fields : modelData.fields; // default to data.ts model

  /*const [expandedKuceZaOdmor, setExpandedKuceZaOdmor] =
    useState<boolean>(false);*/

  const faIconClass = 'fas fa-info ';

  const record = useMemo(() => {
    if (featureData) {
      if (featureData.record) {
        return featureData.record;
      } else if (featureData.features[currentIndex[layer]]) {
        return featureData.features[currentIndex[layer]].properties;
      } else if (featureData.features[0]) {
        setIndex((prev) => {
          const obj = { ...prev };
          obj[layer] = 0;
          return obj;
        });
        return featureData.features[0].properties;
      }
    }
    return null;
  }, [featureData, currentIndex, layer]);

  useEffect(() => {
    if (currentIndex && Object.keys(currentIndex).length > 0) {
      setPage(currentIndex[layer] + 1);
    }
  }, [layer]);

  useEffect(() => {
    setExpanded(null)
    setTimeout(() => {
      setCollapsedNode(null);
    },300)
  }, [page])

  const generateCustomActions = (layerFunctionalityId: number | null): ReactNode[] => {
    let nodes: ReactNode[] = [];

    nodes.push( // Will not render if client does not have permission for 3D
    <Show3DButton
      featureData={featureData}
     />
    )

    switch(layerFunctionalityId) {
      case EnumLayerFunctionality.dkp_show_posjednici_vlasnici:
        nodes.push(
          <PosjedniciButton
          featureData={featureData}
          record={record}
          onToggle={handleExpand}
          expandedIdentifier={expanded}
          />
        );
        nodes.push(
          <VlasniciButton
          featureData={featureData}
          record={record}
          onToggle={handleExpand}
          expandedIdentifier={expanded}
          />
        )
        break;
      case EnumLayerFunctionality.dkp_show_posjednici:
        nodes.push(
          <PosjedniciButton
          featureData={featureData}
          record={record}
          onToggle={handleExpand}
          expandedIdentifier={expanded}
          />
        );
        break;
      case EnumLayerFunctionality.odvoz_location_button:
        break;
      case EnumLayerFunctionality.kuce_za_odmor_button:
        break;
      case EnumLayerFunctionality.google_maps_navigate_button:
        nodes.push(
          <GoogleMapsButton
          featureData={featureData}
          />
        )
        break;
      default:
        break;
    }
    return nodes;
  }
  
  const fields = modelFields;
  const fieldNames = modelFields.map((field) => field.source);

  const recordId = record.id ? record.id : record.fid ? record.fid : null;

  // const handleToggleKuceZaOdmor = () => {
  //   const newValue = !expandedKuceZaOdmor;
  //   setExpandedKuceZaOdmor(newValue);
  // };

  // const handleOdvozLocationRedirect = () => {
  //   const locId =
  //     initialRecod.lokacija_i === null
  //       ? ""
  //       : `&loc_id=${initialRecod.lokacija_i}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleKomunaneObvezeRedirect = () => {
  //   const locId =
  //   initialRecod.lokacija_id === null
  //   ? ""
  //   : `&loc_id=${initialRecod.lokacija_id}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleFotodokumentacijaRedirect = () => {
  //   const objektId =
  //     initialRecod.objekt_id === null
  //       ? ""
  //       : `${initialRecod.objekt_id}`;
  //   console.log(initialRecod)
  //   if (objektId !== "") {
  //     const url =
  //       location.origin +
  //       "/fotodokumentacija-objekata/?rec_id=" + objektId.toString();
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/fotodokumentacija-objekata";
  //     window.open(url, "_blank");
  //   }
  // };

  const modelTitle =
    model?.title_type === 1
      ? t(`${model.title_token}`)
      : model?.title_type === 2 && model?.title_field
        ? record[model.title_field]
        : model?.title_type === 3 && model?.title_field
          ? `${t(`${model.title_token}`)}: ${record[model.title_field]}`
          : null;

  const title = model
    ? modelTitle
    : record.naziv
      ? record.naziv
      : layer
        ? t(`map:layer.${layer}`)
        : 'Info';

  return (
    <MapCard
      sx={{
        backgroundColor: 'background.default',
        minWidth:
          expanded !== null
            ? '580px'
            : featureData.features.length <= 1
              ? '380px'
              : '450px',

        resize: "horizontal"
      }}
    >
      <MapCardHeader
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <Stack justifyContent="center" color="white">
              <Typography variant="body1">{title}</Typography>
              {recordId && recordId !== null ? (
                <Typography variant="body2">{`${t(
                  'common.id'
                )}: ${recordId}`}</Typography>
              ) : null}
            </Stack>
            <Stack alignItems="center">
              <Pagination
                hidden={featureData.features.length <= 1}
                size="small"
                shape="rounded"
                count={featureData.features.length}
                defaultPage={1}
                siblingCount={0}
                boundaryCount={0}
                sx={{
                  mt: 1,
                }}
                page={page}
                onChange={(event, page) => {
                  setPage(page);
                  setIndex((prev: any) => {
                    const obj = { ...prev };
                    obj[layer] = page - 1;
                    return obj;
                  });
                }}
              />
            </Stack>
          </div>
        }
        disableTypography
        avatar={
          <MapCardAvatar
            ariaLabel="info"
            icon={<i className={faIconClass} />}
          />
        }
        action={
          <Stack m={0} direction="row">
            {!layerPickerDisabled && (
              <MapCardHeaderButtonLayers onClick={handlePopoverOverlayClick} />
            )}
            <MapCardHeaderButtonClose onClick={onClose} />
          </Stack>
        }
      />
      <MapCardContent>
        <GridContainer spacing={0}>
          <GridItem xs={12} container>
            <GridContainer spacing={0}>
              <FormContent
                fields={fields}
                fieldNames={fieldNames}
                columns={1}
                mode="simpleView"
                record={record}
              />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </MapCardContent>

      <MapCardActions
        style={{ display: "flex", flexWrap: "wrap", rowGap: "0.6rem" }}
      >
        {generateCustomActions(layerFunctionalityId)}
        {/* {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              endIcon={
                <ExpandMoreIcon
                  sx={[
                    {
                      transform: "rotate(0deg)",
                      transition: (theme) =>
                        theme.transitions.create("all", {
                          duration: theme.transitions.duration.shortest,
                        }),
                    },
                    expandedKuceZaOdmor && {
                      transform: "rotate(180deg)",
                    },
                  ]}
                />
              }
              onClick={handleToggleKuceZaOdmor}
              aria-expanded={expandedKuceZaOdmor}
              aria-label="expand"
              //disabled={recordsKuceZaOdmor.length === 0}
            >
              {t("buttons.edit")}
            </MapCardActionButton>
          ) : null}

          {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<RequestPageIcon />}
              onClick={handleKomunaneObvezeRedirect}
              aria-label="kuce-za-odmor"
              variant="outlined"
            >
              {t("buttons.show_object_short")}
            </MapCardActionButton>
          ) : null}

          {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<PhotoCameraIcon />}
              onClick={handleFotodokumentacijaRedirect}
              aria-label="kuce-za-odmor"
              variant="outlined"
            >
              {t("buttons.show_photos_short")}
            </MapCardActionButton>
          ) : null}

          {hasOdvozLocationRedirectButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              onClick={handleOdvozLocationRedirect}
            >
              {t("buttons.lokacija")}
            </MapCardActionButton>
          ) : null}    */}
          
          
      </MapCardActions>
      <Collapse
        in={expanded !== null}
        sx={{ maxHeight: 400, overflowX: 'scroll' }}
      >
        {collapsedNode}
      </Collapse>
    </MapCard>
  );
};

export default GSInfoCard;