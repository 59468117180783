import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//Material-UI Icons
import FingerprintIcon from "@mui/icons-material/Fingerprint";

//Custom components

// Types
export interface IFormHeadingID {
  id: number
}

function FormHeadingID(props: IFormHeadingID) {
  const { t } = useTranslation();

  const { id } = props;

  return (
    <Tooltip title={t("common.id")}>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
        <FingerprintIcon style={{ marginRight: 8 }} />
        <Typography align="left" variant="subtitle2">
          {id}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default FormHeadingID;
