import * as React from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import AddBoxIcon from "@mui/icons-material/AddBox";

//Custom components
import DialogToolbarMenuItem from "@/ui/Dialog/DialogToolbarMenuItem";

//Types
import { IDialogToolbarMenuItemDetail } from "@/@types/ui/Dialog";

const DialogToolbarMenuItemCopy = React.forwardRef<HTMLLIElement, IDialogToolbarMenuItemDetail>((props, ref) => {
  const { t } = useTranslation();

  return <DialogToolbarMenuItem label={t("buttons.copy")} icon={<AddBoxIcon />} {...props} ref={ref} />;
});

export default DialogToolbarMenuItemCopy;
