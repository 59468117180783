import React, { useContext, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// OL
import { Extent } from 'ol/extent';

// CUSTOM COMPONENTS
import ModelTable from '@/ui/Table/ModelTable';
import DataController from '@/lib/DataController';
import modelComments from '@/models/comments';
import MapContext from '@/context/MapContext/MapContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import TableButton from '@/ui/Table/ActionButtons/TableButton';
import CommentShapeDialog from '@/views/Comments/CommentShapeDialog';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';

// TYPES
import { DrawingType } from '@/@types/common/index';
import { DCRecord } from '@/@types/lib/dataController';

export interface ICommentsPane {
  changeDrawType: (type: DrawingType) => void;
  drawType: DrawingType | undefined;
  records: DCRecord[];
  visible?: boolean;
  toggleVisibility?: () => void;
  setSelectedSource: React.Dispatch<React.SetStateAction<OlSourceVector<Geometry>>>
}

const CommentsPane: FunctionComponent<ICommentsPane> = (props) => {
  const { changeDrawType, drawType, records, visible, toggleVisibility, setSelectedSource } =
    props;

  const { t } = useTranslation();
  const dc = new DataController(modelComments);
  const mapContext = useContext(MapContext);
  const dialogContext = useContext(DialogContext);

  const handleAddComment = () => {
    dialogContext.showDialog(CommentShapeDialog, {
      drawType,
      changeDrawType,
    });
  };

  function handleRowAction(action: string, id: number) {
    const record = records.find((x: DCRecord) => x.id === id);
    if (!record) return;

    switch (action) {
      case 'show_on_map':
        handleShowOnMap(record.extent as Extent, record as DCRecord);
        break;
      default:
        break;
    }
  }

  function handleShowOnMap(extent: Extent, record?: DCRecord) {
    if (mapContext?.map) {
      if(record) {
        const feature = record.geom;
        // @ts-ignore
        const commentsSource = new OlSourceVector({features: [feature] as OlFeature<Geometry>});
        setSelectedSource(commentsSource);
      }
      
      const v = mapContext.map.getView();
      v.fit(extent, {
        padding: [20, 20, 20, 220],
        duration: 1000,
        maxZoom: 19,
      });
    }
  }

  return (
    <Box
      sx={{
        height: '88%',
      }}
    >
      {/* <FormControlLabel
        control={
          <Checkbox
            value={visible}
            color="primary"
            onChange={() => {
              toggleVisibility ? toggleVisibility() : null;
            }}
          />
        }
        label={t('comments.view')}
      /> */}
      <ModelTable
        key={`tblid-${records.length}`}
        dc={dc}
        records={records}
        allowSelection="none"
        title=""
        allowRowAction={() => true}
        onRowAction={handleRowAction}
        viewName="default"
        allowColumnPicker={false}
      />
      <Box mt={2}>
        <TableButton
          variant="contained"
          allow="always"
          onClick={handleAddComment}
          startIcon={<AddIcon />}
          selection={{}}
        >
          {t('titles.add_comment')}
        </TableButton>
      </Box>
    </Box>
  );
};

export default CommentsPane;
