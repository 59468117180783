import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const Tenant: IModel = {
  title: 'Tenants',
  apiPath: 'system/tenants',
  forms: {
    insert: {
      fields: [
        'name_ttoken',
        'tag',
        'host',
        'tenant_state_id',
        'tenant_licence_started_on',
        'tenant_licence_ends_on',
        'tenant_licence_max_users',
        'public_user_type',
      ],
    },
    update: {
      fields: [
        'id',
        'tag',
        'name_ttoken',
        'tenant_state_id',
        'host',
        'created_on',
        'created_by',
        'modified_on',
        'modified_by',
        'primary_main',
        'primary_dark',
        'primary_light',
        'secondary_main',
        'secondary_light',
        'secondary_dark',
        'dem_path',
        'dem_layer_names',
        'gs_user',
        'map_default_extent',
        'map_max_extent',
        'map_initial_view_center',
        'map_initial_view_zoom',
        'geom',
        'geojson',
        'background_image',
        'crest',
        'mail_smtp_server',
        'mail_port',
        'mail_from_address',
        'mail_from_name',
        'mail_user',
        'mail_pwd',
        'mail_password_reset_link',
        'mail_password_reset_subject',
        'mail_password_reset_body_plain',
        'mail_password_reset_body_html',
      ],
    },
    search: {
      fields: ['name_ttoken', 'tag', 'host', 'tenant_state_id'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'name_ttoken', 'tag', 'host', 'tenant_state_id'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'name_ttoken',
      source: 'name_ttoken',
      ttoken: 'admin:tenant.name_ttoken',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 100,
      },
    },
    {
      title: 'tag',
      source: 'tag',
      ttoken: 'common.tag',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 20,
      },
    },
    {
      title: 'tenant_state_id',
      source: 'tenant_state_id',
      ttoken: 'admin:tenant.state_id',
      type: 'picker',
      filter: true,
      items: {
        labels: [
          'admin:tenant_states.active',
          'admin:tenant_states.suspended',
          'admin:tenant_states.inactive',
        ],
        values: [10, 15, 20],
        default: 10,
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'public_user_type',
      source: 'public_user_type',
      ttoken: 'admin:tenant.public_user_type',
      type: 'radio',
      items: {
        labels: ['Public', 'Elevate', 'Public + Elevate'],
        values: [1, 2, 3],
        default: 1,
      },
    },
    {
      title: 'host',
      source: 'host',
      ttoken: 'admin:tenant.host',
      type: 'text',
      filter: true,
      width: 'full',
      validation: {
        required: true,
      },
    },
    coreFields.created_on,
    coreFields.created_by,
    coreFields.modified_on,
    coreFields.modified_by,
    {
      title: 'dem_layer_names',
      source: 'dem_layer_names',
      ttoken: 'admin:tenant.dem_layer_names',
      type: 'text',
    },
    {
      title: 'dem_path',
      source: 'dem_path',
      ttoken: 'admin:tenant.dem_path',
      type: 'text',
    },
    {
      title: 'gs_user',
      source: 'gs_user',
      ttoken: 'admin:tenant.gs_user',
      type: 'text',
    },
    {
      title: 'geom',
      source: 'geom',
      ttoken: 'admin:tenant.geom',
      type: 'multiline',
      rows: 7,
      maxRows: 7,
      validation: {
        wktType: ['MultiPolygon', 'Optional'],
      },
      placeholder: 'MULTIPOLYGON(())',
    },
    {
      title: 'geojson',
      source: 'geojson',
      ttoken: 'admin:tenant.geojson',
      type: 'geojson',
    },
    {
      title: 'map_default_extent',
      source: 'map_default_extent',
      ttoken: 'admin:tenant.map_default_extent',
      type: 'array',
    },
    {
      title: 'map_initial_view_center',
      source: 'map_initial_view_center',
      ttoken: 'admin:tenant.map_initial_view_center',
      type: 'array',
    },
    {
      title: 'map_initial_view_zoom',
      source: 'map_initial_view_zoom',
      ttoken: 'admin:tenant.map_initial_view_zoom',
      type: 'numeric',
    },
    {
      title: 'map_max_extent',
      source: 'map_max_extent',
      ttoken: 'admin:tenant.map_max_extent',
      type: 'array',
    },
    {
      title: 'primary_dark',
      source: 'primary_dark',
      ttoken: 'admin:tenant.primary_dark',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'primary_light',
      source: 'primary_light',
      ttoken: 'admin:tenant.primary_light',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'primary_main',
      source: 'primary_main',
      ttoken: 'admin:tenant.primary_main',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'secondary_dark',
      source: 'secondary_dark',
      ttoken: 'admin:tenant.secondary_dark',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'secondary_light',
      source: 'secondary_light',
      ttoken: 'admin:tenant.secondary_light',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'secondary_main',
      source: 'secondary_main',
      ttoken: 'admin:tenant.secondary_main',
      type: 'color',
      validation: {
        required: true,
        maxLength: 7,
        minLength: 4,
        regex: 'color',
      },
    },
    {
      title: 'ends_on',
      source: 'tenant_licence_ends_on',
      ttoken: 'admin:common.licence_ends_on',
      type: 'date',
    },
    {
      title: 'max_users',
      source: 'tenant_licence_max_users',
      ttoken: 'admin:common.licence_max_users',
      type: 'numeric',
      validation: {
        required: true,
      },
    },
    {
      title: 'started_on',
      source: 'tenant_licence_started_on',
      ttoken: 'admin:common.licence_started_on',
      type: 'date',
    },
    {
      title: 'background_image',
      source: 'background_image',
      ttoken: 'common.background_image',
      type: 'images',
      maxFiles: 1,
    },
    {
      title: 'background_image_type',
      source: 'background_image_type',
      ttoken: 'common.background_image_type',
      type: 'text',
    },
    {
      title: 'crest',
      source: 'crest',
      ttoken: 'common.crest',
      type: 'images',
      maxFiles: 1,
    },
    {
      title: 'crest_type',
      source: 'crest_type',
      ttoken: 'common.crest_type',
      type: 'text',
    },
    {
      title: 'mail_password_reset_body_html',
      source: 'mail_password_reset_body_html',
      ttoken: 'admin:tenant.mail_password_reset_body_html',
      type: 'multiline',
      rows: 7,
      maxRows: 5,
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_password_reset_body_plain',
      source: 'mail_password_reset_body_plain',
      ttoken: 'admin:tenant.mail_password_reset_body_plain',
      type: 'multiline',
      rows: 5,
      maxRows: 5,
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_smtp_server',
      source: 'mail_smtp_server',
      ttoken: 'admin:tenant.mail_smtp_server',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_port',
      source: 'mail_port',
      ttoken: 'admin:tenant.mail_port',
      type: 'numeric',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_from_address',
      source: 'mail_from_address',
      ttoken: 'admin:tenant.mail_from_address',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_password_reset_link',
      source: 'mail_password_reset_link',
      ttoken: 'admin:tenant.mail_password_reset_link',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_password_reset_subject',
      source: 'mail_password_reset_subject',
      ttoken: 'admin:tenant.mail_password_reset_subject',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_from_name',
      source: 'mail_from_name',
      ttoken: 'admin:tenant.mail_from_name',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_user',
      source: 'mail_user',
      ttoken: 'admin:tenant.mail_user',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'mail_pwd',
      source: 'mail_pwd',
      ttoken: 'admin:tenant.mail_pwd',
      type: 'text',
      validation: {
        required: true,
      },
    },
    {
      title: 'last_login_user_id',
      source: 'last_login_user_id',
      ttoken: 'admin:tenant.last_login_user_id',
      type: 'text',
      filter: true,
      validation: {
        required: true,
      },
    },
    {
      title: 'last_login_user_name',
      source: 'last_login_user_name',
      ttoken: 'admin:tenant.last_login_user_name',
      type: 'text',
      filter: true,
      validation: {
        required: true,
      },
    },
    {
      title: 'last_login_on',
      source: 'last_login_on',
      ttoken: 'admin:tenant.last_login_on',
      type: 'datetime',
      filter: true,
      validation: {
        required: true,
      },
    },
  ],
};

export default Tenant;
