import useApi, { IApi } from '@/lib/api/useApi';

// Types
import { IApiResponseSuccessGet, Record } from '@/@types/lib/api';
import { IDataControllerSub, ErrorData } from '@/@types/lib/dataControllerSub';
import {
  IFieldID,
  IFieldPickerModel,
  PickerItemValue,
} from '@/@types/models/model';

import { PickerItem } from '@/@types/controls/controls';
import { ISubmodel, ISubmodelField } from '@/@types/models/submodel';

class DataControllerSubModel implements IDataControllerSub {
  api: IApi;

  apiPath: string;

  fields: Array<ISubmodelField>;

  records: Array<PickerItem>;

  defaultItem: PickerItemValue | null;

  fieldId: IFieldID;

  constructor(
    model: ISubmodel,
    field: IFieldPickerModel,
    pickerApiPathParams: object = {}
  ) {
    this.apiPath = DataControllerSubModel.parseApiPath(
      field.subModel.apiPath,
      pickerApiPathParams
    );

    this.fields = model.fields;

    this.records = [];

    this.defaultItem =
      field && field.items && field.items.default ? field.items.default : null;

    const fieldIdName = field.subModel.idattr ? field.subModel.idattr : null;
    if (fieldIdName) {
      this.fieldId = this.fields.find(
        (attr) => attr.source === fieldIdName
      ) as IFieldID;
    } else {
      this.fieldId = this.fields.find(
        (attr) => attr.idattr === true
      ) as IFieldID;
    }

    this.api = useApi();
  }

  GetData(): Promise<Array<PickerItem> | ErrorData> {
    if (this.records.length === 0) {
      return this.api
        .get(this.apiPath)
        .then((resp) => {
          if (resp.success) {
            const { data } = resp as IApiResponseSuccessGet;
            if (Array.isArray(data)) {
              const dbRecords = data.map((d) => ({ ...d }));
              const items = DataControllerSubModel.mapRecords(dbRecords);
              this.records = items;
              return Promise.resolve(items);
            }
            const dbRecords = [data];
            const items = DataControllerSubModel.mapRecords(dbRecords);
            this.records = items;
            return Promise.resolve(items);
          }
          return Promise.reject(resp);
        })
        .catch((err) => Promise.reject(err));
    }
    return Promise.resolve(this.records);
  }

  GetRecord(recordId: PickerItemValue) {
    if (this.fieldId && (recordId !== null || recordId !== undefined)) {
      const item = this.records.find((r) => r.value === recordId);
      if (item) {
        return { ...item }; // clone object
      }
      return null;
    }
    return null;
  }

  static mapRecords(records: Array<Record>) {
    const items: PickerItem[] = [];
    if (Array.isArray(records)) {
      records.forEach((r) => {
        if (r && r.value !== undefined && r.label !== undefined) {
          const item: PickerItem = {
            value: r.value as PickerItemValue,
            label: r.label as string,
          };
          if (r.disabled !== undefined && typeof r.disabled === 'boolean') {
            item.disabled = r.disabled;
          }
          items.push(item);
        }
      });
    }
    return items;
  }

  static parseApiPath(
    currApiPath: string,
    apiPathParams: { [key: string]: any }
  ) {
    const keys = Object.keys(apiPathParams);
    if (currApiPath.indexOf('{') >= 0 && currApiPath.indexOf('}') >= 0) {
      let label = currApiPath;
      if (keys.length > 0) {
        keys.forEach((k) => {
          const re = new RegExp(`{${k}}`);
          label = label.replace(
            re,
            apiPathParams[k] != null ? apiPathParams[k] : ''
          );
        });
        return label;
      }
      // return null;
    }
    return currApiPath;
  }
}
export default DataControllerSubModel;
