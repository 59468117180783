import React, { FunctionComponent, useContext, useEffect } from "react";

//Types
import { ButtonProps } from "@mui/material";
import ButtonControlRoot from "@/components/Map/Controls/Custom/Button/ButtonControlRoot";


export interface IButtonControl extends ButtonProps {
  id: string
  order?: number
  target?: HTMLElement
}

const ButtonControl: FunctionComponent<IButtonControl> = (props) => {
  return <ButtonControlRoot {...props} >
    {props.children}
  </ButtonControlRoot>
};

export default ButtonControl;

