import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// CUSTOM
import { MenuType } from '@/@types/common';


// Types
type NavDrawerLinkProps = React.PropsWithChildren<{
  open: boolean;
  to: string;
  text: string;
  icon: string;
  ttoken: string;
  nested?: MenuType[];
  collapse?: ()=>void;
  collapsed?: boolean;
  level: number;
  name: string;
}>;

const NavDrawerLink = (props: NavDrawerLinkProps) => {
  const { t } = useTranslation();

  const { open, to, text, icon, ttoken, nested, collapse, collapsed, level,name } = props;

  type MyNavLinkProps = Omit<NavLinkProps, 'to'>;

  const MyNavLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, MyNavLinkProps>(
        (navLinkProps, ref) => {
          const { className: previousClasses, ...rest } = navLinkProps;
          const elementClasses = previousClasses?.toString() ?? '';
          return (
            <NavLink
              {...rest}
              key={"nav-link-"+name}
              ref={ref}
              to={collapse?'':to}
              end
              className={({ isActive }) =>
                isActive && collapse===undefined ? `${elementClasses} Mui-selected` : elementClasses
              }
            />
          );
        }
      ),
    [to]
  );

  return (
    <ListItemButton 
      key={"nav-list-item-"+name}
      aria-label={"nav-list-item-"+name}
      component={MyNavLink} 
      onClick={() => {collapse!==undefined ? collapse() : null}}
    >
      <ListItemIcon
      sx={{
        marginLeft: open?(7*level)+"px":0,
        transition: "margin-left 0.3s"
      }}>
        {open ? (
          <Icon fontSize="small" color="primary">
            {icon}
          </Icon>
        ) : (
          <Tooltip title={t(ttoken) as string}>
            <Icon fontSize="small" color="primary">
              {icon}
            </Icon>
          </Tooltip>
        )}
      </ListItemIcon>
      <ListItemText primary={t(ttoken)} />
      { collapse!==undefined ?
          collapsed!== undefined && collapsed===true ?
              <ExpandLess/> : <ExpandMore/>
          
        :
          <></>
      }
    </ListItemButton>
  );
};

export default NavDrawerLink;
