import * as React from "react";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { IMultiLineControlProps } from "@/@types/controls/controls";

function MultiLineControl(props: IMultiLineControlProps) {
  const { field, type, ...otherProps } = props;

  return (
    <BaseInputControl
      type="text"
      field={field}
      {...otherProps}

      multiline={true}
      // rows={field.rows}
      minRows={field.rows}
      maxRows={field.maxRows || field.rows}

    />
  );
}

export default MultiLineControl;
