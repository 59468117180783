import * as React from 'react';
import { FunctionComponent, ReactNode, PropsWithChildren } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Types
export interface IFormHeading {
  header?: string;
  subheaderContent?: ReactNode
  subheader?: string;
  dataChanged?: boolean;
}

const FormHeading: FunctionComponent<IFormHeading> = (props: PropsWithChildren<IFormHeading>) => {
  const { children, header, subheaderContent, subheader, dataChanged } = props;

  const typographyStyle = {
    mx: 2,
  };

  return header ? (
    <Stack spacing={0} direction="column">
      <Typography align="left" variant="subtitle1" sx={typographyStyle}>
        {header} {dataChanged ? ' *' : null}
      </Typography>
      {subheaderContent ? subheaderContent :
        subheader ? (
        <Typography align="left" variant="subtitle2" sx={typographyStyle}>
          {subheader}
        </Typography>
      ) : null}
    </Stack>
  ) : (
    <Typography align="left" variant="subtitle1" sx={typographyStyle}>
      {children}
    </Typography>
  );
};

export default FormHeading;
