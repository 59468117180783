import * as React from 'react';
import { FunctionComponent, ReactNode, useState } from 'react';
import LoaderContext from './LoaderContext';

// import { ILoaderProvider } from "@/@types/context/LoaderContext";
export interface ILoaderProvider {
  children: ReactNode;
}

const LoaderProvider: FunctionComponent<ILoaderProvider> = (props) => {
  const [loading, setLoading] = useState<number>(0);

  const toggleLoading = (value: boolean): void => {
    // changed the logic from boolean to integer +/- because if we turn on the loader multiple times it doesn't consider turning it off the same number of times
    // it will turn off when the first one sets the value to false, although not everything was properly loaded
    setLoading((prevState) => prevState + (value ? 1 : -1));
  };

  const providerValue = {
    loading,
    toggleLoading,
  };

  return (
    <LoaderContext.Provider value={providerValue}>
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
