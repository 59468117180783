import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// d.ts
import { DCRecord } from '@/@types/lib/dataController';
import { IDataController } from '@/lib/DataController';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import DialogContext from '@/context/DialogContext/DialogContext';
import LoaderContext from '@/context/LoaderContext/LoaderContext';
import MapLayersDialog from './MapLayersDialog';
import MapLayerModel from '@/models/map_layer';
import dataController from '@/lib/DataController';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import { LoaderContextType } from '@/context/LoaderContext/LoaderContext';

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them
let asd = 0;
function MapLayersTable() {
  const navigate = useNavigate();
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;

  const { t } = useTranslation();

  const [records, setRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(MapLayerModel);

  useEffect(() => {
    refreshRecords();
  }, []);

  const handleAdd = () => {
    dialogContext.showDialog(MapLayersDialog, {
      dc: dc,
      mode: 'insert',
      form: 'insert',
      onClose: handleDialogClose,
    });
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = Object.keys(sel)[0]; // neven check if works
    navigate('/admin/map_layers/' + sel[selId].id);
  };

  const handleDelete = (evt: React.SyntheticEvent, sel: string[]) => {};

  const handleRowActionMapLayer = (action: string, id: number, record?: { [key: string]: any }) => {
    if (action === 'copy') {
      dialogContext.showDialog(MapLayersDialog, {
        dc: dc,
        mode: 'insert',
        form: 'insert',
        dataId: id,
        onClose: handleDialogClose,
        initalRecord: record ? record : undefined,
      });
    };
  };


  const handleDialogClose = (result: {
    dataChanged: boolean;
    action: string;
    id: number;
  }) => {
    if (result.dataChanged) {
      refreshRecords();
    }
    if (result.action === 'insert') {
      navigate(`/admin/map_layers/${result.id}`);
    }
  };

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success) {
          if (Array.isArray(resp.data)) {
            //@ts-ignore
            // const recs = new Array(100).fill({});
            // //@ts-ignore
            // resp.data.forEach((r, i) => { recs[i] = {...r} });
            // asd = resp.data.length;
            //@ts-ignore
            // setRecords(recs);
            setRecords(resp.data);
          } else {
            setRecords([]);
          }
        }
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const fetchData = (limit: number, offset: number) => {
    for (let i = 0; i < limit; ++i) {
      records[offset * limit + i] = records[i % asd];
    }
    console.log('here', limit, offset);
    // setRecords(Array.from(records));
    //@ts-ignore
    return new Promise((r) => r());
  };

  return (
    <ModelTable
      title={t('admin:titles.map_layers')}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={true}
      allowAdd={true}
      allowColumnPicker={true}
      addLabel={t('buttons.new_m') as string}
      handleAdd={handleAdd}
      onRowAction={handleRowActionMapLayer}
      allowRowAction={() => true}
      unselectRow={false}
      onDoubleClick={handleEdit}
    >
      <TableButtonEdit variant="contained" onClick={handleEdit} />
    </ModelTable>
  );
}

export default MapLayersTable;
